import React, { Fragment } from 'react';
import FadeIn from 'react-fade-in';

import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useIsPastTopPartOfScreen } from '../hooks/useIsPastTopPartOfScreen';
import { useTopOfScreen } from '../hooks/useTopOfScreen';

export const Footer: React.FC = () => {
  const [showBreadcrumbs, setShowBreadcrumbs] = React.useState<boolean>(false);
  const [isAtTopOfPage] = useTopOfScreen();
  const [isPastTopPartOfScreen] = useIsPastTopPartOfScreen();
  // const { title } = useParams<{ title: string }>();
  // const title =
  //   window &&
  //   window.location &&
  //   window.location.pathname &&
  //   window.location.pathname.split('/')?.length
  //     ? window.location.pathname.split('/').at(-1) || ''
  //     : '';
  let title = '';
  let productType = '';
  if (window) {
    const paths = window.location.pathname.split('/');
    title = paths?.length ? decodeURI(paths[paths.length - 1]) : '';
    productType = paths?.length ? decodeURI(paths[paths.length - 3]) : '';
  }

  const loc = useLocation();
  React.useEffect(() => {
    setShowBreadcrumbs(loc.pathname.includes('product'));
  }, [loc.pathname]);
  return (
    <>
      <Container>
        {showBreadcrumbs && (
          <Breadcrumbs style={{ zIndex: 10 }}>
            <Link to={`/home`} style={{ marginRight: 3 }}>
              SHOP
            </Link>{' '}
            {'>'}
            <Link
              to={`/collection/${productType.toLowerCase()}`}
              style={{ marginRight: 3, marginLeft: 3 }}
            >
              {productType.toUpperCase()}
            </Link>{' '}
            {'>'} {title}
          </Breadcrumbs>
        )}
        <div style={{ flex: 1 }} />
        <SiteMap>
          <PagesSection />
        </SiteMap>

        <ScrollToToFadeIn visible={!isAtTopOfPage && isPastTopPartOfScreen}>
          <ScrollToTopLink
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              document.querySelector('html')?.scrollTo(0, 0);
            }}
          >
            SCROLL TO TOP
          </ScrollToTopLink>
        </ScrollToToFadeIn>
      </Container>
      <MobileScrollView />
    </>
  );
};

const PagesSection: React.FC = () => {
  const [showSocial, setShowSocial] = React.useState<boolean>(false);
  return (
    <StyledPagesSection>
      <div
        style={{ flex: 1, minWidth: 56, textAlign: 'right' }}
        className={'page-btn'}
        onMouseEnter={() => setShowSocial(true)}
        onMouseLeave={() => setShowSocial(false)}
      >
        {showSocial ? (
          <StyledSocialMenu>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
              width: '24vw',
              marginLeft: '-20vw',
            }}
          >
            <a
              href={
                'https://twitter.com/cartonouterwear?s=21&t=zAy7MvX85DmyjQmWhLN2Tg'
              }
            >
              TWITTER
            </a>
            <a
              href={
                'https://www.tiktok.com/@carton.shop?is_from_webapp=1&sender_device=pc'
              }
            >
              TIK TOK
            </a>
            <a
              href={'https://www.youtube.com/channel/UCKIhCmHBPBuCpzVaW4yHfEQ'}
            >
              YOUTUBE
            </a>
            <a href={'https://instagram.com/_carton.shop_?igshid=YmMyMTA2M2Y='}>
              INSTAGRAM
            </a>
            {/* <img
              alt={'twitter'}
              src={require('../images/twitter-icon.svg').default}
            />
            <img
              alt={'tiktok'}
              src={require('../images/tiktok-icon.svg').default}
            />
            <img
              alt={'instagram'}
              src={require('../images/instagram-icon.svg').default}
            /> */}
          </div>
        </StyledSocialMenu>
        ) : (
          <Link to={'/home'}>SOCIAL</Link>
        )}
      </div>
      <Link to={'/info'} style={{ textAlign: 'center' }} className={'page-btn'}>
        INFO
      </Link>
      <Link to={'/contact'} className={'page-btn'}>
        CONTACT
      </Link>
    </StyledPagesSection>
  );
};

const MobileScrollView = () => {
  const [isAtTopOfPage] = useTopOfScreen();
  const [isPastTopPartOfScreen] = useIsPastTopPartOfScreen();
  let isMobile = /iphone|ipod|android|ie|blackberry|fennec/.test(
    navigator.userAgent.toLowerCase()
  );
  if (isAtTopOfPage) return <Fragment />;
  if (!isMobile) return <Fragment />;
  return (
    <div
      style={{
        display: 'flex',
        marginBottom: '20px',
        justifyContent: 'center',
      }}
    >
      <FadeIn>
        <ScrollToTopLinkMobile
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            document.querySelector('html')?.scrollTo(0, 0);
          }}
        >
          SCROLL TO TOP
        </ScrollToTopLinkMobile>
      </FadeIn>
    </div>
  );
};

const ScrollToToFadeIn = styled(FadeIn)`
  position: fixed;
  bottom: 20px;
  left: 50px;
`;
const ScrollToTopLink = styled.a`
  font-size: 12px;
  font-size: 0.7vw;
  cursor: pointer;
  @media (max-width: 700px) {
    display: none;
  }
`;
const ScrollToTopLinkMobile = styled.a`
  font-size: 14px;
`;

const Container = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  width: calc(100% - 30px);
  font-size: 12px;
  font-size: 0.7vw;

  @media (max-width: 700px) {
    font-size: 12px;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex: 1;

  & > a {
    text-decoration: none;
    color: black;
    cursor: pointer;
  }
  & > a:hover {
    // text-decoration: underline;
  }
`;

const StyledPagesSection = styled.div`
  width: 255px;
  width: 16vw;
  display: flex;
`;

const SiteMap = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  & .page-btn {
    flex: 1;
    margin-left: 15px;
    margin-right: 15px;
    margin-left: 0.9vw;
    margin-right: 0.9vw;
    text-decoration: none;
    color: black;
  }
  & .page-btn a {
    color: black;
    text-decoration: none;
  }
  & a.page-btn:hover {
    color: #666666;
    text-decoration: none;
  }
  & .page-btn img {
    cursor: pointer;
  }
`;

const StyledSocialMenu = styled.div`
  a:hover {
    color: #666666 !important;
  }
`

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { makeRequest } from '../hooks/Resource';
import { ProductMinimal, ShopifyProductsResponse } from '../pages/Home';
import { ProductItem } from './ProductItem';
import { sortBy } from 'lodash';

export const SearchSection: React.FC<{
  menu?: any;
  searchText?: string;
  onCartChange: () => void;
}> = ({ searchText, onCartChange }) => {
  const nav = useNavigate();
  const [loggedIn, setLoggedIn] = React.useState<boolean>(
    !!localStorage.getItem('token')
  );
  const [products, setProducts] = React.useState<ProductMinimal[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [menu, setMenu] = React.useState<{ title: string; url: string }[]>([]);
  const [customerData, setCustomerData] = React.useState<any>(null);
  const [userFetched, setUserFetched] = React.useState<boolean>(false);
  const [imagesArr, setImagesArr] = React.useState<
    { url: string; altText?: string }[]
  >([]);
  const getCustomer = async () => {
    if (userFetched) return;
    setUserFetched(true);
    const accessToken = localStorage.getItem('token');
    const resp = await makeRequest<any>(`/api/shopify/user/${accessToken}`);
    const customer = resp.user?.body?.data?.customer;
    localStorage.setItem('customer_id', customer.id);
    const shipping = resp.user?.body?.data?.customer.defaultAddress;
    localStorage.setItem(
      'shipping-address',
      JSON.stringify(shipping)
    );
    if (!customer) {
      return;
    }
    setCustomerData(customer);
  };
  const getShopifyMenu = async () => {
    const resp = await makeRequest<{
      menu: {
        body: { data: { menu: { items: { title: string; url: string }[] } } };
      };
    }>(`/api/shopify/menu`);
    setMenu(resp.menu.body.data.menu.items);
  };

  const getSecondaryImage = (images: any[], featuredImage: string) => {
    const isAltTextExistForFirstImage =
      images &&
      images[0] &&
      images[0].altText &&
      images[0].altText.includes('#secondary');
    if (isAltTextExistForFirstImage) {
      return images[1].url;
    }

    return featuredImage;
  };
  const getShopifyProducts = async () => {
    const resp = await makeRequest<{
      products?: { body?: { data?: { products: ShopifyProductsResponse } } };
    }>(`/api/shopify/products`);
    if (resp?.products?.body?.data?.products?.edges?.length) {
      const productResp: ProductMinimal[] =
        resp.products.body.data.products.edges.flatMap((r) => {
          const ids = r.node.id?.split('/');

          return {
            id: +((ids?.length && ids[ids.length - 1]) || 0),
            title: r.node.title,
            tags: r.node.tags,
            collections: r.node.collections,
            productType: r.node.productType,
            featuredImage: {
              url: getSecondaryImage(
                r.node.images.nodes,
                r.node.featuredImage.url
              ),
            },
            images: r.node.images.nodes.map(({ url }) => url),
            variants: r.node.variants.nodes.map((v) => {
              const variantsPathArr = `${v.id}`.split('/');
              return {
                ...v,
                id: +(
                  (variantsPathArr?.length &&
                    variantsPathArr[variantsPathArr.length - 1]) ||
                  0
                ),
                price: +(v.price || 0),
              };
            }),
          };
        });
      setProducts(productResp);
    }

    setLoaded(true);
  };

  React.useEffect(() => {
    loggedIn && getCustomer();
  }, [loggedIn]);
  loggedIn && getCustomer();

  React.useEffect(() => {
    getShopifyProducts();
    !menu.length && getShopifyMenu();
  }, []);

  const sortedProducts = sortBy(
    products,
    (item) => !item.tags.includes('productIsEarlyAccess')
  );

  const filteredProducts = sortedProducts.filter((prod) => {
    const titleMatch = prod.title
      .toLowerCase()
      .includes((searchText ?? '').toLowerCase());

    const tagsMatch = prod.tags.some((t) =>
      t.toLowerCase().includes((searchText ?? '').toLowerCase())
    );
    const productTypeMatch = prod.productType
      .toLowerCase()
      .includes((searchText ?? '').toLowerCase());

    const collectionMatch = prod.collections.nodes.some((c) =>
      c.handle.toLowerCase().includes((searchText ?? '').toLowerCase())
    );

    const productIsEarlyAccess = prod.tags.includes('productIsEarlyAccess');
    const userHasEarlyAccess = customerData?.tags?.includes?.('earlyAccess');
    if (productIsEarlyAccess && !userHasEarlyAccess) return false;
    return titleMatch || tagsMatch || productTypeMatch || collectionMatch;
  });

  return (
    <Container key={filteredProducts.length}>
      <Column>
        <TopPadding />
        {loaded && filteredProducts.length === 0 && (
          <NoProductText style={{ marginBottom: 50 }}>
            There are 0 products that match your search
          </NoProductText>
        )}
        {(filteredProducts.length > 0 ? filteredProducts : products).map(
          (product, i) => {
            return (
              <ProductItem
                key={i}
                menu={menu}
                product={product}
                onCartChange={onCartChange}
              />
            );
          }
        )}
        {filteredProducts.length === 0 ? (
          <>
            <div style={{ height: 100, width: '100%' }} />
          </>
        ) : (
          <div />
        )}
      </Column>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: -30px;
`;

const TopPadding = styled.div`
  margin-top: 90px;
  margin-top: 5.5vw;

  @media (max-width: 700px) {
    margin-top: 0px;
  }
`;

const NoProductText = styled.div`
  @media (max-width: 700px) {
    font-size: 3vw;
    margin-top: 20px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

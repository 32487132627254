import React from 'react';
import styled from 'styled-components';

interface MenuButtonProps {
  white?: boolean;
  active: boolean;
  onClick: (active: boolean) => void;
}

export const MenuButton: React.FC<MenuButtonProps> = ({
  white,
  active,
  onClick,
}) => {
  return (
    <Container white={white} onClick={() => onClick(!active)}>
      <HamburgerBarWrapper className='one' active={false} white={white}>
        <HamburgerBar className='bar' active={false} />
      </HamburgerBarWrapper>
      <HamburgerBarWrapper className='two' active={false} white={white}>
        <HamburgerBar className='bar two' active={false} />
      </HamburgerBarWrapper>
      <HamburgerBarWrapper className='three' active={false} white={white}>
        <HamburgerBar className='bar three' active={false} />
      </HamburgerBarWrapper>
    </Container>
  );
};

const Container = styled.button<{ white?: boolean }>`
  display: flex;
  flex-direction: column;
  // background: ${({ white }) => (white ? `white` : 'var(--primary-color)')};
  align-items: center;
  justify-content: center;
  // padding: 7px;
  border: 0;
  background: transparent;
  cursor: pointer;
  transform: scale(0.7);
  // z-index: 10;
  z-index: 1;
  margin:0;
  transition: all 0.3s ease-in-out;
  transform-origin: left;
  padding: 0;
  & .bar {
    background: ${({ white }) => (white ? 'white' : 'black')};
  }
`;

const HamburgerBarWrapper = styled.div<{ active: boolean; white?: boolean }>`
  &.one {
    transform: translateY(${({ active }) => (active ? 6 : 0)}px);
    transition: all 0.3s ease-in-out;
  }
  &.one .bar {
    transform: rotate(${({ active }) => (active ? 45 : 0)}deg);
    background: ${({ active, white }) =>
      active ? 'black' : white ? 'white' : 'black'};
    transition: all 0.3s ease-in-out;
  }
  &.two {
    opacity: ${({ active }) => (active ? 0 : 1)};
    transition: all 0.3s ease-in-out;
  }
  &.three {
    transform: translateY(${({ active }) => (active ? -6 : 0)}px);
    transition: all 0.3s ease-in-out;
  }
  &.three .bar {
    transform: rotate(${({ active }) => (active ? -45 : 0)}deg);
    background: ${({ active, white }) =>
      active ? 'black' : white ? 'white' : 'black'};
    transition: all 0.3s ease-in-out;
  }
`;
const HamburgerBar = styled.div<{ active: boolean }>`
  border-radius: 10px;
  width: 20px;
  height: 3px;
  margin: 1.5px 0px;
`;

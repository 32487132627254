import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import styled, { CSSProperties } from 'styled-components';
import FadeIn from 'react-fade-in';
import { useOnScreen } from '../hooks/useOnScreen';
import { Button, DropDown } from '../utils/Constants';

import toast from 'react-hot-toast';
import { ProductMinimal } from '../pages/Home';
import { addToCart } from '../helper/Common';

export const notify = (msg: string | ReactElement) =>
  toast(msg, { duration: 2500 });

interface ProductItemProps {
  product: ProductMinimal;
  imgWidth?: number | string;
  imgHeight?: number | string;
  style?: CSSProperties;
  menu?: any;
  mode?: 'PRODUCT' | 'ATELIER';
  onCartChange: () => void;
}
export const ProductItem: React.FC<ProductItemProps> = (props) => {
  const {
    product,
    imgWidth = '23vw',
    imgHeight = '24vw',
    style = {},
    mode = 'PRODUCT',
    onCartChange,
    menu = [],
  } = props;

  const nav = useNavigate();

  const ref = React.useRef<HTMLDivElement | null>(null);
  const isOnScreen = useOnScreen(ref);
  const [size, setSize] = React.useState<number | null>(null);
  const [hovering, setHovering] = React.useState<boolean>(false);
  const { images = [] } = product;

  const formattedCollection = product.collections.nodes.map((item) =>
    item.handle?.toLowerCase()
  );
  const formattedMenu =
    menu.length && menu.map((item: any) => item.title.toLowerCase());

  const selectedCollectionTitle =
    (formattedCollection.length &&
      formattedCollection?.filter((value) =>
        formattedMenu?.includes?.(value)
      )?.[0]) ??
    'SHOP';

  const getOptions = () => {
    const options: any = [];

    product.variants.map((p, i) => {
      if (p.currentlyNotInStock || p.quantityAvailable > 0) {
        options[i] = { label: i + 1, value: i + 1, isDisabled: true };
      } else {
        options[i] = { label: i + 1, value: i + 1, isDisabled: false };
      }
    });
    //console.log('options', options);

    return options;
  };
  return (
    <FadeIn visible={isOnScreen} delay={200}>
      <Container ref={ref} style={style}>
        <Image
          onMouseOver={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setHovering(true);
          }}
          onMouseUp={() => {
            setHovering(false);
            nav(
              `/product/${selectedCollectionTitle}/${product.id}/${product.title}`
            );
          }}
          imgWidth={imgWidth}
          imgHeight={imgHeight}
          style={{ objectFit: 'contain' }}
          src={
            hovering && images.length > 1
              ? images[1]
              : product.featuredImage.url
          }
          // onClick={() =>
          //   nav(
          //     `/product/${selectedCollectionTitle}/${product.id}/${product.title}`
          //   )
          // }
        />
        <div style={{ marginTop: 10 }} />
        {mode === 'PRODUCT' && (
          <p
            className={'lt-wave'}
            style={{ textTransform: 'uppercase' }}
            onClick={(e) => {
              nav(
                `/product/${selectedCollectionTitle}/${product.id}/${product.title}`
              );
            }}
          >
            {product.title.toUpperCase()}
          </p>
        )}
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginLeft: 10,
            marginRight: 10,
            marginTop: -10,
          }}
        >
          {mode === 'PRODUCT' ? (
            <>
              <StyledDropDown
                isProductItem={true}
                style={{
                  marginRight: 20,
                }}
                title={`${size ?? 'SIZE'}`}
                onChange={(val) => {
                  setSize(+val);
                }}
                options={getOptions()}
              />
              <AddToCartButton
                style={{
                  width: 'auto',
                  flex: 1,
                }}
                onClick={() => {
                  addToCart(product, size);
                  onCartChange();
                }}
              >
                ADD TO CART
              </AddToCartButton>
            </>
          ) : (
            <h5 style={{ width: '100%', textAlign: 'center' }}>{product.id}</h5>
          )}
        </div>
      </Container>
    </FadeIn>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;

  & p {
    font-weight: 600;
    margin-bottom: 40px;
    font-size: 18px;
    font-size: 1.3vw;
    cursor: pointer;
    @media (max-width: 700px) {
      font-size: 18px;
    }
  }
  & p:hover {
    text-decoration: underline;
  }

  & h5 {
    font-weight: 700;
    font-size: 14px;
    font-size: 0.85vw;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
  & .select__placeholder {
    // margin-left: 10px;
    margin-right: -5px;
    @media (max-width: 700px) {
      // color: black;
    }
  }
`;

const Image = styled.img<{
  imgWidth: string | number;
  imgHeight: string | number;
}>`
  height: auto;
  object-fit: contain;
  width: ${({ imgWidth }) =>
    typeof imgWidth === 'number' ? `${imgWidth}px` : imgWidth};
  width: ${({ imgHeight }) =>
    typeof imgHeight === 'number' ? `${imgHeight}px` : imgHeight};

  @media (max-width: 700px) {
    width: 360px;
    height: auto;
  }
`;
const AddToCartButton = styled(Button)`
  width: 100%;
  @media (min-width: 700px) {
    width: calc(19.7vw - 2.5vw);
  }
  @media (min-width: 1200) {
    font-size: 0.9vw;
  }
`;

const StyledDropDown = styled(DropDown)``;

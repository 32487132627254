export const hasValue = (field: any) => {
  return field !== null && field !== 'null' && typeof field !== 'undefined';
};

export const hasTextLength = (string: any) => {
  if (!string || !hasValue(string) || string.length === 0) {
    return false;
  }

  return true;
};

import { compact, uniqBy } from 'lodash';
import React, { Fragment } from 'react';
import FadeIn from 'react-fade-in';
import { useNavigate } from 'react-router-dom';
import styled, { CSSProperties } from 'styled-components';
import { makeRequest } from '../hooks/Resource';
import { ShopifyProductsResponse } from '../pages/Home';
import { Product, ProductVariant } from '../pages/ProductDetail';
import { Button } from '../utils/Constants';
import { CartItem } from './CartItem';
import { Link } from 'react-router-dom';
import { ReactComponent as DownArrow } from '../images/arrow-down.svg';

interface CartPreviewProps {
  cartItems: { id: number; size: number; variant: ProductVariant }[];
  visible: boolean;
  onChange: (visible: boolean) => void;
  style?: CSSProperties;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}
export const CartPreview: React.FC<CartPreviewProps> = ({
  cartItems,
  visible,
  onChange,
  style = {},
  onMouseEnter,
  onMouseLeave,
}) => {
  const [productsInCart, setProductsInCart] = React.useState<Product[]>([]);
  const [cartProducts, setCartProducts] = React.useState<
    { id: number; size: number; variant: ProductVariant }[]
  >([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);
  const nav = useNavigate();

  const removeFromCart = (productId: number, title: string) => {
    const cartString = localStorage.getItem('cart');
    let cart: { id: number; size: number; variant: ProductVariant }[] = [];
    if (cartString) {
      cart = JSON.parse(cartString);
    }
    cart = cart.filter((c) => c.id !== productId);
    console.log('removing', { cart });
    localStorage.setItem('cart', JSON.stringify(cart));
    // setProductsInCart(
    //   productsInCart.filter((p) => cart.map((c) => c.id).includes(p.id))
    // );
    // setCartProducts(cart);
    // notify(`Removed ${title} from cart`);
  };

  const getCartProductIds = () => {
    const cartString = localStorage.getItem('cart');
    let cart: { id: number; size: number; variant: ProductVariant }[] = [];
    if (cartString) {
      cart = JSON.parse(cartString);
    }
    // cart = [...cart.filter((c) => c !== productId), productId];
    setCartProducts(cart);
    return cart;
  };

  const getShopifyProducts = async () => {
    const resp = await makeRequest<{
      products?: { body?: { data?: { products: ShopifyProductsResponse } } };
    }>(`/api/shopify/products`);

    if (resp?.products?.body?.data?.products?.edges?.length) {
      const productResp: Product[] =
        resp.products.body.data.products.edges.flatMap((r) => {
          // console.log({ node: r.node });
          const ids = r.node.id?.split('/');
          return {
            id: +((ids?.length && ids[ids.length - 1]) || 0),
            // title: r.node.title,
            featuredImage: { url: r.node.featuredImage.url },
            // id: +(r.node.id.split() || 0),
            title: r.node.title,
            img: r.node.images.nodes[0].url,
            tags: r.node.tags,
            collections: r.node.collections,
            productType: r.node.productType,
            metafields: r.node.metafields,
            images: r.node.images.nodes.map((im) => im.url),
            description: r.node.description,
            price: 0,
            variants: r.node.variants.nodes.map((v) => {
              const variantsPathArr = `${v.id}`.split('/');
              return {
                id: +(
                  (variantsPathArr?.length &&
                    variantsPathArr[variantsPathArr.length - 1]) ||
                  0
                ),
                price: +(v.price || 0),
              };
            }),
          };
        });

      const cart = await getCartProductIds();
      const products = productResp.filter((p) =>
        cart.map((c) => c.id).includes(p.id)
      );
      const uniqueCart = uniqBy(cart, (c) => [c.id, c.size].join());
      const newval = compact(
        uniqueCart.map((uc) => {
          const prod = products.find((p) => p.id === uc.id);
          if (!prod) {
            return undefined;
          }

          return { ...prod, size: uc.size || 1 };
        })
      );

      await setProductsInCart(newval);
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    getShopifyProducts();
  }, [visible]);

  const total = productsInCart.reduce((total, currentValue) => {
    const quantity = cartProducts.filter(
      (cp) => cp.id === currentValue.id && cp.size === currentValue.size
    ).length;
    const size =
      cartProducts.find(
        (cp) => cp.id === currentValue.id && cp.size === currentValue.size
      )?.size ?? 0;
    const currentIndex = size > 0 ? size - 1 : 0;
    return total + currentValue.variants?.[currentIndex]?.price * quantity;
  }, 0);

  if (!visible) return <Fragment />;
  const itemLength = productsInCart.length;
  const hasCartItems = itemLength > 0;
  let isMobile = /iphone|ipod|android|ie|blackberry|fennec/.test(
    navigator.userAgent.toLowerCase()
  );

  let containerHeight = isMobile ? '270px' : '370px';
  if (itemLength == 1) containerHeight = isMobile ? '270px' : '250px';
  if (itemLength > 1) containerHeight = isMobile ? '200px' : '315px';
  const items = isMobile
    ? productsInCart.slice(itemLength - 1, itemLength)
    : productsInCart;
  const isMinItemLength = itemLength > 1;
  const remainItems = itemLength - 1;
  const CART_LOADING_ITEMS = isLoading && <span>Loading...</span>;
  const CART_ITEMS =
    hasCartItems &&
    !isLoading &&
    items.map((product, idx) => {
      return (
        <CartItem
          isPreview
          setHeight={isMobile && itemLength > 1}
          isLengthOne={itemLength == 1}
          product={product}
          key={idx}
          onRemove={() => {} /*removeFromCart(product.id, product.title)*/}
          size={product.size || 1}
          quantity={
            cartProducts.filter(
              (c) => c.id === product.id && c.size === product.size
            ).length
          }
          showHeader={idx === 0}
          imageSize={'small'}
          showSubtotals={
            productsInCart.filter(
              (product) =>
                cartProducts.filter(
                  (c) => c.id === product.id && c.size === product.size
                ).length
            ).length -
              1 ===
            idx
          }
          total={total}
        />
      );
    });

  const EMPTY_CART = !hasCartItems && (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <h4>No items in cart!</h4>
    </div>
  );

  const PROCEED_TO_CART = hasCartItems && (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        zIndex: 100,
      }}
    >
      <div style={{ marginTop: 7 }}>
        {isMobile && isMinItemLength && (
          <P
            style={{
              display: 'inline-block',
              width: '100%',
            }}
          >
            <Divider style={{ marginBottom: 7 }} />
            {`(${remainItems}) Other Items in your shopping bag. `}
            <Link to={'/cart'} style={{ color: 'black', zIndex: 111 }}>
              <P
                style={{
                  textTransform: 'uppercase',
                  justifyContent: 'flex-start',
                }}
              >
                View Bag
              </P>
            </Link>
          </P>
        )}
      </div>
      {!isMobile && itemLength > 2 ? (
        <div style={{ marginTop: 5, marginBottom: 5 }}>
          <DownArrow />
        </div>
      ) : (
        <div style={{ marginTop: 5, marginBottom: 5 }} />
      )}
      <CheckoutButton
        style={{
          marginTop: '5px',
          marginBottom: '10px',
          // width: `20vw`,
          width: '80%',
          // fontSize: '0.9vw',
        }}
        onClick={async () => {
          await nav('/cart');
          onChange(false);
        }}
      >
        PROCEED TO CHECKOUT
      </CheckoutButton>
    </div>
  );
  return (
    <Container
      onClick={() => onChange(!visible)}
      style={{ pointerEvents: visible ? 'auto' : 'none' }}
    >
      <StyledFadeIn visible={visible} delay={0}>
        <CardContainer
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onMouseEnter?.();
            onChange(true);
          }}
          onMouseLeave={() => {
            onMouseLeave?.();
            onChange(false);
          }}
          onTouchStart={(e: any) => {
            if (!isMobile) return;
            setTouchStart(e.targetTouches[0].clientY);
          }}
          onTouchMove={(e: any) => {
            if (!isMobile) return;
            setTouchEnd(e.targetTouches[0].clientY);
          }}
          onTouchEnd={() => {
            if (!isMobile) return;
            const touch = touchStart - touchEnd;
            const isSwipeUp = touch > 30 && touch < 100;
            if (isSwipeUp || touch < -30) {
              onChange(false);
            }
          }}
          style={{
            position: 'fixed',
            zIndex: 99,
            overflowY: 'scroll',
            ...style,
          }}
        >
          <div
            style={{
              overflowY: 'scroll',
              ...(isMobile && {
                overflowY: 'hidden',
              }),
              height: containerHeight,
              width: '100%',
            }}
          >
            <h4 style={{ marginBottom: 20, padding: 0 }}>SHOPPING BAG</h4>
            {CART_LOADING_ITEMS}
            {CART_ITEMS}
            {EMPTY_CART}
          </div>
          {PROCEED_TO_CART}
        </CardContainer>
      </StyledFadeIn>
    </Container>
  );
};

const Container = styled.div`
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  right: 0;
  top: 0px;
  position: fixed;
  z-index: 99;
  background: rgba(0, 0, 0, 0);
  min-width: 320px;

  display: flex;
  justify-content: center;
  align-items: center;

  & div:first-child {
    @media (max-width: 700px) {
      width: 100%;
      max-width: 100%;
      margin: auto;
      transform: translateY(0px);
    }
  }
`;
const P = styled.p`
  font-weight: 400;
  margin: 0;
  @media (min-width: 700px) {
    font-size: 0.7vw;
  }
  @media (max-width: 700px) {
    font-size: 12px;
  }
`;

const StyledFadeIn = styled(FadeIn)`
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 320px;
  @media (max-width: 700px) {
    display: flex;
    justify-content: center;
    display: flex;
    align-items: center;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  width: 360px;
  width: 23vw;
  padding: 10px;
  align-items: center;
  text-align: center;
  background: white;
  box-shadow: 0px 15px 30px rgba(150, 150, 155, 0.5);
  min-width: 320px;
  right: 20px;
  top: 50px;
  max-height: 650px;
  overflow-y: scroll;
`;
const Divider = styled.div`
  width: 100%;
  height: 3px;
  background: var(--light-blue);
`;
const CheckoutButton = styled(Button)`
  margin-top: 30px;
  cursor: pointer;
  width: 100%;
  @media (min-width: 700px) {
    width: 23vw;
    min-height: 2.8vw;
  }
  &.mobile {
    width: 100%;
    @media (min-width: 700px) {
      display: none;
    }
  }
  &.desktop {
    @media (max-width: 700px) {
      display: none;
    }
  }
`;

import { format } from 'date-fns';
import React from 'react';
import FadeIn from 'react-fade-in';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Column, toPrice } from '../helper/Common';
import { OrderHistoryResponse, OrderItem } from './OrderHistory';

export const OrderDetails: React.FC<{
  order: OrderItem;
  customer: OrderHistoryResponse;
}> = ({ order, customer }) => {
  const [params, setParams] = useSearchParams();

  return (
    <>
      <DesktopContainer>
        <OuterLeftColumn>
          <StyledFadeIn>
            <h2 style={{ width: '100%', textAlign: 'center' }}>Account</h2>
            <u
              style={{ display: 'block', width: '100%', textAlign: 'center' }}
              onClick={() => setParams({})}
            >
              Return to Order History
            </u>
            {/* <div style={{ marginTop: 50 }} /> */}
            <Content>
              <LeftColumn>
                <div className="order-num">Order #{order.orderNumber}</div>
                <div className="purchase-date">
                  {/* Placed on September 29, 2022 at 2:01 pm */}
                  Placed on{' '}
                  {format(new Date(order.processedAt), 'MMMM d, yyyy')}
                  {/* at {format(new Date(order.processedAt), 'h:mm a')} */}
                </div>
                {/* <div style={{ marginTop: 30 }} /> */}
                {/* <OrderTable></OrderTable> */}
                <Divider />
                <table role="table" className="order-details">
                  <thead role="rowgroup">
                    <tr role="row">
                      <th id="ColumnProduct" scope="col" role="columnheader">
                        Product
                      </th>
                      <th id="ColumnSku" scope="col" role="columnheader">
                        SKU
                      </th>
                      <th
                        id="ColumnPrice"
                        scope="col"
                        role="columnheader"
                        style={{ textAlign: 'center' }}
                      >
                        Price
                      </th>
                      <th id="ColumnQuantity" scope="col" role="columnheader">
                        Quantity
                      </th>
                      <th id="ColumnTotal" scope="col" role="columnheader">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    {order.lineItems.nodes.map((li) => (
                      <tr role="row">
                        <td
                          id="Row12200283078793"
                          headers="ColumnProduct"
                          role="rowheader"
                          scope="row"
                          data-label="Product"
                        >
                          <div>
                            <a href="/products/kkk-t-shirt?variant=40476863070345">
                              {li.title.toUpperCase()}
                            </a>
                          </div>
                        </td>
                        <td
                          headers="Row12200283078793 ColumnSku"
                          role="cell"
                          data-label="SKU"
                        ></td>
                        <td
                          headers="Row12200283078793 ColumnPrice"
                          role="cell"
                          data-label="Price"
                          style={{ textAlign: 'center' }}
                        >
                          <span>{toPrice(li.variant.price)}</span>
                        </td>
                        <td
                          headers="Row12200283078793 ColumnQuantity"
                          role="cell"
                          data-label="Quantity"
                        >
                          {li.quantity}
                        </td>
                        <td
                          headers="Row12200283078793 ColumnTotal"
                          role="cell"
                          data-label="Total"
                          style={{ textAlign: 'right' }}
                        >
                          {toPrice(li.discountedTotalPrice.amount)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot role="rowgroup">
                    <tr role="row">
                      <td
                        id="RowSubtotal"
                        role="rowheader"
                        scope="row"
                        colSpan={4}
                      >
                        Subtotal
                      </td>
                      <td
                        headers="RowSubtotal"
                        role="cell"
                        data-label="Subtotal"
                        style={{ textAlign: 'right' }}
                      >
                        {toPrice(order.totalPrice)}
                      </td>
                    </tr>
                    <tr role="row">
                      <td
                        id="RowShipping"
                        role="rowheader"
                        scope="row"
                        colSpan={4}
                      >
                        Shipping
                      </td>
                      <td
                        headers="RowShipping"
                        role="cell"
                        data-label="Shipping"
                        style={{ textAlign: 'right' }}
                      >
                        {toPrice(order.totalShippingPrice)}
                      </td>
                    </tr>
                    <tr role="row">
                      <td
                        id="RowTotal"
                        role="rowheader"
                        scope="row"
                        colSpan={3}
                      >
                        Total
                      </td>
                      <td
                        headers="RowTotal"
                        role="cell"
                        colSpan={2}
                        data-label="Total"
                        style={{ textAlign: 'right' }}
                      >
                        {toPrice(order.totalPrice)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </LeftColumn>
              <RightColumn>
                {/* <h3>Billing Address</h3>
              <div>{customer.displayName}</div>
              <div>{customer.addresses.nodes[0].address1}</div>
              {customer.addresses.nodes[0].address2 && (
                <div>{customer.addresses.nodes[0].address2}</div>
              )}
              <div>
                {customer.addresses.nodes[0].city}{' '}
                {customer.addresses.nodes[0].province}{' '}
                {customer.addresses.nodes[0].zip}
              </div>
              {/* <div>{customer.addresses.nodes[1].country}</div> */}
                {/* <div style={{ marginTop: 20 }} />} */}
                <h3>Shipping Address</h3>
                <div style={{ marginBottom: 10 }}>
                  Payment Status: {order.financialStatus}
                </div>
                <div style={{ marginBottom: 10 }}>
                  Fulfillment Status: {order.fulfillmentStatus}
                </div>
                <div>
                  {order.shippingAddress.company || customer.displayName}
                </div>
                <div>{order.shippingAddress.address1}</div>
                {order.shippingAddress.address1 && (
                  <div>{order.shippingAddress.address2}</div>
                )}
                <div>
                  {order.shippingAddress.city} {order.shippingAddress.province}{' '}
                  {order.shippingAddress.zip}
                </div>
                <div>{order.shippingAddress.country}</div>
              </RightColumn>
            </Content>
          </StyledFadeIn>
        </OuterLeftColumn>
      </DesktopContainer>
      <MobileContainer>
        <StyledFadeIn>
          <h2>Order Details</h2>
          <u onClick={() => setParams({})}>Return to Order History</u>
          <div style={{ marginTop: '10vw' }} />
          <div className="order-num">Order #{order.orderNumber}</div>
          <div className="purchase-date">
            {/* Placed on September 29, 2022 at 2:01 pm */}
            Placed on {format(new Date(order.processedAt), 'MMMM d, yyyy')}
            {/* at {format(new Date(order.processedAt), 'h:mm a')} */}
          </div>
          {order.lineItems.nodes.map((li) => (
            <>
              <div className="row">
                <div className="header">PRODUCT</div>
                <div style={{ flex: 1 }} />
                <div className={'value'}>
                  {order.lineItems.nodes[0].title.toUpperCase()}
                </div>
              </div>
              {/* <div className="row">
            <div className="header">SKU</div>
            <div style={{ flex: 1 }} />
            <div className={'value'}>{''}</div>
          </div> */}
              <div className="row">
                <div className="header">PRICE</div>
                <div style={{ flex: 1 }} />
                <div className={'value'}>{toPrice(li.variant.price)}</div>
              </div>
              <div className="row">
                <div className="header">QUANTITY</div>
                <div style={{ flex: 1 }} />
                <div className={'value'}>{li.quantity}</div>
              </div>
              <div className="row ">
                <div className="header">TOTAL</div>
                <div style={{ flex: 1 }} />
                <div className={'value'}>
                  {toPrice(li.discountedTotalPrice.amount)}
                </div>
              </div>

              <div style={{ marginTop: '10vw' }} />
            </>
          ))}
          <div className="row ">
            <div className="header">SHIPPING</div>
            <div style={{ flex: 1 }} />
            <div className={'value'}>{toPrice(order.totalShippingPrice)}</div>
          </div>

          <div className="row total ">
            <div className="header">TOTAL</div>
            <div style={{ flex: 1 }} />
            <div className={'value'}>{toPrice(order.totalPrice)}</div>
          </div>

          <div style={{ marginTop: '20vw' }} />

          {/* <h3>Billing Address</h3>
          <div>Daniel Ennis</div>
          <div>2 High St.</div>
          <div>Brookeville MD 20833</div>
          <div>United States</div>
          <div style={{ marginTop: 20 }} /> */}
          <h3>Shipping Address</h3>
          <div>Payment Status: {order.financialStatus}</div>
          <div>Fulfillment Status: {order.fulfillmentStatus}</div>
          <div>{order.shippingAddress.company || customer.displayName}</div>
          <div>{order.shippingAddress.address1}</div>
          {order.shippingAddress.address2 && (
            <div>{order.shippingAddress.address2}</div>
          )}
          <div>
            {order.shippingAddress.city} {order.shippingAddress.province}{' '}
            {order.shippingAddress.zip}
          </div>
          <div>{order.shippingAddress.country}</div>
          <div style={{ marginTop: '20vw' }} />
        </StyledFadeIn>
      </MobileContainer>
    </>
  );
};

const Divider = styled.div`
  margin-bottom: 10px;
  width: 100%;
  height: 3px;
  background: var(--light-blue);
  margin-top: 10px;
`;

const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  & h2 {
    font-size: 1.6vw;
    margin-bottom: 0;
  }
  & u {
    font-size: 0.9vw;
  }
  & .order-num {
  }
  & .purchase-date {
    font-size: 0.7vw;
  }
  @media (min-width: 700px) {
    padding: 100px;
    padding-top: 40px;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

const StyledFadeIn = styled(FadeIn)``;

const Content = styled.div`
  display: flex;

  & .order-details {
    table-layout: auto;
    border-collapse: collapse;
    border-bottom: 0.01rem solid rgba(0, 0, 0, 0.08);
    box-shadow: none;
    width: 100%;
    font-size: 1.6rem;
    // font-size: 0.7em;
    position: relative;

    @media (min-width: 700px) {
      border: none;
      // box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.08);
    }
  }

  & .row-group {
    border-bottom: 0.01rem solid rgba(0, 0, 0, 0.08);
  }

  & .order-details th,
  .order-details td {
    padding: 5px 10px;
  }

  & a {
    color: black;
  }

  & .order-details thead th {
    font-size: 1vw;
    font-size: 0.7vw;
    font-weight: 400;
    text-transform: uppercase;
    text-align: left;
  }

  & .order-details {
    // font-size: 1vw;
    font-size: 0.7vw;
    font-weight: 400;
  }
`;

const LeftColumn = styled(Column)`
  align-items: flex-start;
  justify-content: flex-start;
  flex: 3;
  margin-right: 20px;
`;

const RightColumn = styled(Column)`
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;

  font-size: 0.8vw;
`;

const OrderTable = styled.div`
display: flex;
border: 1
  width: 100%;
`;

// MOBILE
const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  & .row:first-child {
    margin-top: 10vw;
  }
  & .row {
    display: flex;
    font-size: 3vw;
  }
  & .row.total {
    font-size: 4vw;
    font-weight: 500;
  }
  & .purchase-date {
    font-size: 2.5vw;
  }
  @media (min-width: 700px) {
    padding: 100px;
  }

  @media (min-width: 700px) {
    display: none;
  }
`;

const OuterLeftColumn = styled(Column)`
  margin-top: 0px;
  @media (max-width: 700px) {
    display: none;
  }
`;

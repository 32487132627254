import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { CSSProperties } from 'styled-components';
import { AccountMenuList } from '../helper/Common';

export const MobileAccountNav: React.FC<{ style?: CSSProperties }> = ({
  style = {},
}) => {
  const nav = useNavigate();
  return (
    <Container>
      <div
        className={'master-list'}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {AccountMenuList.map((item, i) => {
          return (
            <div key={i}>
              {i !== 0 && <>&#x2022;</>}{' '}
              <div
                className={
                  window.location.pathname.includes(item.link) ? 'selected' : ''
                }
                style={{ whiteSpace: 'nowrap', display: 'inline-block' }}
                onClick={() => nav(item.link)}
              >
                {item.label}
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 700px) {
    display: none;
  }

  & .measured-text {
    font-size: 0.7vw;
    @media (max-width: 700px) {
      font-size: 10px;
    }
  }

  & h4 {
    margin-top: 5px;
    width: 33.8vw;
    @media (max-width: 900px) {
      font-size: 14px;
      width: 100%;
    }
    @media (max-width: 700px) {
      // margin-top: 50px;
    }
  }

  & .master-list .selected {
    font-weight: normal;
  }
  & .master-list {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2.6vw;
    min-width: 320px;
    width: 33.8vw;
    @media (max-width: 900px) {
      /*font-size: 12px;*/
      justify-content: center;
    }

    & div {
      margin: 2.2px;
      cursor: pointer;
      text-align: center;
    }
    & .size-btn-wrapper {
      margin-top: 35px;
      justify-content: center;
      @media (max-width: 700px) {
        margin-top: 20px;
      }
      // @media (max-width: 700px) {
      //   width: calc(100% - 5vw);

      //   padding-left: 23vw;
      //   padding-right: 23vw;
      // }
    }
  }
`;

import qs from 'query-string';
import React from 'react';

/*
The `Resource` component is a Redux-connected component that takes a render prop as it's child.
Given a resource `path`, it calls the render prop with the currently cached data at that path,
(or the `placeholder` data if provided) and makes an API request to refresh it with the latest
data if necessary.

The `Resource` component also provides it's children with helper functions that they can call to
update or delete the resource. Calling these helper methods makes the appropriate API requests
and then updates the local cache.

If you pass true for the `collection` prop, the `Resource` component expects an array to be
available at the path provided and exposes functions that allow items to be created, deleted
and updated.

Examples:

```jsx

  <Resource path={'/me'} placeholder={{ emailAddress: 'Loading...' }}>
    {(me, { onSave }) => (
      <div>{me.emailAddress}</div>
    )}
  </Resource>
```

```jsx

  <Resource collection path={'/properties'} placeholder={[]}>
    {(properties, { onCreateItem, onUpdateItem, onDeleteItem}) => (
      ...
      ... (call onCreateItem to add an item, etc.)
      ...
    )}
  </Resource>
```

*/

// Collection data provider

export const CACHED_USER = 'user-token';
export async function makeRequest<T>(
  path: string,
  method = 'GET',
  itemPayload: any = null
): Promise<T> {
  const options: any = {
    method,
  };

  const authorization = `Bearer ${localStorage.getItem(CACHED_USER)}`;
  options.headers = {
    'content-type': 'application/json',
    authorization,
  };
  if (itemPayload) {
    options.body = JSON.stringify(itemPayload);
  }

  const REACT_APP_API =
    window && window.location.hostname.includes('localhost')
      ? ''
      : 'https://carton.herokuapp.com';
  let response: Response | null = null;
  try {
    response = await fetch(`${REACT_APP_API}${path}`, options);
  } catch (err) {
    return { error: `${err}` } as any; // Usually a CORS issue which causes immediate failure
  }

  if (response.status === 401) {
    // window.location.href = '/g/login';
    throw new Error('Unauthorized error');
  }

  const text = await response.text();
  let json = { error: `${response.status} ${response.statusText}` };

  try {
    json = JSON.parse(text);
  } catch (err) {
    console.error(`${method} ${path} returned invalid JSON: ${text}`);
  }

  return json as any;
}

interface ResourceConfig {
  silent: boolean;
}

export interface ResourceOps<T, U = Partial<T>> {
  post: (v: U) => Promise<void>;
  put: (v: U) => Promise<void>;
  putItem: (item: { id: string | number } & U) => Promise<void>;
  delete: () => Promise<void>;
  deleteItem: (
    item: string | number | { id: string | number }
  ) => Promise<void>;
  applyLocalUpdates: (v: T) => void;
  refresh: () => Promise<T>;
}

export function useResource<T, U = Partial<T>>(
  path: string,
  query?: { [key: string]: any },
  config?: ResourceConfig
) {
  const [state, setState] = React.useState<
    { value: T; url: string } | undefined
  >(undefined);
  const url = `${path}${path.includes('?') ? '&' : '?'}${
    query ? qs.stringify(query) : ''
  }`;

  React.useEffect(() => {
    const fetch = async () => {
      setState(undefined);
      setState({ url, value: await makeRequest<T>(url, 'GET') });
    };
    void fetch();
  }, [url]);

  const ops: ResourceOps<T, U> = {
    post: async (v: U) => {
      try {
        const resp = await makeRequest<U>(path, 'POST', v);
        if ('id' in resp && state && state.value instanceof Array) {
          setState({ ...state, value: [...state.value, resp] as any });
        } else {
          console.warn(
            'useResource: POST state update skipped, response contains no id or state.value is not an array'
          );
        }
        // !config?.silent && void message.success('Item created');
      } catch (err) {
        // !config?.silent && void message.error('Failed to save, try again.');
        throw err;
      }
    },
    put: async (v: U) => {
      try {
        const resp = await makeRequest<T>(path, 'PUT', v);
        if (state) {
          if ('id' in resp) {
            setState({ ...state, value: Object.assign({}, state.value, resp) });
          } else if (state.value instanceof Array && resp instanceof Array) {
            setState({ ...state, value: resp });
          } else {
            console.warn(
              'useResource: PUT state update skipped, response does not look like object or array item'
            );
          }
        }
        // !config?.silent && void message.success('Changes saved');
      } catch (err) {
        // !config?.silent && void message.error('Failed to save, try again.');
        throw err;
      }
    },
    putItem: async (item: { id: string | number } & U) => {
      const resp = await makeRequest<any>(`${path}/${item.id}`, 'PUT', item);
      if (resp && 'id' in resp && state && state.value instanceof Array) {
        const nextValue: any = [];
        for (const item of state.value) {
          nextValue.push(item.id === resp.id ? resp : item);
        }
        setState({ ...state, value: nextValue });
      } else {
        console.warn(
          'useResource: PUT state update skipped, response does not look like array item'
        );
      }
      //   !config?.silent && void message.success('Updated successfully');
    },
    delete: async () => {
      await makeRequest<T>(path, 'DELETE');
      //   !config?.silent && void message.success('Deleted successfully');
    },
    deleteItem: async (item: string | number | { id: string | number }) => {
      const itemId = typeof item === 'object' && 'id' in item ? item.id : item;
      await makeRequest<T>(`${path}/${itemId}`, 'DELETE');
      //   !config?.silent && void message.success('Deleted successfully');
      if (state && state.value instanceof Array) {
        setState({
          ...state,
          value: state.value.filter((i) => i.id !== itemId) as any,
        });
      } else {
        console.warn(
          'useResource: DELETE state update skipped, local state is not an array of items'
        );
      }
    },
    applyLocalUpdates: (v: T) => {
      setState({ url, value: v });
    },
    refresh: async () => {
      const v = await makeRequest<T>(url, 'GET');
      setState({ url, value: v });
      return v;
    },
  };

  // Explicitly tell TS this is a tuple of two distinct types, not an array of (T | typeof Ops)
  if (state?.url === url) {
    return [state.value, ops] as [T | undefined, ResourceOps<T, U>];
  } else {
    return [undefined, ops] as [T | undefined, ResourceOps<T, U>];
  }
}

import { compact } from 'lodash';
import React from 'react';
import FadeIn from 'react-fade-in';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Login } from '../components/Login';
import { MobileAccountNav } from '../components/MobileAccountNav';
import { notify } from '../components/ProductItem';
import { ProfileSideNav } from '../components/profile/ProfileSideNav';
import { Textfield } from '../components/Textfield';
import { Column, TextfieldContainer } from '../helper/Common';
import { makeRequest } from '../hooks/Resource';
import { hasTextLength, hasValue } from '../utils/conditions';
import { Button } from '../utils/Constants';

export interface Address {
  id: string;
  address1: string;
  address2: string;
  city: string;
  company: string;
  country: string;
  firstName: string;
  formatted: string;
  lastName: string;
  name: string;
  provinceCode: string;
  province: string;
  phone: string;
  zip: string;
  defaultAddress: '';
}
export interface ResetPasswordInput {
  // old_password: string;
  new_password: string;
  email: string;
  // repeat_new_password: string;
}

export const BLANK_ADDRESS: Address = {
  id: '',
  address1: '',
  address2: '',
  city: '',
  company: '',
  country: '',
  firstName: '',
  formatted: '',
  lastName: '',
  name: '',
  provinceCode: '',
  province: '',
  phone: '',
  zip: '',
  defaultAddress: '',
};

export interface ProfileInput {
  firstName: string;
  lastName: string;
  email: string;
  shippingAddress: string;
  shippingAddress2: string;
  fullName: string;
  addr1: string;
  addr2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  primaryAddressId: string;
  shippingName: string;

  billingAddressId: string;
  billingAddress: string;
  billingFullName: string;
  billingAddr1: string;
  billingAddr2: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  billingPhone: string;
  billingName: string;

  addresses: Partial<Address>[];
  defaultAddressId: '';
}

export interface Customer {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  addresses: { nodes: Address[] };
  defaultAddress?: Address;
}

export const Profile: React.FC = () => {
  const [loggedIn, setLoggedIn] = React.useState<boolean>(
    !!localStorage.getItem('token')
  );
  const [userFetched, setUserFetched] = React.useState<boolean>(false);
  const [deleteAddressIds, setDeleteAddressIds] = React.useState<string[]>([]);
  const [shippingIdx, setShippingIdx] = React.useState<number>(-1);
  const [refresh, setRefresh] = React.useState<number>(0);
  const [address, setAddress] = React.useState<Address>(BLANK_ADDRESS);
  const [values, setValues] = React.useState<ProfileInput>({
    firstName: '',
    lastName: '',
    email: '',
    primaryAddressId: '',
    shippingAddress: '',
    shippingAddress2: '',
    fullName: '',
    addr1: '',
    addr2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    shippingName: '',

    billingAddressId: '',
    billingAddress: '',
    billingFullName: '',
    billingAddr1: '',
    billingAddr2: '',
    billingCity: '',
    billingState: '',
    billingZip: '',
    billingPhone: '',
    billingName: '',

    addresses: [],
    defaultAddressId: '',
  });

  const addresses = values.addresses;
  const hasAddress = !!addresses.length;
  const buttonLabel = hasAddress ? 'MANAGE ADDRESSES' : 'ADD AN ADDRESS';
  const navigate = useNavigate();
  const getCustomer = async () => {
    if (userFetched) return;
    setUserFetched(true);
    const accessToken = localStorage.getItem('token');
    const resp = await makeRequest<{
      user?: { body?: { data?: { customer?: Customer } } };
    }>(`/api/shopify/user/${accessToken}`);
    const customer = resp.user?.body?.data?.customer;

    if (!customer) {
      // localStorage.removeItem('token');
      // setLoggedIn(false);
      return;
    }
    console.log('getCustomer caled')
    const addresses: Partial<Address>[] = customer.addresses.nodes ?? [];
    const address: any = customer.defaultAddress ?? [];
    setAddress(address);
    setValues({
      ...values,
      email: customer.email,
      firstName: customer.firstName,
      lastName: customer.lastName,
      phone: customer.phone,
      addresses,
    });
    const shipping = customer.defaultAddress;
    localStorage.setItem(
      'shipping-address',
      JSON.stringify(shipping)
    );
  };

  React.useEffect(() => {
    loggedIn && getCustomer();
  }, [loggedIn]);
  loggedIn && getCustomer();

  if (!window) {
    return <div />;
  }
  return (
    <Container>
      <LeftColumn>{loggedIn && <ProfileSideNav />}</LeftColumn>
      <Content>
        {!loggedIn ? (
          <Login
            onLoggedIn={() => {
              setUserFetched(false);
              setLoggedIn(true);
              window?.location?.reload();
            }}
          />
        ) : (
          <FadeIn>
            <h2 style={{ marginTop: 15 }}>PROFILE</h2>
            <MobileAccountNav />
            <HeaderColumn style={{ alignItems: 'flex-start' }}>
              <h6>Account Information</h6>
            </HeaderColumn>
            <AddressSection email={values.email} address={address} />

            <div style={{ marginTop: 30 }} />
            <SaveButton
              id={'save-btn'}
              onClick={() =>
                navigate('/addresses', {
                  state: { fromProfile: true, hasAddress },
                })
              }
              className={'desktop'}
            >
              {buttonLabel}
            </SaveButton>
            <div style={{ marginTop: 20 }} />
            <u
              style={{
                fontSize: 12,
                width: '100%',
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/reset-password')}
            >
              Reset Password
            </u>
            <u
              style={{
                fontSize: 12,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                localStorage.removeItem('token');
                localStorage.removeItem('email');
                window.location.reload();
              }}
            >
              Sign out
            </u>
            <div style={{ marginTop: 100 }} />
          </FadeIn>
        )}
      </Content>
      <RightColumn></RightColumn>
    </Container>
  );
};

const SaveButton = styled(Button)`
  margin-top: 30px;
  @media (min-width: 700px) {
    width: 23vw;
  }
  &.mobile {
    width: 100%;
    @media (min-width: 700px) {
      display: none;
    }
  }
  &.desktop {
    @media (max-width: 700px) {
      display: none;
    }
  }
`;

const Container = styled.div`
  display: flex;
  & h2 {
    font-size: 20px;
    @media (min-width: 700px) {
      font-size: 1.6vw;
    }
    // font-size: 5vw;
    font-weight: 700;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
  }

  &.mobile {
    @media (min-width: 700px) {
      display: none;
    }
  }

  & h5 {
    font-weight: 700;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & h6 {
    font-weight: 500;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & .multiple-tfs {
    display: flex;
    justify-content: space-between;
    width: 350px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    @media (min-width: 700px) {
      width: 23vw;
    }
    flex: 1;
  }
  & .multiple-tfs input {
    width: calc((300px / 3) - 20px);
    @media (min-width: 700px) {
      width: calc((22vw / 3) - 20px);
    }
  }
`;

const Content = styled(Column)`
  & > input {
    // text-align: left !important;
    // margin-left: -20px;
  }
  // width: 28vw;
  width: 100%;
  @media (min-width: 700px) {
    width: 22vw;
  }
  font-size: 13px;
  @media (min-width: 700px) {
    font-size: 0.75vw;
  }
`;
const RightColumn = styled(Column)`
  @media (max-width: 700px) {
    display: none;
  }
`;
const LeftColumn = styled(Column)`
  z-index: 1;
  @media (max-width: 700px) {
    display: none;
  }
`;

const HeaderColumn = styled(Column)`
  width: 22vw;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const AddressSection: React.FC<{
  address: Partial<Address>;
  email: any;
}> = ({ email, address }) => {
  const textString = (str: any) => hasTextLength(str) && str;

  return (
    <div>
      <div>{textString(email)}</div>
      <div>{textString(address.name)}</div>
      <div>{textString(address.address1)}</div>
      {hasValue(address.address2) && <div>{textString(address.address2)}</div>}
      <div>
        {hasTextLength(address.city) ? address.city + ',' : ''}
        {textString(address.provinceCode)}
        {textString(address.zip)}
      </div>
      <div>{textString(address.phone)}</div>
    </div>
  );
};

import React from 'react';
import { useNavigate } from 'react-router';
import styled, { CSSProperties } from 'styled-components';
import FadeIn from 'react-fade-in';
import { useOnScreen } from '../hooks/useOnScreen';
import { addToWishList, Column } from '../helper/Common';
import { ProductMinimal } from '../pages/Home';
import DropDown from 'react-dropdown';
// import 'react-dropdown/style.css';

interface CartItemProps {
  product: ProductMinimal;
  setHeight?: boolean;
  style?: CSSProperties;
  showHeader?: boolean;
  showSubtotals?: boolean;
  onRemove: (notify?: boolean) => void;
  size: number;
  quantity: number;
  imageSize?: 'small' | 'normal';
  total?: number;
  shipping?: number;
  orderTotal?: number;
  onUpdateSize?: (size: number) => void;
  onUpdateQuantity?: (quantity: number) => void;
  isPreview?: boolean;
  isLengthOne?: boolean;
}
export const CartItem: React.FC<CartItemProps> = ({
  isPreview = false,
  isLengthOne = false,
  setHeight = false,
  product,
  showHeader,
  showSubtotals,
  total = 0,
  shipping = 0,
  orderTotal = 0,
  style = {},
  onRemove,
  size,
  quantity,
  imageSize = 'normal',
  onUpdateSize,
  onUpdateQuantity,
}) => {
  const nav = useNavigate();

  const IMG_WIDTH = imageSize === 'small' ? 70 : '9.3vw';

  const ref = React.useRef<HTMLDivElement | null>(null);
  const isOnScreen = useOnScreen(ref);

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const selectedCollectionTitle =
    product.collections.nodes.find(
      (n) =>
        n.handle.toLowerCase() !== 'homepage' &&
        n.handle.toLowerCase() !== 'frontpage'
    )?.handle || 'SHOP';

  return (
    <Container ref={ref} style={style}>
      {showHeader && (
        <div style={{ display: 'flex', width: '100%' }}>
          <StyledColumn imageSize={imageSize} className={'first-col'}>
            <StyledColumn imageSize={imageSize} imgWidth={IMG_WIDTH} />
          </StyledColumn>
          <StyledColumn
            imageSize={imageSize}
            style={{
              flex: 3,
              ...(imageSize === 'small' ? { fontSize: 12 } : {}),
            }}
          >
            Item
          </StyledColumn>
          <StyledColumn
            imageSize={imageSize}
            style={{
              ...(imageSize === 'small' ? { fontSize: 12 } : {}),
              alignItems: 'center',
            }}
          >
            Price
          </StyledColumn>
          <StyledColumn
            imageSize={imageSize}
            className={'qty'}
            style={{ ...(imageSize === 'small' ? { fontSize: 12 } : {}) }}
          >
            Qty
          </StyledColumn>
        </div>
      )}
      <Divider />
      <div
        className={'item-container'}
        style={
          setHeight
            ? { height: 0, display: 'flex', width: '100%' }
            : { display: 'flex', width: '100%' }
        }
      >
        <StyledColumn className={'col'} imageSize={imageSize}>
          <Image
            imageSize={imageSize}
            imgWidth={IMG_WIDTH}
            src={product.featuredImage.url}
            onClick={() =>
              nav(
                `/product/${selectedCollectionTitle}/${product.id}/${product.title}`
              )
            } //'/product/6934995828873/Pilot Parka'}
          />
        </StyledColumn>
        <StyledColumn
          imageSize={imageSize}
          style={{
            flex: 3,
            display: 'flex',
            minHeight: imageSize !== 'small' ? '13vw' : 0,
            paddingRight: 10,
          }}
          className={'col'}
        >
          <p
            style={{
              textTransform: 'uppercase',
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            {product.title}
          </p>
          {/* <p>SIZE {size}</p> */}
          <div
            className='size'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <p>SIZE</p>
            <div style={{ marginLeft: 8 }} />
            {isPreview ? (
              <p>{String(size)}</p>
            ) : (
              <StyledDropDown
                disabled={imageSize === 'small'}
                arrowOpen={
                  imageSize === 'small' ? (
                    <div />
                  ) : (
                    <div
                      className='img up'
                      style={{
                        cursor: 'pointer',
                        mask: `url("${
                          require('../images/arrow-down.svg').default
                        }") center/contain no-repeat`,
                        WebkitMask: `url("${
                          require('../images/arrow-down.svg').default
                        }") center/contain no-repeat`,
                      }}
                    />
                  )
                }
                arrowClosed={
                  // <img src={require('../images/arrow-down.svg').default} />
                  imageSize === 'small' ? (
                    <div />
                  ) : (
                    <div
                      className='img'
                      style={{
                        cursor: 'pointer',
                        mask: `url("${
                          require('../images/arrow-down.svg').default
                        }") center/contain no-repeat`,
                        WebkitMask: `url("${
                          require('../images/arrow-down.svg').default
                        }") center/contain no-repeat`,
                      }}
                    />
                  )
                }
                // style={{ /*width: 70, */ width: '2.7vw', marginRight: 20 }}
                value={String(size)}
                onChange={(val) => {
                  if (onUpdateSize) {
                    onUpdateSize(+val.value);
                  }
                }}
                options={[
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                  { label: '4', value: '4' },
                  { label: '5', value: '5' },
                  { label: '6', value: '6' },
                  { label: '7', value: '7' },
                ]}
              />
            )}
          </div>
          <div style={{ flex: 1 }} />
          {imageSize !== 'small' && (
            <a
              style={{ whiteSpace: 'nowrap' }}
              href={'#'}
              onClick={(e) => {
                e.preventDefault();
                if (!localStorage.getItem('token')) {
                  nav('/account');
                  return;
                }
                addToWishList(product, size);
                onRemove(false);
              }}
            >
              MOVE TO WISHLIST
            </a>
          )}
        </StyledColumn>
        <StyledColumn
          className={'col'}
          imageSize={imageSize}
          style={{ alignItems: 'center' }}
        >
          <p>${product.variants[size - 1].price}</p>
        </StyledColumn>
        <StyledColumn className={'col qty'} imageSize={imageSize}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {imageSize !== 'small' && (
              <div
                className={'ticker minus'}
                style={{ fontSize: 18 }}
                onClick={() =>
                  quantity > 1 &&
                  onUpdateQuantity &&
                  onUpdateQuantity(quantity - 1)
                }
              >
                -
              </div>
            )}
            <p style={{ paddingLeft: 10, paddingRight: 10 }}>{quantity}</p>
            {imageSize !== 'small' && (
              <div
                className={'ticker plus'}
                style={{ fontSize: 18 }}
                onClick={() =>
                  onUpdateQuantity && onUpdateQuantity(quantity + 1)
                }
              >
                +
              </div>
            )}
          </div>

          <div style={{ marginTop: 10 }} />
          {/* <StyledDropDown
              disabled={imageSize === 'small'}
              arrowOpen={
                imageSize === 'small' ? (
                  <div />
                ) : (
                  <div
                    className="img up"
                    style={{
                      cursor: 'pointer',
                      mask: `url("${
                        require('../images/arrow-down.svg').default
                      }") center/contain no-repeat`,
                      WebkitMask: `url("${
                        require('../images/arrow-down.svg').default
                      }") center/contain no-repeat`,
                    }}
                  />
                )
              }
              arrowClosed={

                imageSize === 'small' ? (
                  <div />
                ) : (
                  <div
                    className="img"
                    style={{
                      cursor: 'pointer',
                      mask: `url("${
                        require('../images/arrow-down.svg').default
                      }") center/contain no-repeat`,
                      WebkitMask: `url("${
                        require('../images/arrow-down.svg').default
                      }") center/contain no-repeat`,
                    }}
                  />
                )
              }

              value={String(quantity)}
              onChange={(val) => {
                if (onUpdateQuantity) {
                  onUpdateQuantity(+val.value);
                }
              }}
              options={[
                { label: '1', value: '1' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
                { label: '5', value: '5' },
                { label: '6', value: '6' },
                { label: '7', value: '7' },
                { label: '8', value: '8' },
                { label: '9', value: '9' },
                { label: '10', value: '10' },
                { label: '11', value: '11' },
                { label: '12', value: '12' },
                { label: '13', value: '13' },
                { label: '14', value: '14' },
                { label: '15', value: '15' },
                { label: '16', value: '16' },
                { label: '17', value: '17' },
                { label: '18', value: '18' },
                { label: '19', value: '19' },
                { label: '20', value: '20' },
              ]}
            /> */}
          <div style={{ flex: 1 }} />
          {imageSize !== 'small' && (
            <a href={'#'} onClick={() => onRemove()}>
              REMOVE
            </a>
          )}
        </StyledColumn>
      </div>
      {showSubtotals && (
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <Divider />
          <div
            style={{
              display: 'flex',
              width: '100%',
              ...(isPreview && { marginTop: 12 }),
            }}
          >
            <StyledColumn imageSize={imageSize} className={'first-col'}>
              <StyledColumn imageSize={imageSize} imgWidth={IMG_WIDTH} />
            </StyledColumn>
            <StyledColumn
              imageSize={imageSize}
              style={{
                flex: 3,
                ...(imageSize === 'small' ? { fontSize: 12 } : {}),
                // justifyContent: 'center',
                // alignItems: 'center',
              }}
            >
              Price
            </StyledColumn>
            <StyledColumn
              imageSize={imageSize}
              style={{ ...(imageSize === 'small' ? { fontSize: 12 } : {}) }}
            ></StyledColumn>
            <StyledColumn
              imageSize={imageSize}
              className={'qty'}
              style={{ ...(imageSize === 'small' ? { fontSize: 12 } : {}) }}
            >
              {formatter.format(total)}
            </StyledColumn>
          </div>
          <div style={{ marginTop: 10 }} />
          {/* <div style={{ display: 'flex', width: '100%' }}>
              <StyledColumn className={'first-col'}>
                <StyledColumn imgWidth={IMG_WIDTH} />
              </StyledColumn>
              <StyledColumn
                style={{
                  flex: 3,
                  ...(imageSize === 'small' ? { fontSize: 12 } : {}),
                }}
              >
                Estimated Shipping
              </StyledColumn>
              <StyledColumn
                style={{ ...(imageSize === 'small' ? { fontSize: 12 } : {}) }}
              ></StyledColumn>
              <StyledColumn
                className={'qty'}
                style={{ ...(imageSize === 'small' ? { fontSize: 12 } : {}) }}
              >
                {formatter.format(shipping)}
              </StyledColumn>
            </div> */}
          {/* <div style={{ marginTop: 10 }} />
            <div style={{ display: 'flex', width: '100%' }}>
              <StyledColumn className={'first-col'}>
                <StyledColumn imgWidth={IMG_WIDTH} />
              </StyledColumn>

              <StyledColumn
                style={{
                  flex: 3,
                  ...(imageSize === 'small' ? { fontSize: 12 } : {}),
                }}
              >
                <strong>Order Total</strong>
              </StyledColumn>
              <StyledColumn
                style={{ ...(imageSize === 'small' ? { fontSize: 12 } : {}) }}
              ></StyledColumn>

              <StyledColumn
                className={'qty'}
                style={{ ...(imageSize === 'small' ? { fontSize: 12 } : {}) }}
              >
                <strong>{formatter.format(shipping + total)}</strong>
              </StyledColumn>
            </div> */}
        </div>
      )}
    </Container>
  );
};

const StyledDropDown = styled(DropDown)`
  cursor: pointer;
  & .Dropdown-control {
    display: flex;
  }
  & .Dropdown-control:hover {
    color: white;
  }
  & .Dropdown-placeholder {
    @media (min-width: 700px) {
      font-size: 0.7vw;
    }
  }
  & .Dropdown-menu::-webkit-scrollbar {
    display: none;
  }

  & .Dropdown-control:hover .img {
    background: white;
  }
  & .Dropdown-menu {
    position: absolute;
    // max-height: ;
    // @media (max-width: 700px) {
    width: 50px;
    padding: 0px;
    margin-left: -10px;
    background: white;
    border-radius: 5px;
    z-index: 1;
    box-shadow: -2.66667px -2.66667px 8px #ffffff,
      4px 4px 8px rgba(174, 174, 192, 0.4),
      inset 0px 0px 0px 0.533333px rgba(255, 255, 255, 0.2);
    // }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    max-height: 200px;
    overflow: scroll;
  }
  & img {
    margin-left: 8px;
  }
  & .Dropdown-option {
    text-align: center;
  }
  & .Dropdown-option:hover {
    // text-align: center;
    // background: white;
    // width: 2vw;
    color: white;
    background: var(--primary);
  }
`;

const FadeContainer = styled(FadeIn)`
  width: 100%;

  & .ticker {
    font-size: 1vw;
    cursor: pointer;
  }
  & .ticker:hover {
    color: white;
  }
  & .ticker.minus {
    margin-right: 5px;
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
  & .ticker.plus {
    margin-left: 5px;
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
`;

const Divider = styled.div`
  margin-bottom: 10px;
  width: 100%;
  height: 3px;
  background: var(--light-blue);
  margin-top: 10px;
`;

const StyledColumn = styled(Column)<{
  imgWidth?: string | number;
  imageSize: 'small' | 'normal';
}>`
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0px;
  ${({ imgWidth }) =>
    imgWidth
      ? `
  width: ${typeof imgWidth === 'number' ? `${imgWidth}px` : imgWidth};
  @media(max-width: 700px){
    width: 80px;
    height: 80px;
  }


  
  `
      : ``}
  & .size {
    @media (min-width: 700px) {
      font-size: 0.7vw;
    }
    @media (max-width: 700px) {
      margin-bottom: 30px;
    }
  }

  &.first-col {
    padding-left: 20px;
    padding-right: ${({ imageSize }) =>
      imageSize === 'small' ? '18px' : '50px'};
    @media (max-width: 700px) {
      padding-right: 0px;
      padding-left: 0px;
      margin-right: -5px;
    }
  }

  &.qty {
    margin-left: 5px;
    @media (max-width: 700px) {
      align-items: center;
    }
  }

  & a,
  p {
    font-size: 12px;
    @media (min-width: 700px) {
      font-size: 0.7vw;
    }
  }

  @media (max-width: 700px) {
    & a,
    p {
      font-size: 12px;
    }
  }

  & .img {
    height: 15px;
    width: 15px;
    @media (min-width: 700px) {
      height: 0.9vw;
      width: 0.9vw;
    }
    background: black;
    margin-top: 1px;
    margin-left: 8px;
  }
  & .up {
    transform: scaleY(-1);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;

  & p {
    font-weight: 400;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & a {
    color: black;
  }

  & h5 {
    font-weight: 700;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

const Image = styled.img<{
  imgWidth?: number | string;
  imageSize: 'small' | 'normal';
}>`
  height: auto;
  object-fit: contain;
  padding-left: 20px;
  padding-right: 50px;
  padding-right: ${({ imageSize }) =>
    imageSize === 'small' ? '18px' : '50px'};
  width: ${({ imgWidth }) =>
    typeof imgWidth === 'number' ? `${imgWidth}px` : imgWidth};

  @media (max-width: 700px) {
    width: 80px;
    width: 50px;
    padding-left: 0px;
    padding-right: 10px;
  }
`;

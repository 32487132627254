import { includes, uniqBy } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { notify } from '../components/ProductItem';
import { ProfileSideNav } from '../components/profile/ProfileSideNav';
import { WishListItem } from '../components/WishListItem';
import { Column, SideNav } from '../helper/Common';
import { MobileAccountNav } from '../components/MobileAccountNav';
import { makeRequest } from '../hooks/Resource';
import { ProductMinimal, ShopifyProductsResponse } from './Home';

export interface WishlistINT {
  metafields: {
    values: [
      {
        id: number;
        size: number;
        variant: number;
      }
    ];
  };
}

export const Wishlist: React.FC = () => {
  const navigate = useNavigate();

  const [products, setProducts] = React.useState<ProductMinimal[]>([]);
  const [wishlistProducts, setWishlistProducts] = React.useState<
    { id: number; size: number; variant: number }[]
  >([]);
  let isMobile = /iphone|ipod|android|ie|blackberry|fennec/.test(
    navigator.userAgent.toLowerCase()
  );
  const getWishlistProducts = () => {
    const wishlistString = localStorage.getItem('wishlist');
    let wishlist: { id: number; size: number; variant: number }[] = [];
    if (wishlistString) {
      wishlist = JSON.parse(wishlistString);
    }
    // wishlist = [...wishlist.filter((c) => c !== productId), productId];

    return wishlist;
  };

  const onUpdateSize = (productId: number, old: number, newSize: number) => {
    const foundIdx = products.findIndex(
      (prod) => prod.id === productId && prod.size === old
    );
    if (foundIdx !== -1) {
      const newVal = [
        ...products.slice(0, foundIdx),
        { ...products[foundIdx], size: newSize },
        ...products.slice(foundIdx + 1),
      ];
      setProducts(newVal);
      localStorage.setItem('wishlist', JSON.stringify(newVal));
    }
  };

  const removeFromWishlist = async (
    product: ProductMinimal,
    title: string,
    hideNotify?: boolean
  ) => {
    let wishlist: { id: number; size: number; variant: number }[] = [];

    wishlist = wishlistProducts.filter(
      (c) => !(c.id === product.id && c.size === product.size)
    );
    console.log('removing', { wishlist });
    const resp = await makeRequest<{
      user?: {
        body?: { data?: { customer?: { metafield?: { value: '' } } } };
      };
    }>(`/api/shopify/user/wishlistEdit`, 'POST', {
      customerId: localStorage.getItem('customer_id'),
      wishlist_items: wishlist,
    });
    setProducts(
      products.filter((p) =>
        wishlist
          .map((c) => c.id === p.id && c.size === p.size && c.id)
          .includes(p.id)
      )
    );
    setWishlistProducts(wishlist);
    !hideNotify && notify(`Removed ${title} from wishlist`);
  };

  const getSecondaryImage = (images: any[], featuredImage: string) => {
    const filteredImgArr = images.filter(
      (img) => img.altText && ( img.altText.includes('#wishlist') || img.altText.includes('#secondary') )
    );

    if (filteredImgArr && filteredImgArr.length > 1) {
      if( window.innerWidth > 572 ){
        return filteredImgArr[0].url;
      }else{
        return filteredImgArr[1].url;
      }
    }
    return featuredImage;
  };
  const getShopifyProducts = async () => {

    const resp = await makeRequest<{
      products?: { body?: { data?: { products: ShopifyProductsResponse } } };
    }>(`/api/shopify/products`);

    if (resp?.products?.body?.data?.products?.edges?.length) {
      const productResp: ProductMinimal[] =
        resp.products.body.data.products.edges.flatMap((r) => {
          const ids = r.node.id?.split('/');
          return {
            id: +((ids?.length && ids[ids.length - 1]) || 0),
            tags: r.node.tags,
            collections: r.node.collections,
            productType: r.node.productType,
            title: r.node.title,
            featuredImage: {
              url: getSecondaryImage(
                r.node.images.nodes,
                r.node.featuredImage.url
              ),
            },
            variants: r.node.variants.nodes.map((v) => {
              const variantsPathArr = `${v.id}`.split('/');
              return {
                id: +(
                  (variantsPathArr?.length &&
                    variantsPathArr[variantsPathArr.length - 1]) ||
                  0
                ),
                price: +(v.price || 0),
              };
            }),
          };
        });

      const wishlist = wishlistProducts;

      const products = productResp.filter((p) =>
        wishlist.map((c) => c.id).includes(p.id)
      );
      setProducts(
        products.flatMap((p) => {
          const c = wishlist.filter((ca) => ca.id === p.id);
          console.log({ c });
          return uniqBy(
            c.map((c2) => ({ ...p, size: c2?.size || 1 })),
            (c2) => c2.size
          );
        })
      );
    }
  };
  const getShopifyWishlist = async () => {
    const accessToken = localStorage.getItem('token');
    const resp = await makeRequest<{
      user?: {
        body?: { data?: { customer?: { metafield?: { value: '' } } } };
      };
    }>(`/api/shopify/user/${accessToken}/wishlist`);

    let wishlistString =
      (resp.user?.body?.data?.customer?.metafield &&
        resp.user?.body?.data?.customer?.metafield?.value) ??
      '';

    let wishlist: { id: number; size: number; variant: number }[] = [];
    if (wishlistString) {
      wishlist = JSON.parse(wishlistString);
    }

    setWishlistProducts(wishlist);
  };

  React.useEffect(() => {
    getShopifyWishlist();
  }, []);

  React.useEffect(() => {
    if (wishlistProducts && wishlistProducts.length > 0) {
      getShopifyProducts();
    }
  }, [wishlistProducts]);

  return (
    <Container style={{ width: '100%' }}>
      <h2 style={{ marginTop: 15 }}>WISHLIST</h2>
      <MobileAccountNav />
      <div style={{ display: 'flex' }}>
        <LeftColumn style={{ height: '100%', width: '100%', flex: 0 }}>
          <ProfileSideNav />
        </LeftColumn>
        <Column
          style={{ height: '100%', flex: 1 }}
          key={`products-${products.length}`}
        >
          <div
            style={
              products.length > 0
                ? {
                    display: 'grid',
                    gridTemplateColumns: [...(isMobile ? [1] : [1, 1, 1])]
                      .map(() => '1fr')
                      .join(' '),
                    position: 'relative',
                    gap: 70,
                  }
                : { position: 'relative' }
            }
          >
            {products.map((pro) => {
              return (
                <WishListItem
                  product={pro}
                  size={pro.size || 1}
                  imgWidth={'100%'}
                  imgHeight={/*400*/ '26vw'}
                  style={{ width: /*300*/ '20vw' }}
                  onRemove={(hideNotify) => {
                    removeFromWishlist(pro, pro.title, hideNotify);
                  }}
                  onUpdateSize={(size) =>
                    onUpdateSize(pro.id, pro.size || 1, size)
                  }
                />
              );
            })}
            {products.length === 0 ? (
              <div style={{ width: '100%' }}>No products in wishlist</div>
            ) : (
              <div />
            )}
          </div>
        </Column>
      </div>
    </Container>
  );
};

const Container = styled(Column)`
  & h2 {
    font-size: 20px;
    @media (min-width: 700px) {
      font-size: 1.6vw;
    }
    font-weight: 700;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & h5 {
    font-weight: 700;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & h6 {
    font-weight: 500;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & .multiple-tfs {
    display: flex;
    justify-content: space-between;
    width: 400px;
    @media (min-width: 700px) {
      width: 27vw;
    }
    flex: 1;
  }
  & .multiple-tfs input {
    width: calc((300px / 3) - 20px);
  }
`;

const RightColumn = styled(Column)`
  @media (max-width: 700px) {
    display: none;
  }
`;
const LeftColumn = styled(Column)`
  @media (max-width: 700px) {
    display: none;
  }
`;

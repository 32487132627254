import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import FadeIn from 'react-fade-in';
import { activeBtnState, Button } from '../utils/Constants';
import { Modal } from '../components/Modal';
import { Textfield } from '../components/Textfield';
import { SizeChartSection } from '../components/SizeChartSection';
import { makeRequest } from '../hooks/Resource';
import { ShopifyProduct } from './Home';
import { addToCart, addToWishList } from '../helper/Common';
import { compact } from 'lodash';
import { ImageFullScreen } from '../components/ImageFullScreen';
import { useNavigate } from 'react-router-dom';

export interface ProductVariant {
  id: number;
  price: number;
  currentlyNotInStock?: boolean;
  quantityAvailable?: any;
}
export interface Product {
  id: number;
  title: string;
  images: string[];
  description: string;
  price: number;
  size?: number;
  tags: string[];
  productType: string;
  variants: ProductVariant[];
  featuredImage: { url: string };
  videos?: string[];
  modelSizeDescription?: string;
  metafields: {
    namespace: string;
    key: string;
    type: string;
    value: string;
    reference?: { image?: { originalSrc?: string } };
  }[];
  collections: {
    nodes: {
      handle: string;
      title: string;
      id: string;
    }[];
  };
}
export const products: Product[] = [
  {
    id: 1,
    title: 'Ocean ghost t-shirt',
    featuredImage: { url: require('../images/shirt-1.png') },
    tags: [],
    productType: '',
    images: [
      require('../images/shirt-1.png'),
      require('../images/shirt-1.png'),
      require('../images/shirt-1.png'),
      require('../images/shirt-1.png'),
      require('../images/shirt-1.png'),
      require('../images/shirt-1.png'),
      require('../images/shirt-1.png'),
    ],
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
    price: 100,
    variants: [{ id: 0, price: 0 }],
    collections: { nodes: [] },
    metafields: [],
  },
  {
    id: 2,
    title: 'LUNAR FLEX SHELL',
    tags: [],
    productType: '',
    featuredImage: { url: require('../images/shirt-2.png') },
    images: [
      require('../images/shirt-2.png'),
      require('../images/shirt-2.png'),
      require('../images/shirt-2.png'),
      require('../images/shirt-2.png'),
      require('../images/shirt-2.png'),
      require('../images/shirt-2.png'),
      require('../images/shirt-2.png'),
    ],
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
    price: 100,
    variants: [{ id: 0, price: 0 }],
    collections: { nodes: [] },
    metafields: [],
  },
];

export const ProductDetail: React.FC<{ onCartChange: () => void }> = ({
  onCartChange,
}) => {
  const [imageIdx, setImageIdx] = React.useState<number>(0);
  const [sizeIdx, setSizeIdx] = React.useState<number | null>(null);
  const [imageFullScreenVisible, setImageFullScreenVisible] =
    React.useState<boolean>(false);
  const [descriptionVisible, setDescriptionVisible] =
    React.useState<boolean>(false);
  const [modelDescVisible, setModelDescVisible] =
    React.useState<boolean>(false);
  const [product, setProduct] = React.useState<Product | null>(null);
  const [size, setSize] = React.useState<number | null>(null);
  const videoRef = React.useRef<HTMLVideoElement | null>(null);

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const nav = useNavigate();

  const { productId } = useParams<{ productId: string }>();

  // const product = products.find((p) => p.id === +productId);
  const getShopifyProduct = async () => {
    const resp = await makeRequest<{
      product?: {
        body?: {
          data?: { product: ShopifyProduct };
        };
      };
    }>(`/api/shopify/product/${productId}`);

    if (resp?.product?.body?.data?.product) {
      // const videos =
      //   resp.product.body.data.product.media?.edges
      //     .filter((e) => e.node.mediaContentType === 'VIDEO')
      //     ?.flatMap((e) =>
      //       compact([e.node.sources?.find((s) => s.format === 'mp4')?.url])
      //     ) || [];
      console.log(
        'resp.product.body.data.product.media?.edges',
        resp.product.body.data.product.media?.edges
      );

      let videos =
        resp.product.body.data.product.media?.edges
          // .filter((e) => e.node.mediaContentType === 'VIDEO')
          ?.flatMap((e) =>
            compact([
              e.node.sources?.find(
                (s) => e.node.mediaContentType === 'VIDEO' && s.format === 'mp4'
              )?.url || e.node.image?.url,
            ])
          ) || [];

      let images =
        resp.product.body.data.product.images.edges.map((im) => {
          if (
            im.node &&
            im.node.altText &&
            im.node.altText.includes('#secondary')
          ) {
            return im.node.url;
          }

          return '';
        }) || [];
      images = (images && images.length > 0 && images.filter((x) => x)) || [];

      if (images && images.length > 0) {
        videos = videos.filter((vdo) => vdo !== images[0]);
      }
      const productResp: Product = {
        id: +(productId || 0),
        title: resp.product.body.data.product.title,
        tags: resp.product.body.data.product.tags,
        collections: resp.product.body.data.product.collections,
        productType: resp.product.body.data.product.productType,
        metafields: resp.product.body.data.product.metafields,
        featuredImage: {
          url: resp.product.body.data.product.images.edges[0].node.url,
        },
        images: resp.product.body.data.product.images.edges.map(
          (im) => im.node.url
        ),
        videos,
        modelSizeDescription:
          resp.product.body.data.product.metafield?.value ?? '',
        description: resp.product.body.data.product.description,
        price: 0,
        variants: resp.product.body.data.product.variants.nodes.map((v) => {
          const variantsPathArr = `${v.id}`.split('/');
          return {
            ...v,
            id: +(
              (variantsPathArr?.length &&
                variantsPathArr[variantsPathArr.length - 1]) ||
              0
            ),
            price: +(v.price || 0),
          };
        }),
      };

      setProduct(productResp);
    }
  };

  React.useEffect(() => {
    if (imageIdx === 2) {
      videoRef.current?.play();
    }
  }, [imageIdx]);

  React.useEffect(() => {
    const el = document?.querySelector('#root') as HTMLElement;
    const firstChild = el?.firstChild as HTMLElement;
    if (
      el &&
      firstChild &&
      document.body.clientWidth < 1450 &&
      document.body.clientWidth > 1000
    ) {
      el.style.height = '100%';
      el.style.position = 'relative';
      firstChild.style.height = '100%';
    }

    getShopifyProduct();
  }, []);

  const getProductStockStatus = (idx: number) => {
    if (
      !product?.variants[idx]?.currentlyNotInStock &&
      product?.variants[idx]?.quantityAvailable < 1
    ) {
      return true;
    }

    return false;
  };
  if (!productId) return <div />;

  if (!product) return <div />;

  return (
    <Container>
      <FadeIn delay={200}>
        <Content>
          <Description>
            <div
              className={'container'}
              style={{ position: 'relative', maxWidth: 310 }}
            >
              <div
                className={'inner-wrapper'}
                style={
                  {
                    /*minWidth: 250*/
                  }
                }
              >
                <u
                  style={{ margin: 0 }}
                  onClick={() => setModelDescVisible((v) => !v)}
                >
                  Model Size Description
                </u>

                <p
                  className="description"
                  style={{
                    opacity: modelDescVisible ? 1 : 0,
                    transition: '0.1s ease-in-out',
                    textAlign: 'center',
                    height: modelDescVisible ? 'auto' : 0,
                    marginTop: modelDescVisible ? 10 : 0,
                    marginBottom: 0,
                    zIndex: modelDescVisible ? 0 : -1,
                  }}
                >
                  {product.modelSizeDescription?.split('\n').map((d) => (
                    <p>{d}</p>
                  ))}
                </p>

                <div style={{ marginTop: 10 }} />
                <p style={{ display: 'inline-block', margin: 0 }}>
                  Are you unsure of your size?{' '}
                  <u
                    style={{ color: 'black' }}
                    onClick={() => setModalVisible(true)}
                  >
                    Size Chart
                  </u>
                </p>
                <div style={{ marginTop: 10 }} />
                <p style={{ margin: 0 }}>
                  <u onClick={() => setDescriptionVisible((v) => !v)}>
                    Description + Materials
                  </u>
                </p>
                <p
                  className={'description'}
                  style={{
                    opacity: descriptionVisible ? 1 : 0,
                    transition: '0.1s ease-in-out',
                    textAlign: 'center',
                    zIndex: descriptionVisible ? 0 : -1,
                    height: descriptionVisible ? 'auto' : 10,
                  }}
                >
                  {product.description}
                </p>
              </div>
            </div>
          </Description>
          <CarouselContainer>
            <Carousel
              infiniteLoop
              autoPlay={false}
              dynamicHeight={false}
              showIndicators={false}
              showArrows={true}
              showThumbs={false}
              showStatus={false}
              selectedItem={imageIdx}
              centerMode
              onChange={setImageIdx}
              centerSlidePercentage={100}
              renderArrowPrev={(goPrev) => (
                <div className={'left-arrow-wrapper'}>
                  <div
                    className="img left"
                    onClick={goPrev}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: '#666666',
                      mask: `url('${
                        require('../images/arrow-down.svg').default
                      }') center/contain no-repeat`,
                      WebkitMask: `url('${
                        require('../images/arrow-down.svg').default
                      }') center/contain no-repeat`,
                    }}
                  />
                </div>
              )}
              renderArrowNext={(goNext) => (
                <div className={'right-arrow-wrapper'}>
                  <div
                    className="img right"
                    onClick={goNext}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: '#666666',
                      mask: `url('${
                        require('../images/arrow-down.svg').default
                      }') center/contain no-repeat`,
                      WebkitMask: `url('${
                        require('../images/arrow-down.svg').default
                      }') center/contain no-repeat`,
                    }}
                  />
                </div>
              )}
            >
              {(product.videos
                ? [
                    // ...product.images.slice(0, 2),
                    // product.videos[0],
                    // ...product.images.slice(2, 5),
                    // product.videos[1],
                    // ...product.images.slice(5, 6),
                    // product.videos[2],
                    // ...product.images.slice(6),
                    ...product.videos,
                  ]
                : product.images
              ).map((image, idx) => (
                <ImageContainer onClick={() => setImageFullScreenVisible(true)}>
                  {image.includes('mp4') ? (
                    // <video
                    //   ref={videoRef}
                    //   autoPlay={true}
                    //   playsInline={true}
                    //   muted
                    // >
                    //   <source src={image} type='video/mp4'></source>
                    // </video>
                    // <picture>
                    //   <source srcSet={image} type='video/mp4' />
                    /* <img src={image} alt='An image of an explosion.' /> */
                    <video
                      ref={videoRef}
                      autoPlay={true}
                      playsInline={true}
                      muted
                      loop
                    >
                      <source src={image} type="video/mp4"></source>
                    </video>
                  ) : (
                    //  </picture>
                    //  <video
                    //   ref={videoRef}
                    //   autoPlay={true}
                    //   playsInline={true}
                    //   muted
                    // >
                    //   <source src={image} type='video/mp4'></source>
                    // </video>
                    <img
                      alt={''}
                      src={image}
                      style={{
                        borderRadius: 16,
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  )}
                </ImageContainer>
              ))}
            </Carousel>
            <IndicatorContainer key={imageIdx}>
              {[...compact(product.videos)].map((i, idx) => (
                <Indicator
                  style={
                    imageIdx !== idx ? { background: '#DDE4E9', flex: 1 } : {}
                  }
                  onClick={() => {
                    setImageIdx(idx);
                    if (idx === 2) {
                      videoRef.current?.play();
                    }
                  }}
                />
              ))}
            </IndicatorContainer>
          </CarouselContainer>
          <Actions>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                className={'lt-wave'}
                style={{
                  fontWeight: 700,
                  textTransform: 'uppercase',
                }}
              >
                {product.title.toUpperCase()}
              </p>
              <p style={{ marginTop: 12, fontWeight: 400 }}>
                ${product.variants[size ? size - 1 : 0].price}
              </p>
              <div
                style={{
                  display: 'flex',
                  marginTop: 30,
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                {[...Array(7)].map((_, idx) => (
                  <SizeBtn
                    style={{
                      width: '2.55vw',
                      height: '2.55vw',
                      minWidth: 30,
                      minHeight: 30,
                      opacity: getProductStockStatus(idx) ? 0.8 : 1,
                      cursor: getProductStockStatus(idx)
                        ? 'not-allowed'
                        : 'pointer',
                      color: getProductStockStatus(idx) ? 'gray' : 'undefined',
                    }}
                    isDisabled={getProductStockStatus(idx)}
                    onClick={(e) => {
                      e.preventDefault();
                      console.log('test');

                      if (
                        product.variants[idx].currentlyNotInStock ||
                        product.variants[idx].quantityAvailable < 1
                      ) {
                        setSizeIdx(null);
                        setSize(null);
                        return;
                      }
                      setSizeIdx(idx);
                      setSize(idx + 1);
                    }}
                    active={sizeIdx === idx}
                  >
                    {idx + 1}
                  </SizeBtn>
                ))}
              </div>
              <div
                style={{
                  display: 'flex',
                  marginTop: 15,
                  width: '100%',
                }}
              >
                <AddToCartButton
                  style={{
                    /*width: 'calc(327px - 50px)'*/ width: `calc(19.7vw - 2.5vw)`,
                    minWidth: 215,
                    flex: 1,
                  }}
                  onClick={() => {
                    addToCart(product, size);
                    onCartChange();
                  }}
                >
                  ADD TO CART
                </AddToCartButton>
                <Button
                  style={{ width: '3.5vw', marginLeft: 15, minWidth: 41 }}
                  onClick={() => {
                    if (!localStorage.getItem('token')) {
                      nav('/account');
                      return;
                    }
                    addToWishList(product, size);
                  }}
                  className={'save-btn'}
                >
                  {/* <img src={require('../images/save-icon.svg').default} /> */}
                  <div
                    className={'img'}
                    style={{
                      mask: `url('${
                        require('../images/save-icon.svg').default
                      }') center/contain no-repeat`,
                      WebkitMask: `url('${
                        require('../images/save-icon.svg').default
                      }') center/contain no-repeat`,
                    }}
                  />
                </Button>
              </div>
            </div>
          </Actions>
        </Content>
        <Modal
          size={'large'}
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          style={{ overflowX: 'hidden' }}
        >
          <SizeChartSection product={product} visible={modalVisible} />
        </Modal>
      </FadeIn>
      <ImageFullScreen
        media={product.videos || []}
        selectedIdx={imageIdx}
        visible={imageFullScreenVisible}
        onClose={() => setImageFullScreenVisible(false)}
      />
    </Container>
  );
};

const AddToCartButton = styled(Button)`
  width: 100%;
  @media (min-width: 700px) {
    width: calc(19.7vw - 2.5vw);
  }
  @media (min-width: 1200) {
    font-size: 0.9vw;
  }
`;

const Container = styled.div`
  min-height: 100%;
  display: flex;
  @media (max-width: 1450px) {
    justify-content: center;
    margin-top: -150px;
  }
  @media (max-width: 1250px) {
    justify-content: flex-start;
    margin-top: 0px;
  }
  flex-direction: column;
  @media (min-width: 700px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  & u {
    cursor: pointer;
  }

  & .carousel .slider-wrapper {
    @media (max-width: 700px) {
      width: 70%;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  & .left-arrow-wrapper {
    opacity: 0;
    width: 100px;
    z-index: 1;
    display: flex;
    @media (min-width: 1480px) {
      margin-right: -100px;
    }
    @media (max-width: 700px) {
      display: none;
      position: absolute;
      left: 0;
      opacity: 1;
    }
  }
  & .right-arrow-wrapper {
    opacity: 0;
    width: 100px;
    display: flex;
    z-index: 1;
    @media (min-width: 1480px) {
      margin-left: -100px;
    }
    @media (max-width: 700px) {
      position: absolute;
      display: none;
      right: 0;
      opacity: 1;
    }
  }
  & .left-arrow-wrapper:hover {
    opacity: 1;
    color: red;
  }
  & .right-arrow-wrapper:hover {
    opacity: 1;
    color: red;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }

  & .carousel.carousel-slider {
    display: flex;
    @media (max-width: 700px) {
      align-items: center;
    }
  }
`;

const Description = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  // justify-content: flex-end;

  & p.description {
    font-size: 0.6vw;
    @media (max-width: 1100px) {
      font-size: 10px;
    }
  }

  order: 0;
  & .container {
    margin-top: 150px;
    margin-top: 10vw;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    text-align: center;
    font-size: 13px;
    font-size: 0.75vw;
    @media (max-width: 1000px) {
      font-size: 13px;
    }
  }

  & .inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & p {
    position: relative;
  }

  @media (max-width: 1000px) {
    order: 2;
    margin-top: 50px;

    & .container {
      margin-top: 20px;
      margin-top: 0px;
      padding-top: 0px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  justify-content: center;
  align-items: center;
  order: 2;
  align-items: center;
  margin-top: 200px;
  margin-top: 12vw;

  & p {
    margin: 0px;
    font-size: 1vw;
    @media (max-width: 1000px) {
      font-size: 16px;
    }
  }
  & p.lt-wave {
    font-size: 1.3vw;
    @media (max-width: 1000px) {
      font-size: 16px;
    }
  }

  @media (max-width: 1000px) {
    order: 1;
    margin-top: 50px;
  }

  & .img {
    height: 20px;
    width: 20px;
    @media (min-width: 700px) {
      height: 1.2vw;
      width: 1.2vw;
    }
    object-fit: contain;
    background: black;
  }
  & .save-btn:hover .img {
    background: white;
  }
`;

const CarouselContainer = styled.div`
  flex: 4;
  order: 1;
  max-width: 100%;

  & .carousel-root {
    width: 600px;
    width: 38vw;
    min-width: 318px;
    margin: auto;
    @media (max-width: 1440px) {
      // height: 430px;
    }
    @media (max-width: 1140px) {
      // height: 360px;
      // height: 23vw;
    }
  }
  @media (max-width: 1480px) {
    & .carousel-root {
      // width: 500px;
      // width: 28vw;
    }
  }
  @media (max-width: 1000px) {
    & .carousel-root {
      // width: 400px;
    }
  }
  @media (max-width: 1000px) {
    & .carousel-root {
      width: 400px;
      width: 100%;
    }
  }

  & .img {
    height: 15px;
    width: 15px;
    @media (min-width: 700px) {
      height: 0.9vw;
      width: 0.9vw;
    }
    background: black;
    margin-top: 1px;
    margin-left: 8px;
    margin: auto;
  }
  & .img.up {
    transform: scaleY(-1);
  }
  & .img.left {
    transform: rotate(90deg);
    @media (min-width: 1480px) {
      margin-right: 0px;
    }
  }
  & .img.right {
    transform: rotate(-90deg);
    margin-left: 0px;
    @media (max-width: 700px) {
      margin: auto;
    }
  }
  & .img {
    color: white;
    background: white;
  }

  & .slider-wrapper {
    @media (min-width: 700px) {
      // width: 382px;
    }
  }

  margin-top: 50px;
  @media (max-width: 700px) {
  }
  & .arrow {
    @media (max-width: 700px) {
      // display: none;
    }
  }

  & .carousel .slide img {
    // width: 80%;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1480px) {
    // max-width: 400px;
    // max-width: 25vw;
  }
  @media (max-width: 1200px) {
    order: 0;
  }
`;

const ImageContainer = styled.div`
  // padding: 40px;
  padding-bottom: 10px;
  // max-height: 610px;
  position: relative;
  overflow: hidden;
  & img,
  video {
    max-width: 400px;
    max-width: 25vw;
  }
  @media (max-width: 1480px) {
    & img,
    video {
      max-width: 350px;
    }
  }
  @media (max-width: 1180px) {
    & img,
    video {
      max-width: 300px;
    }
  }
  // @media (max-width: 1480px) {
  //   & img,
  //   video {
  //     max-width: 250px;
  //   }
  // }

  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const IndicatorContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: center;
  flex: 1;
  margin-top: 20px;
  max-width: 400px;
  max-width: 25vw;
  margin: auto;
  @media (max-width: 1480px) {
    max-width: 250px;
  }
`;

const Indicator = styled.div`
  // width: 30px;
  flex: 2;
  height: 3px;
  background: black;
  margin: 2px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;

const SizeBtn = styled(Button)<{
  active?: boolean;
  size?: 'large' | 'normal';
  isDisabled?: boolean;
}>`
  color: ${({ active }) => (active ? `var(--primary)` : 'black')};
  ${({ active }) => (active ? activeBtnState : '')}
  transform: scale(${({ size }) => (size === 'large' ? 1.4 : 1)});
  font-size: ${({ size }) => (size === 'large' ? '11px' : '1.9vh')};
  width: 2.55vw;
  height: 2.55vw;
  &:hover {
    background-color: ${({ isDisabled }) =>
      isDisabled ? `white` : `var(--primary)`};
    box-shadow: ${({ isDisabled }) =>
      isDisabled
        ? `none`
        : `-2.66667px -2.66667px 8px rgba(109, 93, 252, 0.4),
      4px 4px 8px rgba(174, 174, 192, 0.4),
      inset 0px 0px 0px 0.533333px rgba(255, 255, 255, 0.2)`};
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  background: white;
  object-fit: contain;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

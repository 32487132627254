import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import './splash/css/base.css';

export const WelcomePreview: React.FC = () => {
  // const nav = useNavigate();

  useEffect(() => {
    // Redirect mobile users away from splash page
    if (window.innerWidth < 699) {
      window.location.href = '/home';
    }
  }, [])

  return (
    <Container
      onClick={() => {
        document.body.style.background = '#EEEFF3';
        // nav('/');
        window.location.href = '/home';
      }}
    >
      <div>
        <div
          id='canvasContainer'
          data-grid='1000'
          data-mouse='0.59'
          data-strength='0.05'
          data-relaxation='0.88'
        >
          <img src='./assets/splash/img/Vector.svg' alt='' />
        </div>
      </div>
      {/* <div className={'three-d'}>carton</div> */}
      {/* <img
        className={'logo'}
        src={require('../images/Carton_3D.svg').default}
        alt={'carton'}
      /> */}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background: #9966cc;

  & .logo {
    margin: auto;
    width: 65vw;
  }
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: -1.8vw;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;

  & p {
    pointer-events: none;
    font-weight: 900;
    font-size: 11vw;
    margin: 0;
    color: #e7d8fc;
    opacity: 0.6;
    text-shadow: -1px -1px 0px #ffffff, 1px 1px 0px #bd97f2;
    text-align: center;
  }

  & h2 {
    position: absolute;
    margin-top: 10vw;
    font-size: 2.6vw;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 55%);
    text-align: center;
    color: #ede1ff;
    font-family: Termina, sans-serif;
    font-weight: 900;
    opacity: 0.7;
    text-shadow: 0px 1px 1px #bd97f2, 0px 2px 1px #bd97f2, 0px 3px 1px #bd97f2,
      0px 4px 1px #bd97f2, 0px 5px 1px #bd97f2, 0px 6px 1px #bd97f2,
      0px 7px 1px #bd97f2, 0px 8px 1px #bd97f2, 0px 9px 1px #bd97f2,
      0px 10px 1px #bd97f2, 0px 11px 1px #bd97f2;

    @media (max-width: 700px) {
      width: 100%;
      text-align: center;
      font-size: 2.5vw;
      text-shadow: 0px 1px 1px #bd97f2, 0px 2px 1px #bd97f2, 0px 3px 1px #bd97f2,
        0px 4px 1px #bd97f2, 0px 5px 1px #bd97f2, 0px 6px 1px #bd97f2,
        0px 7px 1px #bd97f2;
    }
  }
`;

export interface SizeGuideDataType {
  productId: number;
  productTitle: string;
  items: { title: string; sizes: string[] }[];
  image?: string;
}

export const DEFAULT_SIZE_GUIDE_DATA: SizeGuideDataType = {
  productId: 0,
  productTitle: '',
  items: [
    {
      title: 'CARTON',
      sizes: ['1', '2', '3', '4', '5', '6', '7'],
    },
    {
      title: 'WOMENS',
      sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
    },
    {
      title: 'MENS',
      sizes: ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
    },
  ],
};

export const SIZE_GUIDE_DATA: SizeGuideDataType[] = [
  {
    productId: 6935004348553,
    productTitle: 'T-SHIRT',
    image: require('../images/sketches/Tee_Mock.png'),
    items: [
      {
        title: 'FRONT LENGTH',
        sizes: ['', '', '', '', '', '', ''],
      },
      {
        title: 'CHEST WIDTH',
        sizes: ['20', '21', '22', '23', '24', '25', '26'],
      },
      {
        title: 'SLEEVE LENGTH',
        sizes: ['8', '8 1/2', '9', '9 1/2', '10', '10 1/2', '11'],
      },
    ],
  },
  {
    productId: 1234,
    productTitle: 'NEWTON ACADEMY JERSEY (NOTE: DROPPED SHOULDER)',
    items: [
      {
        title: 'FRONT LENGTH',
        sizes: ['18', '18 1/2', '19', '19 1/2', '20', '20 1/2', '21'],
      },
      {
        title: 'CHEST WIDTH',
        sizes: [
          '28 3/4',
          '29 3/4',
          '30 3/4',
          '31 3/4',
          '32 3/4',
          '33 3/4',
          '34 3/4',
        ],
      },
      {
        title: 'SLEEVE LENGTH',
        sizes: ['9', '9 1/4', '9 1/2', '9 3/4', '10', '10 1/4', '10 1/2'],
      },
    ],
  },
  {
    productId: 1234,
    productTitle: '3 POCKET LEGGINGS',
    items: [
      {
        title: 'WAIST WIDTH',
        sizes: ['11', '12', '13', '14', '15 1/4', '16 1/2', '18'],
      },
      {
        title: 'INSEAM LENGTH',
        sizes: ['25 1/2', '26', '26 1/2', '27', '27 1/2', '28', '28 1/2'],
      },
    ],
  },
  {
    productId: 1234,
    productTitle: 'HIKING SHORTS',

    items: [
      {
        title: 'WAIST WIDTH',
        sizes: ['12', '13 1/2', '15', '16', '17', '18', '19'],
      },
      {
        title: 'INSEAM LENGTH',
        sizes: ['4 3/4', '4 3/4', '4 3/4', '4 3/4', '4 3/4', '4 3/4', '4 3/4'],
      },
    ],
  },
  {
    productId: 1234,
    productTitle: 'COCOON CREW-BOMBER (NOTE: OVERSIZED + DROPPED SHOULDER)',

    items: [
      {
        title: 'FRONT LENGTH',
        sizes: ['29 1/2', '30', '30 1/2', '31', '31 1/2', '32', '32 1/2'],
      },
      {
        title: 'CHEST WIDTH',
        sizes: [
          '23 1/2',
          '24 1/2',
          '25 1/2',
          '26 1/2',
          '27 1/2',
          '28 1/2',
          '29 1/2',
        ],
      },
      {
        title: 'SLEEVE LENGTH',
        sizes: [
          '28 1/2',
          '29 1/2',
          '30 1/2',
          '31 1/2',
          '32 1/2',
          '33 1/2',
          '34 1/2',
        ],
      },
    ],
  },
  {
    productId: 1234,
    productTitle: 'FLORICULTURE FULL ZIP HOODIE',

    items: [
      {
        title: 'FRONT LENGTH',
        sizes: ['26 1/2', '27', '27 1/2', '28', '28 1/2', '29', '29 1/2'],
      },
      {
        title: 'CHEST WIDTH',
        sizes: [
          '20 1/2',
          '21 1/2',
          '22 1/2',
          '23 1/2',
          '24 1/2',
          '25 1/2',
          '26 1/2',
        ],
      },
      {
        title: 'SLEEVE LENGTH',
        sizes: [
          '23 3/4',
          '24 3/4',
          '25 3/4',
          '26 3/4',
          '27 3/4',
          '28 3/4',
          '29 3/4',
        ],
      },
    ],
  },
  {
    productId: 6934995796105,
    productTitle: 'MILITIA VEST',
    image: require('../images/sketches/Vest_Mock.png'),
    items: [
      {
        title: 'BACK LENGTH',
        sizes: [
          '23 1/2',
          '23 1/2',
          '24 1/2',
          '24 1/2',
          '24 1/2',
          '26 1/2',
          '26 1/2',
        ],
      },
      { title: 'CHEST WIDTH', sizes: ['', '', '', '', '', '', ''] },
    ],
  },
  {
    productId: 6934995828873,
    productTitle: 'PILOT PARKA',
    image: require('../images/sketches/Jacket_Mock.png'),
    items: [
      {
        title: 'BACK LENGTH',
        sizes: ['29 1/2', '29 1/2', '31', '31', '31', '32 1/2', '32 1/2'],
      },
      {
        title: 'CHEST WIDTH',
        sizes: ['22', '23', '24', '25', '27', '29', '31'],
      },
      {
        title: 'SLEEVE LENGTH',
        sizes: ['21', '22', '23', '24', '25', '26', '27'],
      },
    ],
  },
  {
    productId: 1234,
    productTitle: 'STEALTH JACKET',
    items: [
      {
        title: 'FRONT LENGTH',
        sizes: ['24', '24', '25 1/2', '25 1/2', '25 1/2', '27', '27'],
      },
      {
        title: 'CHEST WIDTH',
        sizes: ['20', '21', '22', '23', '24', '25', '26'],
      },
      {
        title: 'SLEEVE LENGTH',
        sizes: ['22', '23', '24', '24 1/2', '25', '25 1/2', '26'],
      },
    ],
  },
  {
    productId: 1234,
    productTitle: 'AMBASSADOR FLEECE (OVERSIZED + DROPPED SHOULDER)',
    items: [
      {
        title: 'FRONT LENGTH',
        sizes: ['25 1/2', '25 1/2', '27', '27', '27', '28 1/2', '28 1/2'],
      },
      {
        title: 'CHEST WIDTH',
        sizes: ['23', '24', '25', '26', '27', '28', '29'],
      },
      {
        title: 'SLEEVE LENGTH',
        sizes: ['19 1/2', '20 1/2', '21 1/2', '22', '22 1/2', '23', '23 1/2'],
      },
    ],
  },
  {
    productId: 1234,
    productTitle: 'LUNAR FLUX-SHELL',

    items: [
      {
        title: 'BACK LENGTH',
        sizes: ['30', '30 1/2', '31', '31 1/2', '32', '32 1/2', '33'],
      },
      {
        title: 'CHEST WIDTH',
        sizes: ['21', '22', '23', '24', '25', '26', '27'],
      },
      {
        title: 'SLEEVE LENGTH',
        sizes: ['23 1/2', '24 1/2', '25 1/2', '26', '26 1/2', '27', '27 1/2'],
      },
    ],
  },
  {
    productId: 1234,
    productTitle: 'PLATOON PUFFER (OVERSIZED)',
    items: [
      {
        title: 'FRONT LENGTH',
        sizes: ['27', '27 1/2', '28', '28 1/2', '29', '29 1/2', '30'],
      },
      {
        title: 'CHEST WIDTH',
        sizes: ['22', '23', '24', '25', '26', '27', '28'],
      },
      {
        title: 'SLEEVE LENGTH',
        sizes: ['23', '24', '25', '25 1/2', '26', '26 1/2', '27'],
      },
    ],
  },
];

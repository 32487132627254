import React, { useEffect } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Select from 'react-select/dist/declarations/src/Select';
import styled from 'styled-components';
import { validateEmail } from '../helper/Common';
import { makeRequest } from '../hooks/Resource';
import { useTopOfScreen } from '../hooks/useTopOfScreen';
import { ProductVariant } from '../pages/ProductDetail';
import { Button } from '../utils/Constants';
import Dropdown from 'react-select';
import { CartPreview } from './CartPreview';
import { Modal } from './Modal';
import { Textfield } from './Textfield';
import { SearchSection } from './SearchSection';
import { notify } from './ProductItem';
// import 'react-dropdown/style.css';
import { useCookies } from 'react-cookie';
import { elementRoles } from 'aria-query';

export const Navbar: React.FC<{
  refresh: number;
  onSearchChange: (searchText: string) => void;
  isHomePage?: boolean;
}> = ({ refresh, onSearchChange, isHomePage }) => {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [isMouseHover, setIsMouseHover] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [isAtTopOfPage] = useTopOfScreen();
  const [cartItems, setCartItems] = React.useState<
    { id: number; size: number; variant: ProductVariant }[]
  >([]);
  const [menu, setMenu] = React.useState<{ title: string; url: string }[]>([]);
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);
  const [cartPreviewVisible, setCartPreviewVisible] =
    React.useState<boolean>(false);
  const [firstLoad, setFirstLoad] = React.useState<boolean>(false);
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [path, setPath] = React.useState<string>('');
  const [cookies, setCookie] = useCookies();
  const countdownCookie = cookies.Countdown;
  const searchText = searchParams.get('search') ?? '';

  let isMobile = /iphone|ipod|android|ie|blackberry|fennec/.test(
    navigator.userAgent.toLowerCase()
  );

  const location: { pathname: any } = useLocation();

  // WebGLFluid(document.querySelector('canvas'), {
  //   // options
  //   TRIGGER: 'hover',
  //   BACK_COLOR: { r: 255, g: 255, b: 255 },
  //   BLOOM: false,
  //   IMMEDIATE: false,
  //   SUNRAYS: false,
  //   DENSITY_DISSIPATION: 3.5,
  //   VELOCITY_DISSIPATION: 1.7,
  //   CURL: 24,
  //   // COLORFUL: false,
  //   SHADING: true,
  // });

  const getContent = async () => {
    const aboutMeta = await makeRequest<{
      products?: { body?: { data?: { timerDate: any } } };
    }>(`/api/shopify/countdown/metafield`);

    if (
      aboutMeta?.products?.body?.data?.timerDate &&
      aboutMeta?.products?.body?.data?.timerDate[1].value !== 'false'
    ) {
      nav('/countdown');
    }
  };

  useEffect(() => {
    if (countdownCookie) {
      return;
    }
    getContent();
  }, []);

  const submitToKlaviyo = async (email: string) => {
    if (!validateEmail(email)) {
      return;
    }
    const resp = await makeRequest('/api/klaviyo', 'POST', { email });
    // console.log({ resp });
    notify('Subscribed!');
    setModalVisible(false);
  };
  React.useEffect(() => {
    onSearchChange(searchText);
  }, [searchText]);

  React.useEffect(() => {
    const isReturningVisitor = localStorage.getItem('is-returning-visitor');
    if (isReturningVisitor !== '1') {
      localStorage.setItem('is-returning-visitor', '1');
      setTimeout(() => {
        setModalVisible(true);
      }, 2000);
    }
  }, []);

  const getShopifyMenu = async () => {
    const resp = await makeRequest<{
      menu: {
        body: { data: { menu: { items: { title: string; url: string }[] } } };
      };
    }>(`/api/shopify/menu`);

    //console.log({ resp });
    setMenu(resp.menu.body.data.menu.items);
  };

  const getCartProductIds = async () => {
    const cartString = localStorage.getItem('cart');
    let cart: { id: number; size: number; variant: ProductVariant }[] = [];
    if (cartString) {
      cart = JSON.parse(cartString);
    }
    let product_idsObj = cart.map((pi) => { return `"gid://shopify/Product/${pi.id}"` }).join();
    // console.log(product_idsObj)
    const activeProdsResp = await makeRequest<{
      [x: string]: any;
      menu: {
        body: { data: { product_ids: [product_idsObj: string] } };
      };
    }>(`/api/shopify/getActiveProducts`, 'POST', { product_ids: [product_idsObj] });

    // console.log("activeProdsResp: ", activeProdsResp)
    let activeIds: any[] = [];
    activeIds = activeProdsResp.activeProducts.map((pi: { id: any; }) => { return { id: Number(pi.id.replace("gid://shopify/Product/", "")) } });

    let filteredCart: any[] = [];
    cart.forEach(element => {
      let t = activeIds.filter((v)=>{return v.id==element.id});
      // console.log(t)
      if(t.length>0){
        filteredCart.push(element);
      }
    });
    // console.log(filteredCart, activeIds, cart);
    setCartItems(filteredCart);
    return filteredCart;
  };

  React.useEffect(() => {
    if (Object.keys(searchParams).length !== 0) {
      setSearchParams({});
    }
    getCartProductIds();
    if (path && path === location.pathname) {
      return;
    }

    getShopifyMenu();
    if (location.pathname !== '/home') {
      setPath(location.pathname);
    }
  }, [location.pathname, path]);
  React.useEffect(() => {
    let cartData = localStorage.getItem('cart');
    if (cartData) {
      cartData = JSON.parse(cartData);
    }

    if (cartData && cartData.length > 0) {
      getCartProductIds();
    }
  }, [localStorage.getItem('cart')]);

  const refreshCart = async () => {
    if (!firstLoad) {
      setFirstLoad(true);
      return;
    }
    // console.log(window.location.pathname);
    const oldItems = [...cartItems];
    const items = await getCartProductIds();
    if (
      items.length > 0 &&
      items.length !== oldItems.length &&
      window.location.pathname !== '/cart'
    ) {
      setCartPreviewVisible(true);
      setTimeout(() => {
        setIsMouseHover((flag) => {
          if (!isMobile && !flag) setCartPreviewVisible(false);
          return flag;
        });
      }, 4000);
    }
    getShopifyMenu();
  };

  const associateCustomerWithCheckout = async () => {
    const checkoutId = localStorage.getItem('checkout-id');
    const token = localStorage.getItem('token');
    if (!checkoutId || !token) return;
    const resp = await makeRequest<{
      products?: { body?: { data?: { checkout?: { completedAt?: boolean } } } };
    }>(`/api/shopify/checkout/associate`, 'POST', {
      checkoutId,
      customerAccessToken: token,
    });

    if (resp.products?.body?.data?.checkout?.completedAt) {
      // checkout has been completed
      localStorage.removeItem('cart');
      setCartItems([]);
    }
  };
  React.useEffect(() => {
    if (refresh === 0) {
      return;
    }
    refreshCart();
  }, [refresh]);

  React.useEffect(() => {
    associateCustomerWithCheckout();
  }, []);
  return (
    <Container>
      <Spacing style={{ width: '100%' }}></Spacing>
      <Content
        style={{ zIndex: 3 }}
        transparent={isAtTopOfPage}
        onKeyUp={(e) => {
          if (e.code === 'Space') {
            setModalVisible(true);
          }
        }}
      >
        <Column>
          <Link to={'/home'} className={'logo-container'}>
            <img
              alt={'carton'}
              src={require('../images/carton-logo.svg').default}
              style={{ marginTop: 20, marginBottom: 20 }}
            />
          </Link>
          <PagesRow transparent={isAtTopOfPage}>
            <Link
              to={'/home'}
              className={'page-btn'}
              onMouseEnter={() => setMenuIsOpen(true)}
              onMouseLeave={() => setMenuIsOpen(false)}
            >
              SHOP
              <ShopDropDown
                isOpen={menuIsOpen}
                className={isAtTopOfPage ? 'purple' : ''}
              >
                <div className="main">
                  {menu.map((m) => (
                    <>
                      <a
                        href={m.url}
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = m.url;
                        }}
                        className="subcategory-link"
                      >
                        {m.title.toUpperCase()}
                      </a>
                    </>
                  ))}
                  <a
                    href={'/home'}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = '/home';
                    }}
                    className="subcategory-link"
                  >
                    SHOP ALL
                  </a>
                </div>
                {/* <div className={'sub'}>
                  <a
                    href={'m.url'}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = 'm.url';
                    }}
                  >
                    {'m.title'}
                  </a>
                </div> */}
              </ShopDropDown>
            </Link>
            <Link to={'/account'} className={'page-btn'}>
              ACCOUNT
            </Link>
            <Link to={'/atelier'} className={'page-btn'}>
              ATELIER
            </Link>
            <Link to={'/about'} className={'page-btn'}>
              ABOUT
            </Link>
          </PagesRow>

          {/* <Textfield
            color={'white'}
            style={{ marginTop: 30, width: 320 }}
            placeholder={'SEARCH'}
            value={searchText}
            onChange={setSearchText}
          /> */}
        </Column>
        {cartItems.length > 0 ? (
          <Link
            to={'/cart'}
            style={{ position: 'absolute', top: 19, right: 28, padding: 10 }}
            onMouseEnter={() => setCartPreviewVisible(true)}
            onMouseLeave={() => setCartPreviewVisible(false)}
          >
            {/* <img
            alt={'cart'}
            src={require(`../images/cart-icon.svg`).default}
            style={{ cursor: 'pointer' }}
          /> */}
            <div
              // alt={'cart'}
              // src={require(`../images/cart-icon.svg`).default}

              className="img"
              style={{
                cursor: 'pointer',
                mask: `url('${require('../images/cart-icon.svg').default
                  }') center/contain no-repeat`,
                WebkitMask: `url('${require('../images/cart-icon.svg').default
                  }') center/contain no-repeat`,
              }}
            />
            <Badge>{cartItems.length || ''}</Badge>
          </Link>
        ) : (
          <div />
        )}
        <Modal
          size={'large'}
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
        >
          <div style={{ padding: 100, paddingTop: 52 }}>
            <h3 style={{ textAlign: 'center' }}>WELCOME!</h3>
            <p style={{ textAlign: 'center' }}>
              To join our newsletter and stay updated on future drops, enter
              your email address below:
            </p>
            <StyledTextfield
              value={email}
              onChange={setEmail}
              style={{ marginTop: 20, width: '100%' }}
            />
            <Button
              style={{ marginTop: 20, width: '100%' }}
              onClick={() => submitToKlaviyo(email)}
            >
              ENTER
            </Button>
          </div>
        </Modal>
        <BackgroundContainer>
          <WhiteBackground isTransparent={isAtTopOfPage} />
          <WhiteGradient
            style={{ pointerEvents: 'none' }}
            isTransparent={isAtTopOfPage}
          />
        </BackgroundContainer>
      </Content>
      <SearchWrapper>
        <Textfield
          color={'white'}
          style={{ marginTop: 30, /*maxWidth: 320*/ maxWidth: '20vw' }}
          placeholder={'SEARCH'}
          value={searchText}
          autoComplete="search"
          onChange={(text) => {
            if (!text) {
              searchParams.delete('search');
              setSearchParams(searchParams, { replace: true });
              return;
            }
            setSearchParams({ search: text }, { replace: true });
          }}
        />
      </SearchWrapper>
      <>
        <CartPreview
          cartItems={cartItems}
          visible={cartPreviewVisible}
          onChange={setCartPreviewVisible}
          onMouseEnter={() => setIsMouseHover(true)}
          onMouseLeave={() => setIsMouseHover(false)}
        />
      </>
      {searchText && (
        <SearchSection
          menu={menu}
          searchText={searchText}
          onCartChange={refreshCart}
        />
      )}
    </Container>
  );
};

const ShopDropDown = styled.div<{ isOpen: boolean }>`
  display: flex;
  position: absolute;
  transition: 0.3s all ease-in-out;
  top: 20px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  background: white;
  padding: 0px;
  margin-left: -10px;
  overflow: hidden;

  .main {
    display: flex;
    flex-direction: column;
    & > a {
      color: black;
      text-decoration: none;
      padding: 10px 15px;
    }
    & > a:hover {
      color: white;
      background: var(--primary);
    }
    & > a.subcategory-link:hover {
      color: white !important;
    }
  }

  background: linear-gradient(
      315deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #f0f1f5;
  background-blend-mode: soft-light, normal;

  box-shadow: -2.66667px -2.66667px 8px #ffffff,
    4px 4px 8px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.533333px rgba(255, 255, 255, 0.2);
  &.purple {
    box-shadow: -2.66667px -2.66667px 8px rgba(109, 93, 252, 0.4),
      4px 4px 8px rgba(174, 174, 192, 0.4),
      inset 0px 0px 0px 0.533333px rgba(255, 255, 255, 0.2);
  }
  border-radius: 10px;

  & .sub {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    color: black;
    text-decoration: none;
    border-left: 1px solid rgba(150, 150, 150, 0.2);
    & > a {
      color: black;
      text-decoration: none;
      padding: 5px 10px;
    }
    & > a:hover {
      color: white;
      background: var(--primary);
    }
  }
`;

const Spacing = styled.div`
  height: 160px;
  @media (max-width: 700px) {
    height: 120px;
  }
`;

const StyledTextfield = styled(Textfield)``;

const Container = styled.div`
  @media (max-width: 700px) {
    display: none;
  }
  & .textfield-wrapper {
    flex: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & .img {
    height: 15px;
    width: 15px;
    height: 0.9vw;
    width: 0.9vw;
    background: black;
    margin-top: 3px;
  }
  & .img:hover {
    background: white;
  }

  & .logo-container img {
    width: 9vw;
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  // margin-top: -60px;
  position: absolute;
  width: 100%;
  top: 140px;
  top: 5.5vw;
  z-index: 2;
  & input {
    font-size: 0.7vw;
  }

  @media (min-width: 700px) {
    // margin-top: -80px;
  }
  @media (min-width: 1500px) {
    // margin-top: -70px;
  }

  @media (min-width: 1600px) {
    // margin-top: -50px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div<{ transparent: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  & img:hover {
    color: var(--primary);
  }

  & .page-btn,
  .page-btn .Dropdown-placeholder {
    padding-left: 25px;
    padding-right: 25px;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    font-size: 12px;
    font-size: 0.7vw;
    text-decoration: none;
    color: black;
    font-weight: 500;
  }
  & .page-btn {
    padding-bottom: 10px;
    > div {
      display: none;
    }
    &:hover {
      > div {
        display: block;
      }
    }
  }
  @media (max-width: 700px) {
    font-size: 12px;
  }
  @media (max-width: 700px) {
    & .page-btn,
    .page-btn .Dropdown-placeholder {
      font-size: 12px;
    }
  }

  & a:hover,
  .Dropdown-placeholder:hover {
    color: ${({ transparent }) => (transparent ? '#666666' : `var(--primary)`)};
  }
`;

const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
`;
const WhiteBackground = styled.div<{ isTransparent: boolean }>`
  width: 100%;
  height: 105%;

  transition: 0.2s all ease-in-out;
  background-image: linear-gradient(
    to bottom,
    rgba(238, 239, 243, 1),
    rgba(238, 239, 243, 1)
  );
  ${({ isTransparent }) => (isTransparent ? `opacity: 0` : `opacity: 1`)}
`;

const WhiteGradient = styled.div<{ isTransparent: boolean }>`
  height: 50%;
  transition: 0.2s all ease-in-out;
  background-image: linear-gradient(
    to bottom,
    rgba(238, 239, 243, 1),
    rgba(238, 239, 243, 0)
  );
  ${({ isTransparent }) => (isTransparent ? `opacity: 0` : `opacity: 1`)}
`;

const PagesRow = styled.div<{ transparent: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & .dropdown:hover .menu {
  }
`;

const Badge = styled.div`
  display: flex;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: black;
  color: white;
  font-size: 6px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  top: 8px;
  right: 4px;
`;

import React from 'react';
import { ProductItem } from '../components/ProductItem';
import { Textfield } from '../components/Textfield';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { makeRequest } from '../hooks/Resource';
import { ShopifyProductsResponse, ProductMinimal } from './Home';

export const Atelier: React.FC = () => {
  const nav = useNavigate();
  const [products, setProducts] = React.useState<ProductMinimal[]>([]);

  const getShopifyProducts = async () => {
    const resp = await makeRequest<{
      products?: { body?: { data?: { products: ShopifyProductsResponse } } };
    }>(`/api/shopify/products`);

    if (resp?.products?.body?.data?.products?.edges?.length) {
      const productResp: ProductMinimal[] =
        resp.products.body.data.products.edges.flatMap((r) => {
          const ids = r.node.id?.split('/');
          return {
            id: +(ids?.length ? ids[ids.length - 1] : 0),
            title: r.node.title,
            tags: r.node.tags,
            collections: r.node.collections,
            productType: r.node.productType,
            featuredImage: { url: r.node.featuredImage.url },
            variants: r.node.variants.nodes.map((v) => {
              const variantsPathArr = `${v.id}`.split('/');
              return {
                id: +(
                  (variantsPathArr?.length &&
                    variantsPathArr[variantsPathArr.length - 1]) ||
                  0
                ),
                price: +(v.price || 0),
              };
            }),
          };
        });
      setProducts(productResp);
    }
  };

  React.useEffect(() => {
    getShopifyProducts();
  }, []);

  return (
    <Container>
      <div style={{ marginTop: /*60*/ '5vw' }} />
      <Column>
        <h2>Coming Soon</h2>
        {/* {products.map((product) => {
          return (
            <ProductItem
              id={product.id}
              title={product.title}
              img={product.featuredImage}
              mode={'ATELIER'}
            />
          );
        })}
        {products.length === 0 ? (
          <ProductItem id={0} title={''} img={''} mode={'ATELIER'} />
        ) : (
          <div />
        )} */}
      </Column>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: -30px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;

  & h2 {
    margin: 0;
    paddiing: 0;
    margin: auto;
    font-size: 32px;
    @media (min-width: 700px) {
      font-size: 2vw;
    }
  }
`;

import React from 'react';
import { Textfield } from './Textfield';
import styled, { CSSProperties } from 'styled-components';
import { activeBtnState, Button } from '../utils/Constants';
import { Product } from '../pages/ProductDetail';
import {
  DEFAULT_SIZE_GUIDE_DATA,
  SIZE_GUIDE_DATA,
} from '../utils/SizeGuideData';
import {
  Metafield,
  ProductMinimal,
  ShopifyProductsResponse,
} from '../pages/Home';
import { makeRequest } from '../hooks/Resource';
import { useLocation } from 'react-router-dom';

interface SizePosition {
  length: number;
  style: CSSProperties;
  degrees?: number;
  showMaster?: boolean;
}

export interface Collection {
  id: string;
  title: string;
  metafields: Metafield[];
}

const SHOW_LINES = true;
export const SizeChartSection: React.FC<{
  product?: Product;
  showMaster?: boolean;
  visible?: boolean;
}> = ({ product, showMaster = false, visible = false }) => {
  const [sizeIdx, setSizeIdx] = React.useState<number | null>(null);
  const [values, setValues] = React.useState<{
    vertical: string;
    horizontal: string;
    angle: string;
  }>({ vertical: '', horizontal: '', angle: '' });
  const [refreshImage, setRefreshImage] = React.useState<number>(0);
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(
    null
  );
  const [selectedCollection, setSelectedCollection] =
    React.useState<Collection | null>(null);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [collections, setCollections] = React.useState<Collection[]>([]);
  const [path, setPath] = React.useState<string>('');

  const location: { pathname: any } = useLocation();
  // Set video URL to empty string if modal is closed; this stops playback.
  const videoUrl = visible
    ? 'https://player.vimeo.com/video/760003776?h=8e02825269&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
    : '';

  const getCollections = async () => {
    const resp = await makeRequest<{
      collections?: {
        body?: { data?: { collections?: { nodes?: Collection[] } } };
      };
    }>(`/api/shopify/collections`);

    if (resp?.collections?.body?.data?.collections?.nodes?.length) {
      const filtered = resp.collections.body.data.collections.nodes.filter(
        (n) =>
          n.metafields.find((m) => m?.key === 'include_in_size_guide')
            ?.value === 'true'
      );

      setCollections(filtered);
      const referencedCollection = product?.metafields.find(
        (m) => m?.key === 'size_guide'
      )?.value;
      const collectionId =
        referencedCollection?.split('/')[
          referencedCollection.split('/').length - 1
        ];
      const found = filtered.find((f) => f.id === referencedCollection);

      setSelectedCollection(found || filtered[0]);
    }
  };

  const getShopifyProducts = async () => {
    const resp = await makeRequest<{
      products?: { body?: { data?: { products: ShopifyProductsResponse } } };
    }>(`/api/shopify/products`);
    if (resp?.products?.body?.data?.products?.edges?.length) {
      const productResp: Product[] =
        resp.products.body.data.products.edges.flatMap((r) => {
          // console.log({ node: r.node });
          const ids = r.node.id?.split('/');
          return {
            id: +((ids?.length && ids[ids.length - 1]) || 0),
            // title: r.node.title,
            featuredImage: { url: r.node.featuredImage.url },
            // id: +(r.node.id.split() || 0),
            title: r.node.title,
            img: r.node.images.nodes[0].url,
            tags: r.node.tags,
            collections: r.node.collections,
            productType: r.node.productType,
            metafields: r.node.metafields,
            images: r.node.images.nodes.map((im) => im.url),
            description: r.node.description,
            price: 0,
            variants: r.node.variants.nodes.map((v) => {
              const variantsPathArr = `${v.id}`.split('/');
              return {
                id: +(
                  (variantsPathArr?.length &&
                    variantsPathArr[variantsPathArr.length - 1]) ||
                  0
                ),
                price: +(v.price || 0),
              };
            }),
          };
        });
      setProducts(productResp);

      !product && setSelectedProduct(productResp[0]);
    }
  };

  React.useEffect(() => {
    if (path && path === location.pathname) {
      getShopifyProducts();
      getCollections();
    }
    setPath(location.pathname);
  }, [location.pathname, path]);

  const sizeGuide =
    SIZE_GUIDE_DATA.find(
      (sgd) => sgd.productId === (product || selectedProduct)?.id
    ) || DEFAULT_SIZE_GUIDE_DATA;

  const mobileRightMultiplier = document?.body.clientWidth < 700 ? 2.85 : 1;

  function findRelevantMetafieldNumber(key: string) {
    const productMetafields =
      product?.metafields?.find((mf) => mf?.key === 'sketch_margin_top')
        ?.value || 0;
    if (productMetafields) {
      return Number(productMetafields);
    }

    return Number(
      selectedCollection?.metafields?.find((mf) => mf?.key === key)?.value || 0
    );
  }
  function findRelevantMetafieldArray(key: string) {
    const productMetafields = product?.metafields?.find(
      (mf) => mf?.key === key
    )?.value;
    // console.log({ productMetafields });

    if (productMetafields) {
      return JSON.parse(productMetafields);
    }

    // console.log({
    //   selectedCollection,
    //   value: selectedCollection?.metafields?.find((mf) => mf?.key === key),
    // });
    return JSON.parse(
      selectedCollection?.metafields?.find((mf) => mf?.key === key)?.value ||
        '[]'
    );
  }

  const verticalTop = findRelevantMetafieldNumber('sketch_margin_top');
  const verticalLeft = findRelevantMetafieldNumber('sketch_margin_left');
  const horizontalTop = findRelevantMetafieldNumber(
    'sketch_horizontal_margin_top'
  );
  const horizontalLeft = findRelevantMetafieldNumber(
    'sketch_horizontal_margin_left'
  );
  const sleeveTop = findRelevantMetafieldNumber('sketch_sleeve_margin_top');
  const sleeveLeft = findRelevantMetafieldNumber('sketch_sleeve_margin_left');
  const verticalLength = findRelevantMetafieldNumber('sketch_vertical_length');
  const horizontalLength = findRelevantMetafieldNumber(
    'sketch_horizontal_length'
  );
  const sketchSleeveLength = findRelevantMetafieldNumber(
    'sketch_sleeve_length'
  );
  const backLength: string[] = findRelevantMetafieldArray('back_length');
  const frontLength: string[] = findRelevantMetafieldArray('front_length');

  const sleeveLength: string[] = findRelevantMetafieldArray('sleeve_length');
  const chestWidth: string[] = findRelevantMetafieldArray('chest_width');
  const waistWidth: string[] = findRelevantMetafieldArray('waist_width');
  const inseamLength: string[] = findRelevantMetafieldArray('inseam_length');

  let sketchImage: string | undefined = product?.metafields?.find(
    (mf) => mf?.key === 'sketch'
  )?.reference?.image?.originalSrc;
  if (!sketchImage) {
    sketchImage = selectedCollection?.metafields?.find(
      (mf) => mf?.key === 'sketch'
    )?.reference?.image?.originalSrc;
  }

  const filteredSizeChart =
    [
      { title: 'CARTON', sizes: ['1', '2', '3', '4', '5', '6', '7'] },
      { title: 'Back Length', sizes: backLength },
      { title: 'Front Length', sizes: frontLength },
      { title: 'Chest Width', sizes: chestWidth },
      { title: 'Waist Width', sizes: waistWidth },
      { title: 'Inseam Width', sizes: inseamLength },
      { title: 'Sleeve Length', sizes: sleeveLength },
    ].filter((c) => c.sizes.length) || DEFAULT_SIZE_GUIDE_DATA;
  console.log(filteredSizeChart);

  const sizePositioning: { [type: string]: SizePosition } = {
    vertical: {
      // length: 33,
      length: verticalLength,
      // style: { top: 40, right: 1 },
      style: {
        top: verticalTop,
        left: verticalLeft,
        display: verticalLength === 0 ? 'none' : 'flex',
      },
    },
    horizontal: {
      // length: 16,
      length: horizontalLength,
      style: {
        // left: '50%',
        // transform: 'translateX(-50%)',
        display: horizontalLength === 0 ? 'none' : 'flex',
        justifyContent: 'center',
        top: horizontalTop,
        left: horizontalLeft,
      },
    },
    angled: {
      // length: 35,
      length: sketchSleeveLength,
      degrees: 74,
      // style: { top: 30, left: 15 },
      style: {
        top: sleeveTop,
        left: sleeveLeft,
        display: sketchSleeveLength === 0 ? 'none' : 'flex',
      },
    },
  };

  const thisSizePosition = sizePositioning;

  const sizefields = (
    <>
      {/* <h5 style={{ textAlign: 'center', margin: 0 }}>SIZE CONVERSION CHART</h5> */}
      {filteredSizeChart.map((item) => (
        <TextfieldContainer>
          <StyledTextfield
            size={'large'}
            value={item.title}
            onChange={() => {}}
            style={{
              /*, minWidth: 180*/
              minWidth: '2vw',
            }}
            readonly
          />
          {item.sizes.map((si, idx) => (
            <StyledTextfield value={si} onChange={() => {}} readonly />
          ))}
        </TextfieldContainer>
      ))}
    </>
  );
  const defaultSizefields = (
    <>
      <h5 style={{ textAlign: 'center', margin: 0 }}>SIZE CONVERSION CHART</h5>
      {DEFAULT_SIZE_GUIDE_DATA.items.map((item) => (
        <TextfieldContainer>
          <StyledTextfield
            size={'large'}
            value={item.title}
            onChange={() => {}}
            style={{ /*minWidth: 180*/ minWidth: '2vw' }}
            readonly
          />
          {item.sizes.map((si, idx) => (
            <StyledTextfield value={si} onChange={() => {}} readonly />
          ))}
        </TextfieldContainer>
      ))}
    </>
  );

  return (
    <Container
      style={{
        opacity: !collections.length ? 0 : 1,
        transition: '0.3s all ease-in-out',
      }}
      hasProduct={!!product}
    >
      {/* {!product && rectangle} */}
      {!product && (
        <div
          className={'video-wrapper'}
          style={{
            display: 'flex',
            //   background: 'rgba(190, 191, 196, 0.38)',
            // background: '#DDDEE2',
            borderRadius: 25,
            // width: 'calc(100% - 3vw)',
            // height: '19vw',
            marginTop: 20,
            // height: 250,
            marginBottom: 50,
            overflow: 'hidden',
            minWidth: 320,
            minHeight: 190,
          }}
        >
          <iframe
            width='100%'
            height='100%'
            src='https://player.vimeo.com/video/758138613?h=390ed07238&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
            // src='https://www.youtube.com/embed/c0-hvjV2A5Y'
            title='CARTON ABOUT V6'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            style={{ width: '100%', height: '100%', borderRadius: 25 }}
          ></iframe>
        </div>
      )}
      {/* {!product && sizefields} */}
      {/* {!product && defaultSizefields} */}

      {/* {!product && <div style={{ marginTop: 50 }} />} */}

      {showMaster && (
        <SizingInstruction style={{ marginBottom: 15 }}>
          Click the option below to expand the sizing details
        </SizingInstruction>
      )}
      {showMaster && (
        <div
          className={'master-list'}
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {collections.map((col, idx) => {
            return (
              <>
                {idx !== 0 && <>&#x2022;</>}{' '}
                <div
                  className={
                    selectedCollection?.id === col.id ? 'selected' : ''
                  }
                  style={{ whiteSpace: 'nowrap', display: 'inline-block' }}
                  onClick={() => {
                    setSizeIdx(null);
                    setSelectedCollection(col);
                    setValues({ vertical: '', horizontal: '', angle: '' });
                  }}
                >
                  {col.title}
                </div>
              </>
            );
          })}
        </div>
      )}
      {product ? (
        <h4 style={{ textAlign: 'center' }}>PRODUCT MEASUREMENTS</h4>
      ) : (
        <div />
      )}
      <div
        className={'size-btn-wrapper'}
        style={{
          display: 'flex',
          marginBottom: 35,
        }}
      >
        {[...Array(7)].map((_, idx) => (
          <SizeBtn
            size={'large'}
            onClick={() => {
              console.log('in large');

              setSizeIdx(idx);

              const vertical = filteredSizeChart[3]?.sizes[idx];
              const horizontal = filteredSizeChart[1]?.sizes[idx];
              const angle = filteredSizeChart[2]?.sizes[idx];
              setValues({
                vertical,
                horizontal,
                angle,
              });
              setRefreshImage((v) => v + 1);
            }}
            active={sizeIdx === idx}
          >
            {idx + 1}
          </SizeBtn>
        ))}
      </div>
      {(product || selectedCollection) && (
        <>
          <ImageContainer key={`refresh-${refreshImage}`}>
            {SHOW_LINES && values.vertical && thisSizePosition && (
              <VerticalSizing
                length={thisSizePosition?.vertical.length ?? 0}
                style={thisSizePosition?.vertical.style}
              >
                <div className={'pre'} />
                <div
                  className={'value'}
                  style={{
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  }}
                >
                  <div style={{ display: 'block' }}>
                    {values.vertical.length <= 2 && <>&nbsp;</>}
                    {values.vertical}
                    {values.vertical.length <= 2 && <>&nbsp;</>}
                  </div>
                </div>
                <div className={'post'} />
              </VerticalSizing>
            )}
            {SHOW_LINES && values.horizontal && thisSizePosition && (
              <HorizontalSizing
                length={thisSizePosition?.horizontal.length ?? 0}
                style={thisSizePosition?.horizontal.style}
              >
                <div className={'pre'} />
                <div
                  className={'value'}
                  style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                >
                  <div
                    style={{
                      display: 'block',
                      position: 'relative',
                    }}
                  >
                    {values.horizontal.length <= 2 && <>&nbsp;</>}
                    {values.horizontal}
                    {values.horizontal.length <= 2 && <>&nbsp;</>}
                  </div>
                </div>
                <div className={'post'} />
              </HorizontalSizing>
            )}
            {SHOW_LINES && values.angle && thisSizePosition && (
              <AngledSizing
                length={thisSizePosition?.angled.length ?? 0}
                degrees={thisSizePosition?.angled.degrees ?? 0}
                style={thisSizePosition?.angled.style}
              >
                <div className={'pre'} />
                <div
                  className={'value'}
                  style={{
                    height: 17,
                    width: 38,
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'block',
                      position: 'absolute',
                    }}
                  >
                    {values.angle.length <= 2 && <>&nbsp;</>}
                    {values.angle}
                    {values.angle.length <= 2 && <>&nbsp;</>}
                  </div>
                </div>
                <div className={'post'} />
              </AngledSizing>
            )}
            <img
              alt={''}
              src={sketchImage}
              style={{ borderRadius: 16, height: 500, objectFit: 'cover' }}
            />
          </ImageContainer>
          <p className={'measured-text'}>Units of Measure = Inches</p>

          {/* {product && <div style={{ marginTop: '2vw' }} />}
          {product && sizefields}
          {product && <div style={{ marginTop: '2vw' }} />}
          {product && defaultSizefields}
          {product && <div style={{ marginBottom: '2vw' }} />} */}
          <div style={{ marginTop: '2vw' }} />
          {sizefields}
          <div style={{ marginTop: '2vw' }} />
          {defaultSizefields}
          <div style={{ marginBottom: '2vw' }} />
        </>
      )}

      {/* {product && rectangle} */}
      {product && (
        <div
          className='video-wrapper'
          style={{
            display: 'flex',
            //   background: 'rgba(190, 191, 196, 0.38)',
            background: 'transparent',
            borderRadius: 25,
            marginTop: 20,
            // height: '19vw',

            overflow: 'hidden',
          }}
        >
          <iframe
            width='100%'
            height='100%'
            // src='https://www.youtube.com/embed/c0-hvjV2A5Y'
            // src='https://player.vimeo.com/video/758138613?h=390ed07238&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
            src={videoUrl}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            style={{ width: '100%', borderRadius: 25, overflow: 'hidden' }}
          ></iframe>
        </div>
      )}
    </Container>
  );
};

const Image = styled.img`
  height: 68vh;
`;
const SizeInputContainer = styled.div`
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const SizingInstruction = styled.div`
  font-size: 0.65vw;
  @media (max-width: 700px) {
    font-size: 12px;
    text-align: center;
  }
`;

const Container = styled.div<{ hasProduct: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  // paddingLeft: 30;
  // paddingRight: 30;
  max-width: calc(100% - 50px);
  margin: ${({ hasProduct }) => (hasProduct ? 'auto' : '0px')};
  margin-left: ${({ hasProduct }) => (hasProduct ? 'auto' : '10px')};
  margin-right: ${({ hasProduct }) => (hasProduct ? 'auto' : '10px')};
  margin-top: ${({ hasProduct }) => (hasProduct ? '30px' : '0px')};
  @media (max-width: 700px) {
    position: ${({ hasProduct }) =>
      hasProduct
        ? `
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow-y: scroll;`
        : 'relative'};
    margin-top: ${({ hasProduct }) => (hasProduct ? '15px' : '0px')};
  }

  & iframe {
    max-width: 90%;
    max-width: 100%;
    // width: 560px;
    @media (min-width: 700px) {
      max-width: 100%;
    }
  }

  & .measured-text {
    font-size: 0.65vw;
    @media (max-width: 700px) {
      font-size: 10px;
    }
  }

  & h5 {
    width: 33.8vw;
    @media (max-width: 900px) {
      font-size: 10px;
      width: 100%;
      margin-top: 10px;
    }
  }

  & h4 {
    margin-top: 5px;
    width: 33.8vw;
    @media (max-width: 900px) {
      font-size: 14px;
      width: 100%;
    }
    @media (max-width: 700px) {
      // margin-top: 50px;
    }
  }

  & .master-list .selected {
    font-weight: normal;
  }
  & .master-list {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.8vw;
    min-width: 320px;
    width: 33.8vw;
    @media (max-width: 900px) {
      font-size: 12px;
      justify-content: center;
    }

    & div {
      margin: 5px;
      cursor: pointer;
    }
  }

  & .video-wrapper {
    width: 33.8vw;
    height: 20vw;
    margin-bottom: 20px;
    @media (min-width: 700px) {
      height: 19vw;
    }

    @media (max-width: 700px) {
      width: ${({ hasProduct }) =>
        hasProduct ? ' calc(100% - 24.5vw)' : 'calc(100% - 5vw)'};
      // width: calc(100% - 5vw);
      // width: calc(100% - 24.5vw);
      height: 53vw;
      // min-height: 53vw;

      // min-height: ${({ hasProduct }) => (hasProduct ? '43vw' : '53vw')};
      height: 43vw;
      min-height: 43vw;
      margin-bottom: 50px;
    }
  }

  & .size-btn-wrapper {
    margin-top: 35px;
    justify-content: center;
    @media (max-width: 700px) {
      margin-top: 20px;
    }
    // @media (max-width: 700px) {
    //   width: calc(100% - 5vw);

    //   padding-left: 23vw;
    //   padding-right: 23vw;
    // }
  }
`;

const VerticalSizing = styled.div<{ length: number }>`
  position: absolute;
  display: flex;
  align-items: center;
  transform: rotate(90deg);
  bottom: 0px;
  top: 0px;
  font-size: 13px;
  @media (max-width: 700px) {
    font-size: 3.1vw;
  }
  & .pre {
    width: ${({ length }) => length}px;
    height: 1px;
    // background: var(--primary);
  }
  .value {
    padding: 0px;
    transform: rotate(-90deg);
    & div {
      background: white;
    }
  }
  & .post {
    width: ${({ length }) => length}px;
    height: 1px;
    // background: var(--primary);
  }
`;
const HorizontalSizing = styled.div<{ length: number }>`
  position: absolute;
  display: flex;

  align-items: center;
  bottom: 0px;
  right: 0px;
  font-size: 13px;
  @media (max-width: 700px) {
    font-size: 3.1vw;
  }
  & .pre {
    width: ${({ length }) => length}px;
    height: 1px;
    // background: var(--primary);
  }

  .value {
    padding: 10px;
    & div {
      background: white;
    }
  }
  & .post {
    width: ${({ length }) => length}px;
    height: 1px;
    // background: var(--primary);
  }
`;
const AngledSizing = styled.div<{ length: number; degrees: number }>`
  position: absolute;
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 0px;
  font-size: 13px;
  @media (max-width: 700px) {
    font-size: 3.1vw;
  }
  transform: ${({ degrees }) => `rotate(-${degrees}deg)`};

  & .pre {
    width: ${({ length }) => length}px;
    height: 1px;
    // background: var(--primary);
  }
  .value {
    padding: 0px;
    transform: ${({ degrees }) => `rotate(${degrees}deg)`};
    & div {
      background: white;
    }
  }
  & .post {
    width: ${({ length }) => length}px;
    height: 1px;
    // background: var(--primary);
  }
`;
const StyledTextfield = styled(Textfield)<{ size?: 'normal' | 'large' }>`
  & .input-container {
    height: 30px;
    height: 2.8vw;
  }
`;

const TextfieldContainer = styled.div`
  & .input-container {
    width: 3.3vw;
    min-width: 30px;
    height: 2.8vw;
    @media (max-width: 700px) {
      // min-width: 55px;
    }
  }
  & :first-child .input-container {
    width: 8.4vw;
    @media (max-width: 700px) {
      width: 23vw;
    }
  }
  display: flex;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  // padding-left: 23vw;
  // padding-right: 23vw;
  & input {
    font-size: 0.6vw;
  }
  @media (max-width: 700px) {
    // width: 100%;
    // overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    & input {
      font-size: 1.2vw;
    }
  }
  & .textfield-wrapper {
    margin-left: 2px;
    margin-right: 2px;
  }

  & .textfield-wrapper div {
    border-radius: 4px;
  }
`;

const ImageContainer = styled.div`
  // padding: 40px;
  padding-bottom: 10px;
  position: relative;

  @media (max-width: 700px) {
    padding: 0px;
    min-height: 500px;
  }
  @media (min-width: 0px) {
    padding-top: 0px;
  }
  overflow: hidden;
`;

const SizeBtn = styled(Button)<{ active?: boolean; size?: 'large' | 'normal' }>`
  width: 40px;
  height: 40px;
  width: 2.8vw;
  height: 2.8vw;
  min-width: 29px;
  min-height: 29px;
  max-width: 70px;
  max-height: 70px;
  margin-left: ${({ size }) => (size === 'large' ? '1vw' : '5px')};
  margin-right: ${({ size }) => (size === 'large' ? '1vw' : '5px')};
  transform: scale(${({ size }) => (size === 'large' ? 1.4 : 1)});
  font-size: ${({ size }) => (size === 'large' ? '11px' : '14px')};
  color: ${({ active }) => (active ? `var(--primary)` : 'black')};
  ${({ active }) => (active ? activeBtnState : '')}
  @media(max-width: 700px) {
    margin-left: 5px;
    margin-right: 5px;
    transform: scale(1);
    min-width: 10vw;
    height: 10vw;
  }
`;

import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { CartItem } from '../components/CartItem';
import { notify, ProductItem } from '../components/ProductItem';
import { Column, SideNav } from '../helper/Common';
import { makeRequest } from '../hooks/Resource';
import {
  Button,
  CartCreateInput,
  CheckoutCreateInput,
  MailingAddress,
  ShopifyCheckoutCreateResponse,
} from '../utils/Constants';
import { ShopifyProductsResponse } from './Home';
import { Product, products, ProductVariant } from './ProductDetail';
import toast from 'react-hot-toast';
import { compact, uniqBy } from 'lodash';

export const Cart: React.FC<{ onCartChange: () => void }> = ({
  onCartChange,
}) => {
  const [productsInCart, setProductsInCart] = React.useState<Product[]>([]);
  const [cartProducts, setCartProducts] = React.useState<
    { id: number; size: number; variant: ProductVariant }[]
  >([]);
  const navigate = useNavigate();
  const getCartProductIds = async () => {
    const cartString = localStorage.getItem('cart');
    let cart: { id: number; size: number; variant: ProductVariant }[] = [];
    if (cartString) {
      cart = JSON.parse(cartString);
    }
    let product_idsObj = cart.map((pi) => { return `"gid://shopify/Product/${pi.id}"` }).join();
    // console.log(product_idsObj)
    const activeProdsResp = await makeRequest<{
      [x: string]: any;
      menu: {
        body: { data: { product_ids: [product_idsObj: string] } };
      };
    }>(`/api/shopify/getActiveProducts`, 'POST', { product_ids: [product_idsObj] });

    // console.log("activeProdsResp: ", activeProdsResp)
    let activeIds: any[] = [];
    activeIds = activeProdsResp.activeProducts.map((pi: { id: any; }) => { return { id: Number(pi.id.replace("gid://shopify/Product/", "")) } });

    let filteredCart: any[] = [];
    cart.forEach(element => {
      let t = activeIds.filter((v) => { return v.id == element.id });
      // console.log(t)
      if (t.length > 0) {
        filteredCart.push(element);
      }
    });
    // console.log(filteredCart, activeIds, cart);
    setCartProducts(filteredCart);
    return filteredCart;
  };

  const removeFromCart = (
    productId: number,
    size: number,
    title: string,
    shouldNotify?: boolean
  ) => {
    const cartString = localStorage.getItem('cart');
    let cart: { id: number; size: number; variant: ProductVariant }[] = [];
    if (cartString) {
      cart = JSON.parse(cartString);
    }
    cart = cart.filter((c) => !(c.id === productId && c.size === size));

    localStorage.setItem('cart', JSON.stringify(cart));
    setProductsInCart(
      productsInCart.filter((p) => cart.map((c) => c.id).includes(p.id))
    );
    setCartProducts(cart);
    shouldNotify && notify(`Removed ${title} from cart`);
  };

  const getShopifyProducts = async () => {
    const resp = await makeRequest<{
      products?: { body?: { data?: { products: ShopifyProductsResponse } } };
    }>(`/api/shopify/products`);

    if (resp?.products?.body?.data?.products?.edges?.length) {
      const productResp: Product[] =
        resp.products.body.data.products.edges.flatMap((r) => {
          // console.log({ node: r.node });
          const ids = r.node.id?.split('/');
          return {
            id: +((ids?.length && ids[ids.length - 1]) || 0),
            // title: r.node.title,
            featuredImage: { url: r.node.featuredImage.url },
            // id: +(r.node.id.split() || 0),
            title: r.node.title,
            img: r.node.images.nodes[0].url,
            tags: r.node.tags,
            collections: r.node.collections,
            productType: r.node.productType,
            metafields: r.node.metafields,
            images: r.node.images.nodes.map((im) => im.url),
            description: r.node.description,
            price: 0,
            variants: r.node.variants.nodes.map((v) => {
              const variantsPathArr = `${v.id}`.split('/');
              return {
                id: +(
                  (variantsPathArr?.length &&
                    variantsPathArr[variantsPathArr.length - 1]) ||
                  0
                ),
                price: +(v.price || 0),
              };
            }),
          };
        });

      const cart = await getCartProductIds();
      const products = productResp.filter((p) =>
        cart.map((c) => c.id).includes(p.id)
      );

      const uniqueCart = uniqBy(cart, (c) => [c.id, c.size].join());
      console.log({ uniqueCart });
      setProductsInCart(
        compact(
          uniqueCart.map((uc) => {
            const prod = products.find((p) => p.id === uc.id);
            if (!prod) {
              return undefined;
            }
            console.log({ uc });
            return { ...prod, size: uc.size || 1 };
          })
        )
      );
      // setProductsInCart(
      //   products.map((p) => {
      //     const c = cart.find((ca) => ca.id === p.id);
      //     return { ...p, size: c?.size || 1 };
      //   })
      // );
    }
  };

  const createCheckout = async () => {
    const shippingStr = localStorage.getItem('shipping-address');
    const shippingAddress = shippingStr ? JSON.parse(shippingStr) : undefined;
    const cart = await getCartProductIds();
    // console.log("checkcout cart: ", cart)
    let _customerEmail = String(localStorage.getItem("email"));
    console.log("checkcout _customerEmail: ", _customerEmail)
    const payload: CheckoutCreateInput = {
      variants: uniqBy(
        cartProducts.map((cp) => {
          const quantity = cartProducts.filter(
            (cp2) => cp2.variant === cp.variant
          ).length;
          return { ...cp.variant, quantity };
        }),
        (cp) => cp.id
      ),
      shippingAddress,
      customerEmail: _customerEmail
    };

    const resp = await makeRequest<{
      checkout?: {
        body?: { data?: { checkoutCreate: ShopifyCheckoutCreateResponse } };
      };
    }>(`/api/shopify/checkout/create`, 'POST', payload);

    const checkout = resp.checkout?.body?.data?.checkoutCreate.checkout;
    const checkoutUrl = checkout?.webUrl;
    if (checkoutUrl) {
      localStorage.setItem('checkout-id', checkout.id);
      // window.open(checkoutUrl, '_blank');
      window.location.href = checkoutUrl;
    }
  };

  React.useEffect(() => {
    getShopifyProducts();
  }, []);

  console.log({ cartProducts, productsInCart });
  const total = productsInCart.reduce((a, b) => {
    const quantity = cartProducts.filter(
      (cp) => cp.id === b.id && cp.size === b.size
    ).length;
    console.log({ b, quantity });
    const size =
      cartProducts.find((cp) => cp.id === b.id && cp.size === b.size)?.size ??
      0;
    const currentIndex = size > 0 ? size - 1 : 0;

    return a + (b.variants[currentIndex]?.price || 0) * quantity;
  }, 0);

  return (
    <Container>
      <LeftColumn>
        <h2>SHOPPING BAG</h2>
        <div style={{ marginTop: 50 }} />
        {productsInCart
          .filter(
            (product) =>
              cartProducts.filter(
                (c) => c.id === product.id && c.size === product.size
              ).length
          )
          .sort((a, b) =>
            a.title.toLowerCase().localeCompare(b.title.toLowerCase())
          )
          .map((product, idx) => {
            return (
              <CartItem
                product={product}
                onRemove={(notify) => {
                  removeFromCart(
                    product.id,
                    product?.size ?? 1,
                    product.title,
                    notify
                  );
                  onCartChange();
                }}
                size={product.size || 1}
                quantity={
                  cartProducts.filter(
                    (c) => c.id === product.id && c.size === product.size
                  ).length
                }
                showHeader={idx === 0}
                showSubtotals={
                  productsInCart.filter(
                    (product) =>
                      cartProducts.filter(
                        (c) => c.id === product.id && c.size === product.size
                      ).length
                  ).length -
                    1 ===
                  idx
                }
                total={total}
                onUpdateSize={(size) => {
                  const cart = cartProducts;
                  const thisCart = cart.filter(
                    (c) => c.id === product.id && c.size === product.size
                  );
                  if (!thisCart?.length) return;

                  const newCart = [
                    ...cart.filter(
                      (c) => !(c.id === product.id && c.size === product.size)
                    ),
                    ...thisCart.map((tc) => ({ ...tc, size })),
                  ];

                  setProductsInCart((v) => [
                    ...v.filter(
                      (p) => !(p.id === product.id && p.size === product.size)
                    ),
                    { ...product, size },
                  ]);
                  setCartProducts(newCart);
                  localStorage.setItem('cart', JSON.stringify(newCart));
                }}
                onUpdateQuantity={(quantity) => {
                  const thisCart = cartProducts.find(
                    (c) => c.id === product.id && c.size === product.size
                  );
                  if (!thisCart) return;
                  const newQuantity = [...Array(quantity)].map((_) => thisCart);
                  const newCart = [
                    ...cartProducts.filter(
                      (c) => !(c.id === product.id && c.size === product.size)
                    ),
                    ...newQuantity,
                  ];
                  setCartProducts(newCart);
                  localStorage.setItem('cart', JSON.stringify(newCart));
                  onCartChange();
                }}
              />
            );
          })}
        {productsInCart.length === 0 ? (
          <>
            <Column>No products in cart</Column>

            <div style={{ height: 100, width: '100%' }} />
          </>
        ) : (
          <div />
        )}
        {productsInCart.length === 0 ? (
          <div />
        ) : (
          <CheckoutButton onClick={createCheckout} className={'mobile'}>
            PROCEED TO CHECKOUT
          </CheckoutButton>
        )}
      </LeftColumn>

      <RightColumn>
        {localStorage.getItem('token') ? (
          <>
            <h2>LOGGED IN AS</h2>
            <div style={{ marginTop: 30 }} />
            <p>{localStorage.getItem('email')}</p>
            <div style={{ marginTop: 10 }} />
            <div style={{ display: 'inline-block' }}>
              Not your accounts?{' '}
              <a href={'/account'} style={{ color: 'black' }}>
                Sign in as another user.
              </a>
            </div>
          </>
        ) : (
          <>
            <h2>NOT LOGGED IN</h2>
            <div style={{ marginTop: 10 }} />
            <div style={{ display: 'inline-block' }}>
              <a href={'/account'} style={{ color: 'black' }}>
                Sign in
              </a>
            </div>
          </>
        )}
        {productsInCart.length === 0 ? (
          <div />
        ) : (
          <CheckoutButton onClick={createCheckout} className={'desktop'}>
            PROCEED TO CHECKOUT
          </CheckoutButton>
        )}
      </RightColumn>
    </Container>
  );
};

const CheckoutButton = styled(Button)`
  margin-top: 30px;
  cursor: pointer;
  @media (min-width: 700px) {
    width: 23vw;
  }
  &.mobile {
    width: 100%;
    @media (min-width: 700px) {
      display: none;
    }
  }
  &.desktop {
    @media (max-width: 700px) {
      display: none;
    }
  }
`;

const Container = styled.div`
  display: flex;
  padding: 20px 150px;
  font-size: 12px;
  @media (min-width: 700px) {
    font-size: 0.7vw;
  }
  margin-bottom: 50px;

  @media (max-width: 700px) {
    font-size: 12px;
  }

  & h2 {
    font-size: 26px;
    @media (min-width: 700px) {
      font-size: 1.6vw;
    }
    font-weight: 700;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 18px;
    @media (min-width: 700px) {
      font-size: 1.2vw;
    }
  }

  & h5 {
    font-weight: 700;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & h6 {
    font-weight: 500;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & .multiple-tfs {
    display: flex;
    justify-content: space-between;
    width: 400px;
    flex: 1;
  }
  & .multiple-tfs input {
    width: calc((300px / 3) - 20px);
  }

  @media (max-width: 1200px) {
    padding: 20px 50px;
  }
  @media (max-width: 1000px) {
    padding: 20px 5px;
  }
`;

const LeftColumn = styled(Column)`
  alignitems: flex-start;
  flex: 3;
  padding-left: 30px;
  padding-right: 30px;

  @media (min-width: 700px) {
    align-items: flex-start;
  }
`;
const RightColumn = styled(Column)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 50px;
  padding-top: 0px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

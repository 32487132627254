import React from 'react';
import styled, { CSSProperties } from 'styled-components';

export const Textfield: React.FC<{
  value: string;
  autoComplete?: string;
  onChange: (value: string) => void;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  color?: 'gray' | 'white';
  placeholder?: string;
  readonly?: boolean;
  isSecure?: boolean;
  onClick?: () => void;
  onEnter?: () => void;
}> = ({
  value,
  onChange,
  inputStyle,
  autoComplete,
  color = 'gray',
  style = {},
  placeholder,
  readonly = false,
  onClick,
  isSecure = false,
  onEnter,
}) => {
  return (
    <Container className='textfield-wrapper'>
      <InputContainer color={color} style={style} className={'input-container'}>
        <input
          autoComplete={autoComplete}
          placeholder={placeholder || ''}
          value={value}
          style={inputStyle}
          onChange={(e) => onChange(e.target.value)}
          readOnly={readonly}
          onClick={() => onClick && onClick()}
          type={isSecure ? 'password' : 'text'}
          onKeyUp={(e) => e.key === 'Enter' && onEnter && onEnter()}
        />
      </InputContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;

  & input {
    border: 0px;
    background: transparent;
    width: 100%;
    height: 100%;
    text-align: center;
    transform: rotate(-180deg);
    font-size: 12px;
    @media (min-width: 700px) {
      font-size: 0.7vw;
    }
  }

  @media (max-width: 700px) {
    & input {
      font-size: 12px;
    }
  }
`;

const InputContainer = styled.div<{ color: 'white' | 'gray' }>`
  flex: 1;
  min-height: 30px;
  ${({ color }) =>
    color === 'gray'
      ? `background: linear-gradient(
        315deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #f0f1f5;
    background-blend-mode: soft-light, normal;
    box-shadow: 
    inset 2.17989px 2.17989px 2.17989px rgba(255, 255, 255, 0.7),
      inset -3.26984px -3.26984px 2.17989px rgba(174, 174, 192, 0.2);
    border-radius: 8.71957px;
    transform: rotate(-180deg);
    height: 40px;
    @media(min-width: 700px){
    height: 2.5vw;}`
      : `
        background: linear-gradient(315deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%), #F0F1F5;
background-blend-mode: soft-light, normal;
box-shadow: inset 2.17989px 2.17989px 2.17989px rgba(255, 255, 255, 0.7), inset -3.26984px -3.26984px 2.17989px rgba(174, 174, 192, 0.2);
border-radius: 8.71957px;
transform: rotate(-180deg);
    height: 40px;
    @media(min-width: 700px){
    height: 2.5vw;
    }`}
`;

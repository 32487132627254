import React from 'react';
import { ProductItem } from '../components/ProductItem';
import { Textfield } from '../components/Textfield';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { ProductVariant as ShopifyProductVariant } from 'shopify-buy';
import axios from 'axios';
import { makeRequest } from '../hooks/Resource';
import { ProductVariant } from './ProductDetail';
import { SearchSection } from '../components/SearchSection';
import { useParams } from 'react-router-dom';

export const Search: React.FC<{ onCartChange: () => void }> = ({
  onCartChange,
}) => {
  const { searchText } = useParams<{ searchText: string }>();
  return <SearchSection onCartChange={onCartChange} searchText={searchText} />;
};

import styled, { CSSProperties } from 'styled-components';
import Select from 'react-select';

export interface CartCreateInput {
  variants: {
    id: number;
    quantity: number;
  }[];
}
export interface MailingAddress {
  address1: string;
  address2: string;
  city: string;
  company: string;
  country: string;
  firstName: string;
  lastName: string;
  phone: string;
  province: string;
  zip: string;
}
export interface CheckoutCreateInput {
  variants: {
    id: number;
    quantity: number;
  }[];
  shippingAddress?: MailingAddress;
  customerEmail:string;
}

export interface ShopifyCheckoutCreateResponse {
  checkout: {
    id: string;
    webUrl: string;
  };
}

export const productItemBtnState = `
  width: 70px;
  @media (min-width: 1200px) {
    width: 90px;
  }
  @media (min-width: 1800px) {
    width: 120px;
  }
`;
export const activeBtnState = `color: var(--primary);

background: linear-gradient(
    315deg,
    rgba(255, 255, 255, 0),
    0% rgba(0, 0, 0, 0.6) 100%
  ),
  #f0f1f5;

box-shadow: 2.66667px 2.66667px 8px #ffffff,
  -4px -4px 8px rgba(174, 174, 192, 0.4),
  inset 0px 0px 0px 0.533333px rgba(255, 255, 255, 0.2);`;

export const contactStyle = `
.select__control .select__single-value {
  font-weight: 500;
  @media (min-width: 700px) {
    font-size: 0.7vw;
  }
}
 #react-select-3-input {
  position: absolute;
}
.select__control .select__value-container {
  margin-left: 0px;
  margin-right: 0px;
}
> .select__control .select__indicators {
  min-width: 40px;
  margin: 0 !important;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  justify-content: center;
  & select__dropdown-indicator {
    margin: 0 !important;
    padding: 0 !important;
  }
  // align-items:center;
}
`;

export const DropDown: React.FC<{
  title: string;
  isContact?: boolean;
  style?: CSSProperties;
  width?: string;
  noStyle?: boolean;
  isProductItem?: boolean;
  options?: { label: string; value: string | number }[];
  onChange: (val: string) => void;
  value?: object;
}> = ({
  title,
  style = {},
  width,
  isContact = false,
  isProductItem = false,
  onChange,
  options = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
  ],
  noStyle = false,
  value,
}) => {

  return (
    <StyledSelect
      isContact={isContact}
      isProductItem={isProductItem}
      dropdownWidth={width}
      className={`basic-single`}
      classNamePrefix='select'
      name='color'
      isSearchable={false}
      placeholder={title}
      options={options}
      onChange={(e: any) => onChange(e.value)}
      value={value}
      isOptionDisabled={(option: any) => !option.isDisabled}
    />
    // <Button style={{ paddingLeft: 10, paddingRight: 10, ...style }}>
    //   {title}
    //   <img
    //     alt={''}
    //     src={require('../images/arrow-down.svg').default}
    //     style={{ marginLeft: 15 }}
    //   />
    // </Button>
  );
};
export const NoStyleDropDown: React.FC<{
  title: string;
  style?: CSSProperties;
  onChange: (val: string) => void;
}> = ({ title, style = {}, onChange }) => {
  return (
    <NoStyleSelect
      classNamePrefix='select'
      isSearchable={false}
      placeholder={title}
      options={[
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
      ]}
      onChange={(e: any) => onChange(e.value)}
    />
    // <Button style={{ paddingLeft: 10, paddingRight: 10, ...style }}>
    //   {title}
    //   <img
    //     alt={''}
    //     src={require('../images/arrow-down.svg').default}
    //     style={{ marginLeft: 15 }}
    //   />
    // </Button>
  );
};

const NoStyleSelect = styled(Select)``;

const StyledSelect = styled(Select)<{
  active?: boolean;
  dropdownWidth?: string;
  isContact?: boolean;
  isProductItem?: boolean;
}>`
  width: 100%;
  width: ${({ dropdownWidth }) => (dropdownWidth ? dropdownWidth : '100%')};
  @media (min-width: 700px) {
    width: 5.4vw;
    width: ${({ dropdownWidth }) => (dropdownWidth ? dropdownWidth : '5.4vw')};
  }
  @media (min-width: 700px) {
    height: 2.8vw;
  }
  margin-right: 10px;
  min-height: 0px;
  min-height: 30px;

  & .select__option {
    background: transparent;
  }
  & .select__menu-list {
    background: #eeeff3;
  }

  & > .select__control {
    gap: 2px;
    width: 382px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    width: ${({ dropdownWidth }) => (dropdownWidth ? dropdownWidth : '100%')};
    height: 40px;
    font-size: 11px;
    @media (min-width: 700px) {
      height: 2.8vw;
      font-size: 0.85vw;
    }
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    min-height: 0px;
    min-height: 30px;

    background: linear-gradient(
        315deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #f0f1f5;
    background-blend-mode: soft-light, normal;

    border: 0;
    /* Up/40-64 */
    box-shadow: -2.66667px -2.66667px 8px #ffffff,
      4px 4px 8px rgba(174, 174, 192, 0.4),
      inset 0px 0px 0px 0.533333px rgba(255, 255, 255, 0.2);

    border-radius: 8px;
  }
  & > .select__control:hover {
    box-shadow: -2.66667px -2.66667px 8px rgba(109, 93, 252, 0.4),
      4px 4px 8px rgba(174, 174, 192, 0.4),
      inset 0px 0px 0px 0.533333px rgba(255, 255, 255, 0.2);
  }

  & > .select__control:hover {
    background: var(--primary);
  }
  & > .select__control:hover .select__single-value {
    color: white;
  }
  & > .select__control .select__placeholder {
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
  }
  & > .select__control:hover .select__placeholder {
    color: white;
  }
  & > .select__control:hover svg {
    color: white;
  }
  & .select__option {
    color: black;
    text-align: center;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.9vw;
    }
  }
  & .select__option:hover {
    color: white;
    background: var(--primary);
  }
  & > .select__control .select__value-container {
    z-index: 1;
    // min-width: 40px;
    padding: 0;
    margin: 0;
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
    overflow: visible;
  }
  & > .select__control .select__indicator-separator {
    display: none;
  }
  & > .select__control .select__indicators {
    margin-right: 10px;
    min-width: 1vw;
    margin: 0;
    padding: 0;
  }

  &:hover {
    // color: var(--primary);
    // color: white;
  }

  transition: all 0.3s ease-in-out;

  ${({ isContact }) => (isContact ? contactStyle : ``)}
  ${({ active }) => (active ? activeBtnState : ``)}
  ${({ isProductItem }) => (isProductItem ? productItemBtnState : ``)}

  &:active {
    ${activeBtnState}
  }
  .select__option.select__option--is-disabled {
    cursor: not-allowed;
    color: #aaa !important;
    &:hover {
      background-color: transparent;
    }
  }
`;

export const Button = styled.button<{ active?: boolean }>`
  all: unset;
  width: 382px;
  @media (min-width: 700px) {
    width: 26vw;
  }
  height: 40px;
  @media (min-width: 700px) {
    height: 2.8vw;
  }
  font-size: 14px;
  @media (min-width: 700px) {
    font-size: 0.85vw;
  }
  min-height: 30px;
  @media (max-width: 1200px) {
    font-size: 11px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;

  background: linear-gradient(
      315deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #f0f1f5;
  background-blend-mode: soft-light, normal;

  /* Up/40-64 */
  box-shadow: -2.66667px -2.66667px 8px #ffffff,
    4px 4px 8px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.533333px rgba(255, 255, 255, 0.2);
  border-radius: 8px;

  &:hover {
    // color: var(--primary);
    color: var(--light);
    background: var(--primary);
    box-shadow: -2.66667px -2.66667px 8px rgba(109, 93, 252, 0.4),
      4px 4px 8px rgba(174, 174, 192, 0.4),
      inset 0px 0px 0px 0.533333px rgba(255, 255, 255, 0.2);
    // box-shadow: -2.66667px -2.66667px 8px #ffffff,
    //   4px 4px 8px rgba(109, 93, 252, 0.4),
    //   inset 0px 0px 0px 0.533333px rgba(109, 93, 252, 0.2);
  }

  transition: all 0.3s ease-in-out;

  ${({ active }) => (active ? activeBtnState : ``)}

  &:active {
    ${activeBtnState}
  }
  &:disabled {
    &:hover {
      background-color: #fff;
    }
  }
`;

import { compact, includes, last, uniqBy } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import FadeIn from 'react-fade-in';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import { Login } from '../components/Login';
import { MobileAccountNav } from '../components/MobileAccountNav';
import { notify } from '../components/ProductItem';
import { ProfileSideNav } from '../components/profile/ProfileSideNav';
import { Textfield } from '../components/Textfield';
import { Column, TextfieldContainer } from '../helper/Common';
import { makeRequest } from '../hooks/Resource';
import { hasTextLength, hasValue } from '../utils/conditions';
import { Button } from '../utils/Constants';
import { Address, BLANK_ADDRESS, Customer, ProfileInput } from './Profile';

export const Addresses: React.FC = (props: any) => {
  const location: { state: any } = useLocation();

  const [loggedIn, setLoggedIn] = React.useState<boolean>(
    !!localStorage.getItem('token')
  );
  const [userFetched, setUserFetched] = React.useState<boolean>(false);
  const [deleteAddressIds, setDeleteAddressIds] = React.useState<string[]>([]);
  const [defaultAddId, setdefaultAddId] = React.useState<string>('');
  const [shippingIdx, setShippingIdx] = React.useState<number>(-1);
  const [billingIdx, setBillingIdx] = React.useState<number>(1);
  const [showSaveButton2, setSaveButton2] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<number>(0);
  const [values, setValues] = React.useState<ProfileInput>({
    firstName: '',
    lastName: '',
    email: '',
    primaryAddressId: '',
    shippingAddress: '',
    shippingAddress2: '',
    fullName: '',
    addr1: '',
    addr2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    shippingName: '',

    billingAddressId: '',
    billingAddress: '',
    billingFullName: '',
    billingAddr1: '',
    billingAddr2: '',
    billingCity: '',
    billingState: '',
    billingZip: '',
    billingPhone: '',
    billingName: '',

    addresses: [],
    defaultAddressId: '',
  });

  const addNewAddress = () =>
    setValues((v) => ({
      ...v,
      addresses: [...values.addresses, BLANK_ADDRESS],
    }));

  const getCustomer = async () => {
    if (userFetched) return;
    setUserFetched(true);
    const accessToken = localStorage.getItem('token');
    const resp = await makeRequest<{
      user?: { body?: { data?: { customer?: Customer } } };
    }>(`/api/shopify/user/${accessToken}`);
    const customer = resp.user?.body?.data?.customer;

    if (!customer) {
      // localStorage.removeItem('token');
      // setLoggedIn(false);
      return;
    }

    const addresses: Partial<Address>[] = customer.addresses.nodes ?? [];
    const shippingId = localStorage.getItem('shipping-id');
    const idx = addresses.findIndex((a) => {
      return a.id?.split('?')[0] === shippingId;
    });

    const shipping = customer.defaultAddress;
    localStorage.setItem(
      'shipping-address',
      JSON.stringify(shipping)
    );
    //console.log('addresses', customer);
    const defaultAddressGID = customer?.defaultAddress?.id;

    if (defaultAddressGID) {
      setdefaultAddId(defaultAddressGID);
    }
    setShippingIdx(idx);

    setValues({
      ...values,
      // ...shippingInput,
      // ...billingInput,
      email: customer.email,
      firstName: customer.firstName,
      lastName: customer.lastName,
      phone: customer.phone,
      addresses,
    });

    if (
      location?.state?.fromProfile &&
      !location?.state?.hasAddress &&
      !values.addresses.length
    )
      addNewAddress();
  };

  React.useEffect(() => {
    loggedIn && getCustomer();
  }, [loggedIn, refresh]);
  React.useEffect(() => {
    deleteAddressIds.length && onUpdate();
  }, [deleteAddressIds]);
  loggedIn && getCustomer();

  const onUpdate = async () => {
      try {
        const accessToken = localStorage.getItem('token');
        const addressNodes = values.addresses?.map((item) => ({
          ...item,
          name: `${item.firstName} ${item.lastName}`,
        }));

        const payload: Omit<Customer, 'id' | 'addresses'> & {
          addresses: { nodes: Partial<Address>[] };
        } = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          addresses: {
            nodes: addressNodes,
          },
        };

        console.log(payload);

        const resp = await makeRequest<{
          update?: {
            body?: {
              data?: {
                customerUpdate?: {
                  customer?: Customer;
                  customerUserErrors?: { message?: string }[];
                };
              };
            };
          };
        }>(`/api/shopify/user/update`, 'POST', {
          accessToken,
          customer: payload,
          deleteAddressIds,
          defaultAddressId: defaultAddId || '',
        });
        const errors =
          resp.update?.body?.data?.customerUpdate?.customerUserErrors;
        if (errors?.length) {
          notify(errors[0].message ?? 'Something went wrong');
          return;
        }
        const customerResp = resp.update?.body?.data?.customerUpdate?.customer;
        if (!customerResp) {
          notify('Something went wrong.');
          return;
        }
        notify('Profile saved');

        // await getCustomer();

        setUserFetched(false);
        setValues({
          ...values,
          addresses: customerResp.addresses.nodes,
          // ...shippingInput,
          // ...billingInput,
          // addresses: newVal.filter((nv, idx) => ![0, 1].includes(idx)),
        });
        getCustomer();

      } catch (e) {
        console.log({ e });
      }

  };

  if (!window) {
    return <div />;
  }

  const showTopButton = includes(
    values.addresses.map((item) => item.id),
    ''
  );
  const setSaveButton = (value: any) => {
    setSaveButton2((v) => {
      if (value) return value;
      if (showTopButton) return true;
      return value;
    });
  };
  const showSaveAddressButton = showSaveButton2 || showTopButton;

  return (
    <Container>
      <LeftColumn>{loggedIn && <ProfileSideNav />}</LeftColumn>
      <Content key={`submitted${refresh}`}>
        <FadeIn>
          <h2 style={{ marginTop: 15 }}>PROFILE</h2>
          <MobileAccountNav />
          <HeaderColumn style={{ alignItems: 'flex-start' }}>
            <h5 style={{ textAlign: 'center', width: '100%' }}>
              EDIT YOUR PERSONAL INFORMATION
            </h5>
          </HeaderColumn>

          <div style={{ marginTop: 40 }} />
          {values.addresses.map((item, index) => {
            const isBlank = false;
            if (!isBlank && item.id === '') return <Fragment />;
            if (isBlank && item.id !== '') return <Fragment />;
            const onChange = (key: string, value: string | number) => {
              console.log('values', values);

              const addresses = values.addresses;
              setValues((v) => ({
                ...v,
                addresses: [
                  ...addresses.slice(0, index),
                  { ...item, [key]: value },
                  ...addresses.slice(index + 1),
                ],
              }));
            };
            const onRemove = async () => {
              if (
                !window?.confirm(
                  'Are you sure you want to remove this address?'
                )
              ) {
                return;
              }
              const addresses = values.addresses;
              await setValues((v) => ({
                ...v,
                addresses: [
                  ...addresses.slice(0, index),
                  ...addresses.slice(index + 1),
                ],
              }));

              !!item?.id && setDeleteAddressIds((v) => compact([item.id]));
            };
            return (
              <AddressSection
                addrIdx={index}
                addr={item}
                onChange={onChange}
                onRemove={onRemove}
                showSaveButton={showSaveButton2}
                setSaveButton={setSaveButton}
                onUpdate={onUpdate}
                showRemove
                showEdit={item.id !== ''}
                defaultToEditing={item.id === ''}
                isShipping={shippingIdx === index}
                isBilling={false}
                onShippingChange={(checked) =>
                  setShippingIdx(checked ? index : -1)
                }
                onBillingChange={() => {}}
                setdefaultAddId={setdefaultAddId}
                defaultAddId={defaultAddId}
              />
            );
          })}
          {values.addresses.map((item, index) => {
            const isBlank = true;
            if (!isBlank && item.id === '') return <Fragment />;
            if (isBlank && item.id !== '') return <Fragment />;
            const onChange = (key: string, value: string | number) => {
              const addresses = values.addresses;
              setValues((v) => ({
                ...v,
                addresses: [
                  ...addresses.slice(0, index),
                  { ...item, [key]: value },
                  ...addresses.slice(index + 1),
                ],
              }));
            };
            const onRemove = async () => {
              if (
                !window?.confirm(
                  'Are you sure you want to remove this address?'
                )
              ) {
                return;
              }
              const addresses = values.addresses;
              await setValues((v) => ({
                ...v,
                addresses: [
                  ...addresses.slice(0, index),
                  ...addresses.slice(index + 1),
                ],
              }));
              await setDeleteAddressIds((v) => {
                console.log('avi', compact([item.id]));

                return compact([item.id]);
              });
              !!item?.id && onUpdate();
            };
            return (
              <AddressSection
                addrIdx={index}
                addr={item}
                onChange={onChange}
                onRemove={onRemove}
                showSaveButton={showSaveButton2}
                setSaveButton={setSaveButton}
                onUpdate={onUpdate}
                showRemove
                showEdit={item.id !== ''}
                defaultToEditing={item.id === ''}
                isShipping={shippingIdx === index}
                isBilling={false}
                onShippingChange={(checked) =>
                  setShippingIdx(checked ? index : -1)
                }
                onBillingChange={() => {}}
                setdefaultAddId={setdefaultAddId}
                defaultAddId={defaultAddId}
              />
            );
          })}

          {showSaveAddressButton && (
            <SaveButton
              id={'save-btn'}
              onClick={async () => {
                await onUpdate();
                await setRefresh((v) => v + 1);
                const shipping = values.addresses[shippingIdx];
                localStorage.setItem(
                  'shipping-address',
                  JSON.stringify(shipping)
                );
              }}
            >
              SAVE ADDRESS
            </SaveButton>
          )}

          {!showTopButton && (
            <div
              style={{
                alignItems: 'center',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{
                  width: 'auto',
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontWeight: 400,
                  marginBottom: 5,
                  marginTop: 30,
                }}
                onClick={addNewAddress}
              >
                ADD ADDRESS
              </Button>
            </div>
          )}
          <div style={{ marginTop: 30 }} />
          <u
            style={{
              fontSize: 12,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              localStorage.removeItem('token');
              localStorage.removeItem('email');
              window.location.reload();
            }}
          >
            Sign out
          </u>
          <div style={{ marginTop: 100 }} />
        </FadeIn>
      </Content>
      <RightColumn></RightColumn>
    </Container>
  );
};

const AddressSection: React.FC<{
  addr: Partial<Address>;
  onChange: (key: string, value: string | number) => void;
  onRemove?: () => void;
  addrIdx: number;
  onUpdate: () => Promise<void>;
  defaultToEditing?: boolean;
  showRemove?: boolean;
  showEdit?: boolean;
  showSaveButton?: boolean;
  isShipping: boolean;
  isBilling: boolean;
  onShippingChange: (checked: boolean) => void;
  onBillingChange: (checked: boolean) => void;
  setSaveButton: (checked: boolean) => void;
  setdefaultAddId: (value: string) => void;
  defaultAddId: string;
}> = ({
  addr,
  onChange,
  onRemove,
  addrIdx,
  onUpdate,
  defaultToEditing = false,
  showRemove = false,
  showEdit = false,
  isShipping,
  isBilling,
  onShippingChange,
  showSaveButton,
  onBillingChange,
  setSaveButton,
  setdefaultAddId,
  defaultAddId,
}) => {
  const textString = (str: any) => hasTextLength(str) && str;
  const [editing, setEditing] = React.useState<boolean>(defaultToEditing);

  const handleChangeDefaultAddress = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    console.log('id', id);

    if (e.target.checked && id) {
      setdefaultAddId(id);
      return;
    }
    setdefaultAddId('');
  };
  return (
    <>
      <div
        id={`address-${addrIdx}`}
        style={{
          display: 'flex',
          marginTop: 30,
          alignItems: 'center',
          width: '100%',
        }}
      >
        <h6 style={{ flex: 1 }}>{`ADDRESS ${addrIdx + 1}`}</h6>
        <div style={{ flex: 1 }} />

        {showEdit && (
          <u
            style={{ fontSize: 10, cursor: 'pointer' }}
            onClick={() => {
              setEditing((v) => {
                setSaveButton(!v);
                return !v;
              });
            }}
          >
            EDIT
          </u>
        )}
        {showRemove && (
          <>
            {showEdit && '/'}
            <u
              style={{ fontSize: 10, cursor: 'pointer' }}
              onClick={() => onRemove && onRemove()}
            >
              REMOVE
            </u>
          </>
        )}
      </div>
      {!editing ? (
        <>
          <div>{textString(addr.name)}</div>
          <div>{textString(addr.address1)}</div>
          {hasValue(addr.address2) && <div>{textString(addr.address2)}</div>}
          <div>
            {hasTextLength(addr.city) ? addr.city + ',' : ''}
            {textString(addr.provinceCode)}
            {textString(addr.zip)}
          </div>

          <div>{textString(addr.phone)}</div>
          {isShipping && (
            <div style={{ marginTop: 5, fontWeight: 'bold' }}>
              Default Shipping Address
            </div>
          )}
        </>
      ) : (
        <>
          <TextfieldContainer>
            First Name
            <Textfield
              value={addr.firstName ?? ''}
              onChange={(firstName) => onChange('firstName', firstName)}
              color={'white'}
              onEnter={onUpdate}
            />
          </TextfieldContainer>
          <TextfieldContainer>
            Last Name
            <Textfield
              value={addr.lastName ?? ''}
              onChange={(lastName) => onChange('lastName', lastName)}
              color={'white'}
              onEnter={onUpdate}
            />
          </TextfieldContainer>
          <TextfieldContainer>
            Address 1
            <Textfield
              value={addr.address1 ?? ''}
              onChange={(address1) => onChange('address1', address1)}
              color={'white'}
              onEnter={onUpdate}
            />
          </TextfieldContainer>
          <TextfieldContainer>
            Address 2
            <Textfield
              value={addr.address2 ?? ''}
              onChange={(address2) => onChange('address2', address2)}
              color={'white'}
              onEnter={onUpdate}
            />
          </TextfieldContainer>
          <div className='multiple-tfs'>
            <TextfieldContainer>
              City
              <Textfield
                value={addr.city ?? ''}
                onChange={(city) => onChange('city', city)}
                color={'white'}
                style={{ width: 'auto' }}
                inputStyle={{ width: '100%' }}
                onEnter={onUpdate}
              />
            </TextfieldContainer>
            <TextfieldContainer>
              State
              <Textfield
                inputStyle={{ width: '100%' }}
                value={addr.provinceCode ?? ''}
                onChange={(provinceCode) => {
                  onChange('provinceCode', provinceCode);
                }}
                color={'white'}
                style={{ width: 'auto' }}
                onEnter={onUpdate}
              />
            </TextfieldContainer>
            <TextfieldContainer>
              Zip
              <Textfield
                inputStyle={{ width: '100%' }}
                value={addr.zip ?? ''}
                onChange={(zip) => onChange('zip', zip)}
                color={'white'}
                style={{ width: 'auto' }}
                onEnter={onUpdate}
              />
            </TextfieldContainer>
          </div>
          <TextfieldContainer>
            Phone Number
            <Textfield
              value={addr.phone ?? ''}
              onChange={(phone) => onChange('phone', phone)}
              color={'white'}
              onEnter={onUpdate}
            />
          </TextfieldContainer>
          <div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
            <label>
                <input
                  type={'checkbox'}
                  checked={addr.id === defaultAddId}
                  value={addr.id ?? ''}
                  onChange={(e) => {
                    handleChangeDefaultAddress(e, addr.id ?? '');
                    // setIsShipping(!isShipping);
                    onShippingChange(!isShipping);
                  }}
                />
                Set as default shipping address
            </label>
          </div>
        </>
      )}
    </>
  );
};

const SaveButton = styled(Button)`
  margin-top: 30px;
  @media (min-width: 700px) {
    width: 23vw;
  }
  &.mobile {
    width: 100%;
    @media (min-width: 700px) {
      display: none;
    }
  }
  &.desktop {
    @media (max-width: 700px) {
      display: none;
    }
  }
`;

const Container = styled.div`
  display: flex;
  & h2 {
    font-size: 20px;
    @media (min-width: 700px) {
      font-size: 1.6vw;
    }
    // font-size: 5vw;
    font-weight: 700;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
  }

  &.mobile {
    @media (min-width: 700px) {
      display: none;
    }
  }

  & h5 {
    font-weight: 700;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & h6 {
    font-weight: 500;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & .multiple-tfs {
    display: flex;
    justify-content: space-between;
    width: 350px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    @media (min-width: 700px) {
      width: 23vw;
    }
    flex: 1;
  }
  & .multiple-tfs input {
    width: calc((300px / 3) - 20px);
    @media (min-width: 700px) {
      width: calc((22vw / 3) - 20px);
    }
  }
`;

const Content = styled(Column)`
  & > input {
    // text-align: left !important;
    // margin-left: -20px;
  }
  // width: 28vw;
  width: 100%;
  @media (min-width: 700px) {
    width: 22vw;
  }
  font-size: 13px;
  @media (min-width: 700px) {
    font-size: 0.75vw;
  }
`;

const RightColumn = styled(Column)`
  @media (max-width: 700px) {
    display: none;
  }
`;
const LeftColumn = styled(Column)`
  z-index: 1;
  @media (max-width: 700px) {
    display: none;
  }
`;

const HeaderColumn = styled(Column)`
  width: 22vw;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

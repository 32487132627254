import React from 'react';
import styled, { CSSProperties } from 'styled-components';

export const Textarea: React.FC<{
  style?: CSSProperties;
  value: string;
  color?: 'gray' | 'white';
  width?: number | string;
  placeholder?: string;
  onChange: (value: string) => void;
}> = ({
  value,
  color = 'gray',
  style = {},
  placeholder,
  width = '100%',
  onChange,
}) => {
  return (
    <Container className="textarea-wrapper" width={width}>
      <InputContainer width={width} color={color} style={style}>
        <textarea
          value={value}
          placeholder={placeholder || ''}
          onChange={(e) => onChange(e.target.value)}
        />
      </InputContainer>
    </Container>
  );
};

const Container = styled.div<{ width: number | string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (min-width: 700px) {
    width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  }

  & textarea {
    border: 0px;
    width: 100%;
    @media (min-width: 700px) {
      width: ${({ width }) =>
        typeof width === 'number' ? `${width}px` : width};
    }
    background: transparent;
    height: 100%;
    text-align: left;
    transform: rotate(-180deg);
    font-size: 12px;
    @media (min-width: 700px) {
      font-size: 0.7vw;
    }
    padding-top: 10px;
    padding-left: 10px;
    width: calc(100% - 5px);
    box-sizing: border-box;
  }

  @media (max-width: 700px) {
    & textarea {
      font-size: 12px;
    }
  }
`;

const InputContainer = styled.div<{
  color: 'white' | 'gray';
  width: number | string;
}>`
  width: 100%;
  @media (min-width: 700px) {
    width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  }
  ${({ color }) =>
    color === 'gray'
      ? `background: linear-gradient(
        315deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #f0f1f5;
    background-blend-mode: soft-light, normal;
    box-shadow: 
    inset 2.17989px 2.17989px 2.17989px rgba(255, 255, 255, 0.7),
      inset -3.26984px -3.26984px 2.17989px rgba(174, 174, 192, 0.2);
    border-radius: 8.71957px;
    transform: rotate(-180deg);
    height: 200px;`
      : `
        background: linear-gradient(315deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%), #F0F1F5;
background-blend-mode: soft-light, normal;
box-shadow: inset 2.17989px 2.17989px 2.17989px rgba(255, 255, 255, 0.7), inset -3.26984px -3.26984px 2.17989px rgba(174, 174, 192, 0.2);
border-radius: 8.71957px;
transform: rotate(-180deg);
    height: 200px;`}
`;

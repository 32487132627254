import React, { ReactElement } from 'react';
import FadeIn from 'react-fade-in';
import styled, { CSSProperties } from 'styled-components';
import { Button } from '../utils/Constants';
import { Textfield } from './Textfield';

interface ModalProps {
  visible: boolean;
  onClose: () => void;
  size?: 'normal' | 'large';
  children?: ReactElement;
  style?: CSSProperties;
}
export const Modal: React.FC<ModalProps> = ({
  visible,
  onClose,
  children,
  size = 'normal',
  style = {},
}) => {
  const mobileModal = (
    <ModalContainer
      size={size}
      className={'mobile'}
      onClick={(e) => e.stopPropagation()}
      style={{
        transition: '0.3s all ease-in-out',
        opacity: +visible,
        ...style,
      }}
    >
      <CloseBtn onClick={() => onClose()}>X</CloseBtn>
      {children}
    </ModalContainer>
  );
  const desktopModal = (
    <StyledFade
      className={'desktop'}
      childClassName={'inner'}
      visible={visible}
      delay={100}
    >
      <ModalContainer
        size={size}
        onClick={(e) => e.stopPropagation()}
        style={style}
      >
        <CloseBtn onClick={() => onClose()}>X</CloseBtn>
        {children}
      </ModalContainer>
    </StyledFade>
  );
  return (
    <Overlay
      style={{ display: visible ? 'flex' : 'none' }}
      onClick={() => onClose()}
    >
      <div
        style={{
          display: 'flex',
          position: 'relative',
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {mobileModal}
        {desktopModal}
      </div>
    </Overlay>
  );
};

const StyledFade = styled(FadeIn)`
  // height: 100%;
  // display: flex;
  // align-items: center;
  // max-height: 100%;
  // overflow-y: scroll;
  // border-radius: 15px;
  // width: 100%;
  // max-width: 100%;
  // margin: auto;

  // @media (max-width: 700px) {
  //   max-height: 100%;
  //   // max-width: 90%;
  //   border-radius: 0px;
  // }

  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  @media (max-width: 700px) {
    min-height: 100%;
  }
  left: 0;
  top: 0;
  background: rgba(39, 40, 50, 0.25);
  display: flex;
  z-index: 5;
  justify-content: center;
  align-items: center;

  & .mobile {
    @media (min-width: 700px) {
      display: none;
    }
  }
  & .desktop {
    height: 100%;
    display: flex;
    align-items: center;
    max-height: 100%;
    overflow-y: scroll;
    border-radius: 15px;
    width: 100%;
    max-width: 100%;
    margin: auto;
    justify-content: center;

    @media (max-width: 700px) {
      max-height: 100%;
      // max-width: 90%;
      border-radius: 0px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    @media (max-width: 700px) {
      display: none;
    }
  }
  & .desktop .inner {
    border-radius: 15px;
    max-height: 90%;
    overflow-y: scroll;
  }
`;

const CloseBtn = styled.div`
  position: sticky;
  margin-right: 30px;
  top: 30px;
  text-align: right;
  cursor: pointer;

  @media (max-width: 700px) {
    right: 0;
    position: absolute;
    font-size: 14px;
  }

  &:hover {
    color: white;
  }
`;

const ModalContainer = styled.div<{ size: 'normal' | 'large' }>`
  // width: ${({ size }) => (size === 'large' ? 600 : 500)}px;
  width: ${({ size }) => (size === 'large' ? '41vw' : '31vw')};
  max-width: ${({ size }) => (size === 'large' ? `41vw` : '31vw')};
  font-size: 1vw;
  display: flex;
  position: relative;
  // min-height: 400px;
  background: #f0f1f5;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  justify-content: center;
  flex-direction: column;
  overflow-y: scroll;
  @media (min-width: 700px) {
    overflow-y: visible;
  }
  max-height: ${({ size }) => (size === 'large' ? 800 : 600)}px;
  height: 90%;
  max-width: 100%;

  @media (max-width: 700px) {
    width: calc(100% - 30px);
    width: calc(100% - 0px);
    max-width: 90%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    // margin: auto;
    padding: 0px;
    border-radius: 0px;
    position: fixed;
    top: 0;
  }

  @media (min-width: 700px) {
    max-height: 90%;
    border-radius: 15px;
  }
`;

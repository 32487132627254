import React from 'react';
import { useNavigate } from 'react-router';
import styled, { CSSProperties } from 'styled-components';
import FadeIn from 'react-fade-in';
import { useOnScreen } from '../hooks/useOnScreen';
import { Button } from '../utils/Constants';
import { Column } from '../helper/Common';
import { ProductMinimal } from '../pages/Home';
import { OrderItem } from '../pages/OrderHistory';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

interface OrderHistoryItemProps {
  product: OrderItem;
  style?: CSSProperties;
  showHeader?: boolean;
}
export const OrderHistoryItem: React.FC<OrderHistoryItemProps> = ({
  product,
  showHeader,
  style = {},
}) => {
  const nav = useNavigate();
  const [params, setParams] = useSearchParams();

  const IMG_WIDTH = 100;

  const ref = React.useRef<HTMLDivElement | null>(null);
  const isOnScreen = useOnScreen(ref);

  return (
    <FadeContainer visible={isOnScreen} delay={200}>
      <Container ref={ref} style={style}>
        {showHeader && (
          <div className="header-row desktop-only">
            <StyledColumn
              style={{
                width: IMG_WIDTH,
                flex: 4,
              }}
            >
              ORDER
            </StyledColumn>
            <StyledColumn className={'order-date'}>ORDER DATE</StyledColumn>
          </div>
        )}
        <Divider className="desktop-only" />
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            marginTop: 20,
          }}
        >
          <StyledColumn className={'left'} style={{ flex: 4, minWidth: 400 }}>
            <div className={'order-num'}>{product.orderNumber}</div>
            <Image
              width={IMG_WIDTH}
              src={product.lineItems.nodes[0]?.variant?.image.url}
              // onClick={() => nav(`/product/${product.id}/${product.title}`)}
            />
            <div style={{ flex: 1, marginTop: 20 }} />
            {/* <a href={product.customerUrl}>VIEW DETAILS</a> */}
            <u
              onClick={() => {
                setParams({ order: `${product.orderNumber}` });
              }}
            >
              VIEW DETAILS
            </u>
          </StyledColumn>
          <StyledColumn className={'order-date right'} style={{ minWidth: 80 }}>
            {format(new Date(product.processedAt), 'MMM d, yyyy')}
            <div style={{ flex: 1 }} />
            {/* <a href={product.statusUrl}>TRACK ORDER</a> */}
          </StyledColumn>
        </div>
      </Container>
    </FadeContainer>
  );
};

const FadeContainer = styled(FadeIn)`
  width: 100%;
`;

const Divider = styled.div`
  margin-bottom: 10px;
  width: 100%;
  height: 3px;
  background: var(--light-blue);
  margin-top: 10px;
`;

const StyledColumn = styled(Column)`
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0px;
  font-size: 13px;
  @media (min-width: 700px) {
    font-size: 0.75vw;
  }

  @media (max-width: 700px) {
    &.left {
      align-items: center;
    }
    &.right {
      align-items: center;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;

  & .header-row {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
  }

  & .order-date {
    align-items: flex-end;
    @media (max-width: 700px) {
      align-items: center;
    }
  }

  & .desktop-only {
    @media (max-width: 700px) {
      display: none;
    }
  }

  & u {
    cursor: pointer;
  }

  & p {
    font-weight: 400;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & a {
    color: black;
  }

  & .order-num {
    @media (max-width: 700px) {
      font-size: 5vw;
    }
  }

  & h5 {
    font-weight: 700;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  @media (max-width: 700px) {
    margin-top: 10vw;
    margin-bottom: 20vw;
  }
`;

const Image = styled.img`
  height: auto;
  object-fit: contain;
  @media (min-width: 700px) {
    padding-right: 50px;
  }
  @media (max-width: 700px) {
    height: auto;
    width: 90%;
  }
`;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SideNav } from '../../helper/Common';
import { useTopOfScreen } from '../../hooks/useTopOfScreen';

export const ProfileSideNav: React.FC = () => {
  const navigate = useNavigate();

  return (
    <StyledSideNav transparent={true}>
      <div
        onClick={() => navigate('/account')}
        style={{
          fontWeight: window.location.pathname.includes('account')
            ? 'bold'
            : 'normal',
        }}
      >
        Profile
      </div>
      {/* <div
        onClick={() => navigate('/payment-method')}
        style={{
          fontWeight: window.location.pathname.includes('payment-method')
            ? 'bold'
            : 'normal',
        }}
      >
        Payment Method
      </div> */}
      <div
        onClick={() => navigate('/addresses')}
        style={{
          fontWeight: window.location.pathname.includes('addresses')
            ? 'bold'
            : 'normal',
        }}
      >
        Addresses
      </div>
      <div
        onClick={() => navigate('/order-history')}
        style={{
          fontWeight: window.location.pathname.includes('order-history')
            ? 'bold'
            : 'normal',
        }}
      >
        Order History
      </div>
      <div
        onClick={() => navigate('/wish-list')}
        style={{
          fontWeight: window.location.pathname.includes('wish-list')
            ? 'bold'
            : 'normal',
        }}
      >
        Wishlist
      </div>
    </StyledSideNav>
  );
};

const StyledSideNav = styled(SideNav)`
  @media (max-width: 700px) {
    display: none;
  }
  & div:hover {
    color: #666;
  }
`;

import React from 'react';

export const useTopOfScreen = (): [boolean] => {
  const [y, setY] = React.useState(window.scrollY);
  const [isAtTopOfPage, setIsAtTopOfPage] = React.useState<boolean>(true);

  const handleNavigation = React.useCallback(
    (e: any) => {
      const window = e.currentTarget || y;
      if (window.scrollY < 10) {
        !isAtTopOfPage && setIsAtTopOfPage(true);
      } else {
        isAtTopOfPage && setIsAtTopOfPage(false);
      }
      setY(window.scrollY);
    },
    [y]
  );

  React.useEffect(() => {    
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  return [isAtTopOfPage];
};

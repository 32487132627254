import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SizeChartSection } from '../components/SizeChartSection';
import { SideNav } from '../helper/Common';
import { makeRequest } from '../hooks/Resource';

export const About: React.FC = () => {
  const [aboutHtml, setAboutHtml] = React.useState<string>('');

  const [path, setPath] = React.useState<string>('');

  const location: { pathname: any } = useLocation();

  React.useEffect(() => {
    if (path && path === location.pathname) {
      getContent();
    }
    setPath(location.pathname);
  }, [location.pathname, path]);

  const getContent = async () => {
    const aboutResp = await makeRequest<{
      page?: { body?: { data?: { page: { body: string } } } };
    }>(`/api/shopify/page/faq`);

    if (aboutResp?.page?.body?.data?.page?.body) {
      setAboutHtml(aboutResp.page.body.data.page.body);
    }
  };

  if (!document) return <div />;

  return (
    <Container>
      <LeftColumn>
        <StyledSideNav style={{ top: 200 }}></StyledSideNav>
      </LeftColumn>
      <Column>
        <SizeChartSection showMaster />
        <div style={{ marginTop: 40 }} />
        <h3 id={'privacy'}>FAQ</h3>
        <div dangerouslySetInnerHTML={{ __html: aboutHtml }} />
        <div style={{ marginTop: 50 }} />
      </Column>
      <RightColumn></RightColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  & h3 {
    font-weight: 700;
    font-size: 18px;
    @media (min-width: 700px) {
      font-size: 1.2vw;
    }
    margin: 0;
    margin-bottom: 10px;
    @media (max-width: 700px) {
      font-size: 18px;
    }
  }

  & p {
    font-size: 12px;
    @media (min-width: 700px) {
      font-size: 0.7vw;
    }
    margin: 0;
    margin-top: 10px;
    @media (max-width: 700px) {
      font-size: 12px;
    }
  }

  @media (max-width: 700px) {
    & p {
      font-size: 12px;
    }
  }
`;

const StyledSideNav = styled(SideNav)`
  font-size: 12px;
  font-size: 0.7vw;

  @media (max-width: 700px) {
    font-size: 12px;
  }

  & div {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  // max-width: 600px;
  margin-top: 50px;
  max-width: 34vw;
  position: relative;

  @media (max-width: 700px) {
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
  }
`;

const RightColumn = styled(Column)`
  @media (max-width: 700px) {
    display: none;
  }
`;

const LeftColumn = styled(Column)`
  @media (max-width: 700px) {
    display: none;
  }
`;

import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useSearchParams,
} from 'react-router-dom';
import { Home } from './pages/Home';
import { Navbar } from './components/Navbar';
import { Countdown } from './pages/Countdown';
import { Footer } from './components/Footer';
import { ProductDetail } from './pages/ProductDetail';
import { Privacy } from './pages/Privacy';
import { Profile } from './pages/Profile';
// @ts-ignore
import WebGLFluid from 'webgl-fluid';
import { Wishlist } from './pages/Wishlist';
import { OrderHistory } from './pages/OrderHistory';
import { Atelier } from './pages/Atelier';
import { Contact } from './pages/Contact';
import { Cart } from './pages/Cart';
import { About } from './pages/About';
import { MobileNavbar } from './components/MobileNavbar';
import ScrollToTop from './components/ScrollToTop';
import { Toaster } from 'react-hot-toast';
import React, { useEffect } from 'react';
import { Search } from './pages/Search';
import { WelcomePreview } from './components/WelcomePreview';
import styled from 'styled-components';
import { Addresses } from './pages/Addresses';
import { ResetPassword } from './pages/reset-password';
import { useViewport } from './hooks/useViewport';

function App() {
  const [refreshCart, setRefreshCart] = React.useState<number>(0);
  // const [searchParams] = useSearchParams();
  // const searchText = searchParams.get('search');
  const [hasSearchText, setHasSearchText] = React.useState<boolean>(
    window.location.search.includes('?search=')
  );

  const { width } = useViewport();
  const breakpoint = 699;

  return (
    <Router>
      <Outer className={'outer-root'}>
        <ScrollToTop>
          {window.location.pathname !== '/' && (
            <>
              {width < breakpoint ? (
                <MobileNavbar
                  refresh={refreshCart}
                  onSearchChange={(searchText) =>
                    setHasSearchText(!!searchText)
                  }
                />
              ) : (
                <Navbar
                  refresh={refreshCart}
                  onSearchChange={(searchText) =>
                    setHasSearchText(!!searchText)
                  }
                />
              )}

              <Toaster containerStyle={{ textAlign: 'center', fontSize: 12 }} />
            </>
          )}

          {!hasSearchText && (
            <Routes>
              <Route
                path={'/home'}
                element={
                  <Home onCartChange={() => setRefreshCart((v) => v + 1)} />
                }
              />
              <Route path={'/countdown'} element={<Home onCartChange={() => setRefreshCart((v) => v + 1)} />} />
              <Route
                path='/product/:productType/:productId/:title'
                element={
                  <ProductDetail
                    onCartChange={() => setRefreshCart((v) => v + 1)}
                  />
                }
              />
              <Route path='/' element={<WelcomePreview />} />
              <Route path='/info' element={<Privacy />} />
              <Route path='/account' element={<Profile />} />
              <Route path='/addresses' element={<Addresses />} />
              <Route path='/reset-password' element={<ResetPassword />} />
              <Route path='/about' element={<About />} />
              <Route
                path='/search/:searchText'
                element={
                  <Search onCartChange={() => setRefreshCart((v) => v + 1)} />
                }
              />
              <Route
                path='/collection/:searchText'
                element={
                  <Search onCartChange={() => setRefreshCart((v) => v + 1)} />
                }
              />
              <Route path='/wish-list' element={<Wishlist />} />
              <Route path='/order-history' element={<OrderHistory />} />
              <Route path='/atelier' element={<Atelier />} />
              <Route path='/contact' element={<Contact />} />
              <Route
                path='/cart'
                element={
                  <Cart onCartChange={() => setRefreshCart((v) => v + 1)} />
                }
              />
            </Routes>
          )}

          {window.location.pathname !== '/' && <Footer />}
        </ScrollToTop>
      </Outer>
    </Router>
  );
}

export default App;

const Outer = styled.div`
  @media (max-width: 700px) {
    overflow-y: hidden;
  }
`;

import React, { useEffect } from 'react';
import FadeIn from 'react-fade-in';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import styled from 'styled-components';
import { makeRequest } from '../hooks/Resource';
import { useTopOfScreen } from '../hooks/useTopOfScreen';
import { ProductVariant } from '../pages/ProductDetail';
import { Button } from '../utils/Constants';
import { CartPreview } from './CartPreview';
import { MenuButton } from './MenuButton';
import { Modal } from './Modal';
import { SearchSection } from './SearchSection';
import { Textfield } from './Textfield';
import { useCookies } from 'react-cookie';
import { elementRoles } from 'aria-query';

export const MobileNavbar: React.FC<{
  refresh: number;
  onSearchChange: (searchText: string) => void;
}> = ({ refresh, onSearchChange }) => {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [menuVisible, setMenuVisible] = React.useState<boolean>(false);
  const [showSocial, setShowSocial] = React.useState<boolean>(false);
  const [showInfo, setShowInfo] = React.useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get('search') ?? '';
  const [isAtTopOfPage] = useTopOfScreen();
  const [cartItems, setCartItems] = React.useState<
    { id: number; size: number; variant: ProductVariant }[]
  >([]);
  const [submenuVisible, setSubmenuVisible] = React.useState<boolean>(false);
  const [menu, setMenu] = React.useState<{ title: string; url: string }[]>([]);
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);
  const [cartPreviewVisible, setCartPreviewVisible] =
    React.useState<boolean>(false);
  const [firstLoad, setFirstLoad] = React.useState<boolean>(false);
  const [path, setPath] = React.useState<string>('');
  const [cookies, setCookie] = useCookies();
  const countdownCookie = cookies.Countdown;
  const location: { pathname: any } = useLocation();

  const getContent = async () => {
    const aboutMeta = await makeRequest<{
      products?: { body?: { data?: { timerDate: any } } };
    }>(`/api/shopify/countdown/metafield`);
    console.log( aboutMeta );
    if (aboutMeta?.products?.body?.data?.timerDate[1].value !== 'false') {
      nav('/countdown');
    }
  };

  useEffect(() => {
    if (countdownCookie) {
      return;
    }
    getContent();
  }, []);

  React.useEffect(() => {
    const isReturningVisitor = localStorage.getItem('is-returning-visitor');
    if (isReturningVisitor !== '1') {
      localStorage.setItem('is-returning-visitor', '1');
      setTimeout(() => {
        setModalVisible(true);
      }, 2000);
    }
  }, []);

  React.useEffect(() => {
    onSearchChange(searchText);
  }, [searchText]);

  const getShopifyMenu = async () => {
    const resp = await makeRequest<{
      menu: {
        body: { data: { menu: { items: { title: string; url: string }[] } } };
      };
    }>(`/api/shopify/menu`);

    // console.log({ resp });
    setMenu(resp.menu.body.data.menu.items);
  };

  const getCartProductIds = async () => {
    const cartString = localStorage.getItem('cart');
    let cart: { id: number; size: number; variant: ProductVariant }[] = [];
    if (cartString) {
      cart = JSON.parse(cartString);
    }
    let product_idsObj = cart.map((pi) => { return `"gid://shopify/Product/${pi.id}"` }).join();
    // console.log(product_idsObj)
    const activeProdsResp = await makeRequest<{
      [x: string]: any;
      menu: {
        body: { data: { product_ids: [product_idsObj: string] } };
      };
    }>(`/api/shopify/getActiveProducts`, 'POST', { product_ids: [product_idsObj] });

    console.log("activeProdsResp: ", activeProdsResp)
    let activeIds: any[] = [];
    activeIds = activeProdsResp.activeProducts.map((pi: { id: any; }) => { return { id: Number(pi.id.replace("gid://shopify/Product/", "")) } });

    let filteredCart: any[] = [];
    cart.forEach(element => {
      let t = activeIds.filter((v)=>{return v.id==element.id});
      // console.log(t)
      if(t.length>0){
        filteredCart.push(element);
      }
    });
    // console.log(filteredCart, activeIds, cart);
    setCartItems(filteredCart);
    return filteredCart;
  };

  const refreshCart = async () => {
    if (!firstLoad) {
      setFirstLoad(true);
      return;
    }

    const oldItems = [...cartItems];
    const items = await getCartProductIds();
    if (
      items.length > 0 &&
      items.length > 0 &&
      items.length !== oldItems.length &&
      window.location.pathname !== '/cart'
    ) {
      setCartPreviewVisible(true);
      setTimeout(() => setCartPreviewVisible(false), 4000);
    }
    getShopifyMenu();
  };

  React.useEffect(() => {
    if (path && path === location.pathname) {
      return;
    }

    getShopifyMenu();
    if (location.pathname !== '/home') {
      getCartProductIds();
    }
  }, [location.pathname, path]);

  React.useEffect(() => {
    let cartData = localStorage.getItem('cart');
    if (cartData) {
      cartData = JSON.parse(cartData);
    }

    if (cartData && cartData.length > 0) {
      getCartProductIds();
    }
  }, [localStorage.getItem('cart')]);

  React.useEffect(() => {
    refreshCart();
  }, [refresh]);

  const nav = useNavigate();

  return (
    <Container>
      <div style={{ width: '100%', height: 120 }}></div>
      <div
        className='menu-parent'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 3,
          backgroundImage:
            'linear-gradient( to bottom, rgba(238,239,243,1), rgba(238,239,243,1) )',
        }}
      >
        <Content
          onKeyUp={(e) => {
            if (e.code === 'Space') {
              setModalVisible(true);
            }
          }}
        >
          <div
            style={{
              transition: 'all 0.3s ease-in-out',
              position: 'absolute',
              left: 0,
            }}
          >
            <MenuButton
              active={menuVisible}
              onClick={(v) => {
                setMenuVisible(v);
                setShowSocial(false);
                setShowInfo(false);
              }}
            />

            {/* <img
            alt={'cart'}
            src={require(`../images/cart-icon.svg`).default}
            style={{ cursor: 'pointer' }}
          /> */}
          </div>
          <Column>
            <Link to={'/home'}>
              <img
                alt={'carton'}
                src={require('../images/carton-logo.svg').default}
                height={30}
                style={{ marginTop: 20, marginBottom: 20 }}
              />
            </Link>

            {/* <Textfield
            color={'white'}
            style={{ marginTop: 0, width: 320 }}
            placeholder={'SEARCH'}
            value={searchText}
            onChange={setSearchText}
          /> */}
          </Column>
          {cartItems.length ? (
            <Link
              to={'/cart'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                nav('/cart');
              }}
              style={{ position: 'absolute', top: 29, right: 5 }}
            >
              <img
                alt={'cart'}
                src={require(`../images/cart-icon.svg`).default}
                style={{ cursor: 'pointer' }}
              />
              <Badge>{cartItems.length || ''}</Badge>
            </Link>
          ) : (
            <div />
          )}

          {/* <Modal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          style={{ padding: 100 }}
        >
          <>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and
            </p>
            <Textfield style={{ marginTop: 20, width: '100%' }} />
            <Button style={{ marginTop: 20, width: '100%' }}>ENTER</Button>
          </>
        </Modal> */}
          {/* <BackgroundContainer>
          <WhiteBackground />
          <WhiteGradient />
        </BackgroundContainer> */}
          {
            <MenuContainer visible={menuVisible}>
              <CloseBtn
                className='close-btn'
                onClick={() => setMenuVisible(false)}
              >
                X
              </CloseBtn>
              <Column
                style={{
                  alignItems: 'flex-start',
                  transition: '0.3s all ease-in-out',
                }}
              >
                <Link
                  to={'/home'}
                  onClick={(e) => {
                    setMenuVisible(false);
                    setShowSocial(false);
                    setShowInfo(false);
                  }}
                >
                  <img
                    alt={'carton'}
                    src={require('../images/carton-logo.svg').default}
                    height={30}
                    style={{ marginTop: 20, marginBottom: 20 }}
                  />
                </Link>

                <div style={{ marginTop: '8vw' }} />

                <Link
                  to={'/home'}
                  className={'page-btn'}
                  onClick={(e) => {
                    e.preventDefault();
                    // setMenuVisible(false);
                    // setShowSocial(false);
                    setSubmenuVisible((v) => !v);
                  }}
                >
                  SHOP
                </Link>
                <FadeIn visible={submenuVisible}>
                  <div
                    className={`submenu ${
                      submenuVisible ? 'visible' : 'hidden'
                    }`}
                    style={{ cursor: submenuVisible ? 'pointer' : 'none' }}
                  >
                    {menu.map((m) => (
                      <>
                        <a
                          href={m.url}
                          onClick={(e) => {
                            e.preventDefault();
                            if (!submenuVisible) return;
                            setMenuVisible(false);
                            setShowSocial(false);
                            setShowInfo(false);
                            window.location.href = m.url;
                          }}
                          style={{
                            cursor: submenuVisible ? 'pointer' : 'none',
                          }}
                        >
                          {m.title.toUpperCase()}
                        </a>
                      </>
                    ))}
                    <a
                      href={'/home'}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!submenuVisible) return;
                        setMenuVisible(false);
                        setShowSocial(false);
                        setShowInfo(false);
                        window.location.href = '/home';
                      }}
                      style={{ cursor: submenuVisible ? 'pointer' : 'none' }}
                    >
                      SHOP ALL
                    </a>
                  </div>
                </FadeIn>
                <Link
                  to={'/account'}
                  className={'page-btn'}
                  onClick={() => {
                    setMenuVisible(false);
                    setShowSocial(false);
                    setShowInfo(false);
                  }}
                >
                  ACCOUNT
                </Link>
                <Link
                  to={'/atelier'}
                  className={'page-btn'}
                  onClick={() => {
                    setMenuVisible(false);
                    setShowSocial(false);
                    setShowInfo(false);
                  }}
                >
                  ATELIER
                </Link>
                <Link
                  to={'/about'}
                  className={'page-btn'}
                  onClick={() => {
                    setMenuVisible(false);
                    setShowSocial(false);
                    setShowInfo(false);
                  }}
                >
                  ABOUT
                </Link>

                <div style={{ marginTop: 50 }} />
                {showSocial ? (
                  <SubmenuWrapper>
                    <Link
                      to={'/home'}
                      className={'page-btn small'}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowSocial(false);
                        setShowInfo(false);
                      }}
                    >
                      SOCIAL
                    </Link>

                    <a
                      href={
                        'https://twitter.com/cartonouterwear?s=21&t=zAy7MvX85DmyjQmWhLN2Tg'
                      }
                      className={'page-btn small submenu-item'}
                    >
                      Twitter
                    </a>
                    <a
                      href={
                        'https://www.tiktok.com/@carton.shop?is_from_webapp=1&sender_device=pc'
                      }
                      className={'page-btn small submenu-item'}
                    >
                      TikTok
                    </a>
                    <a
                      href={
                        'https://www.youtube.com/channel/UCKIhCmHBPBuCpzVaW4yHfEQ'
                      }
                      className={'page-btn small submenu-item'}
                    >
                      Youtube
                    </a>
                    <a
                      href={
                        'https://instagram.com/_carton.shop_?igshid=YmMyMTA2M2Y='
                      }
                      style={{ marginBottom: 50 }}
                      className={'page-btn small submenu-item'}
                    >
                      Instagram
                    </a>
                    {/* <img
                    alt={'twitter'}
                    src={require('../images/twitter-icon.svg').default}
                    height={30}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  />
                  <img
                    alt={'tiktok'}
                    src={require('../images/tiktok-icon.svg').default}
                    height={30}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  />
                  <img
                    alt={'instagram'}
                    src={require('../images/instagram-icon.svg').default}
                    height={30}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  /> */}
                  </SubmenuWrapper>
                ) : (
                  <Link
                    to={'/home'}
                    className={'page-btn small'}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSocial(true);
                      setShowInfo(false);
                    }}
                  >
                    SOCIAL
                  </Link>
                )}

                {showInfo ? (
                  <SubmenuWrapper>
                    <Link
                      to={'/info'}
                      className={'page-btn small'}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowSocial(false);
                        setShowInfo(false);
                      }}
                    >
                      INFO
                    </Link>
                    <Link
                      to={'/info'}
                      className={'page-btn small submenu-item'}
                      onClick={(e) => {
                        // e.preventDefault();
                        setMenuVisible(false);
                        setShowSocial(false);
                        setShowInfo(false);
                      }}
                      state={{ name: 'shipping-return-policy' }}
                    >
                      shipping
                    </Link>
                    <Link
                      to={'/info'}
                      state={{ name: 'privacy' }}
                      className={'page-btn small submenu-item'}
                      onClick={(e) => {
                        // e.preventDefault();
                        setMenuVisible(false);
                        setShowSocial(false);
                        setShowInfo(false);
                      }}
                    >
                      privacy
                    </Link>
                    <Link
                      to={'/info'}
                      className={'page-btn small submenu-item'}
                      state={{ name: 'terms' }}
                      onClick={(e) => {
                        // e.preventDefault();
                        setMenuVisible(false);
                        setShowSocial(false);
                        setShowInfo(false);
                      }}
                    >
                      terms
                    </Link>
                  </SubmenuWrapper>
                ) : (
                  <Link
                    to={'/info'}
                    className={'page-btn small'}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSocial(false);
                      setShowInfo(true);
                    }}
                  >
                    INFO
                  </Link>
                )}

                <Link
                  to={'/contact'}
                  className={'page-btn small'}
                  onClick={() => {
                    setMenuVisible(false);
                    setShowSocial(false);
                    setShowInfo(false);
                  }}
                >
                  CONTACT
                </Link>
              </Column>
            </MenuContainer>
          }
          <BackgroundContainer>
            <WhiteBackground />
            <WhiteGradient />
          </BackgroundContainer>
        </Content>
      </div>
      <SearchWrapper>
        <Textfield
          color={'white'}
          style={{ marginTop: 0, width: 320, maxWidth: 320 }}
          placeholder={'SEARCH'}
          value={searchText}
          autoComplete='search'
          onChange={(text) => {
            if (!text) {
              searchParams.delete('search');
              setSearchParams(searchParams, { replace: true });
              return;
            }
            setSearchParams({ search: text }, { replace: true });
          }}
        />
      </SearchWrapper>
      <>
        <CartPreview
          cartItems={cartItems}
          visible={cartPreviewVisible}
          onChange={setCartPreviewVisible}
        />
      </>
      {searchText && (
        <SearchSection
          menu={menu}
          searchText={searchText}
          onCartChange={refreshCart}
        />
      )}
    </Container>
  );
};

const Badge = styled.div`
  display: flex;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: black;
  color: white;
  font-size: 6px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  top: 0;
  right: -5px;
`;

const SubmenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  margin-bottom: 10px;
  flex-direction: column;

  & a {
    text-transform: uppercase;
    margin-left: 10px;
    &.submenu-item {
      margin-left: 25px;
    }
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  margin-top: -40px;
  z-index: 1;
`;

const Container = styled.div`
  @media (min-width: 700px) {
    display: none;
  }
  overflow-y: scroll;

  & .submenu {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    transition: 0.3s all ease-in-out;
  }
  & .submenu.hidden {
    max-height: 1px;
  }
  & .submenu.visible {
    max-height: 100%;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  z-index: 2;
  max-width: 320px;
  margin: auto;
  right: 0;

  & img:hover {
    color: var(--primary);
  }
`;

const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  left: 0;
  top: 0;
  z-index: -1;
`;
const WhiteBackground = styled.div`
  width: 100%;
  height: 105%;
  background-image: linear-gradient(
    to bottom,
    rgba(238, 239, 243, 1),
    rgba(238, 239, 243, 1)
  );
`;

const CloseBtn = styled.div`
  position: fixed;
  top: 33px;
  right: 20px;
  text-align: right;
  cursor: pointer;
  font-size: 22px;

  &:hover {
    color: white;
  }
`;

const WhiteGradient = styled.div`
  height: 50%;
  background-image: linear-gradient(
    to bottom,
    rgba(238, 239, 243, 1),
    rgba(238, 239, 243, 0)
  );
`;

const PagesRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & .page-btn {
    padding-left: 25px;
    padding-right: 25px;
    font-size: 12px;
    text-decoration: none;
    color: black;
    font-weight: 500;
  }

  & a:hover {
    color: var(--primary);
  }
`;

const MenuContainer = styled.div<{ visible: boolean }>`
  display: flex;
  position: fixed;
  top: 0;
  left: ${({ visible }) => (visible ? 0 : `-130%`)};
  width: 100%;
  height: 100%;
  background: white;
  z-index: 5;
  transition: all 0.3s ease-in-out;

  padding: 5px 30px;

  & .close-btn {
    transition: all 0.3s ease-in-out;
    opacity: ${({ visible }) => +visible};
  }

  & a {
    color: black;
    text-decoration: none;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  & a.small {
    font-size: 12px;
  }
`;

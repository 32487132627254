import React from 'react';
import FadeIn from 'react-fade-in';
import styled from 'styled-components';
import { MobileAccountNav } from '../components/MobileAccountNav';
import { notify } from '../components/ProductItem';
import { ProfileSideNav } from '../components/profile/ProfileSideNav';
import { Textfield } from '../components/Textfield';
import { Column, TextfieldContainer } from '../helper/Common';
import { makeRequest } from '../hooks/Resource';
import { Button } from '../utils/Constants';
import { Customer, ResetPasswordInput } from './Profile';

export const ResetPassword: React.FC = () => {
  const initialValues = {
    // old_password: '',
    new_password: '',
    email:''
    // repeat_new_password: '',
  };
  const [loggedIn, setLoggedIn] = React.useState<boolean>(
    !!localStorage.getItem('token')
  );
  const [userFetched, setUserFetched] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<number>(0);
  const [values, setValues] = React.useState<ResetPasswordInput>(initialValues);


  const getCustomer = async () => {
    if (userFetched) return;
    setUserFetched(true);
    const accessToken = localStorage.getItem('token');
    const resp = await makeRequest<{
      user?: { body?: { data?: { customer?: Customer } } };
    }>(`/api/shopify/user/${accessToken}`);
    const customer = resp.user?.body?.data?.customer;
    const shipping = customer?.defaultAddress;
    localStorage.setItem(
      'shipping-address',
      JSON.stringify(shipping)
    );
    if (!customer) {
      // localStorage.removeItem('token');
      // setLoggedIn(false);
      return;
    }

    setValues({
      ...values,
      email: customer.email
    });
  };
  getCustomer();
  const onChange = (field: string, value: any): any => {
    setValues({ ...values, [field]: value });
  };

  const onSubmit = async () => {
    const accessToken = localStorage.getItem('token');
    const {
      email,
      //  old_password, repeat_new_password
    } = values;

    // if (repeat_new_password !== new_password)
    //   return notify('New password and repeat new password are not same');

    const payload = {
      // old_password,
      email,
    };

    const resp = await makeRequest<{
      update?: {
        body?: {
          data?: {
            customerRecover?: {
              customerUserErrors?: { message?: string }[];
            };
          };
        };
      };
    }>(`/api/shopify/user/reset`, 'POST', {email});
    const errors = resp.update?.body?.data?.customerRecover?.customerUserErrors;
    if (errors?.length) {
      notify(errors[0].message ?? 'Something went wrong');
      return;
    }
    const customerResp = resp.update?.body?.data?.customerRecover?.customerUserErrors;
    console.log(customerResp)
    if (!customerResp) {
      notify('Something went wrong.');
      return;
    }
    setValues(initialValues);
    notify(`Please check your email for a link to reset your password`);
    setRefresh((v) => v + 1);
  };

  const onResetPassword = async () => {
    const {
      email,
      //  old_password, repeat_new_password
    } = values;

    const resp = await makeRequest<{
      reset?: {
        body?: {
          data?: {
            customerRecover?: {
              customerUserErrors?: { message?: string }[];
            };
          };
        };
      };
    }>(`/api/shopify/user/reset`, 'POST', { email });

    const errors = resp.reset?.body?.data?.customerRecover?.customerUserErrors;
    console.log(errors)
    if (errors?.length) {
      notify(errors[0].message ?? 'Something went wrong');
      return;
    }
    notify(`Please check your email for a link to reset your password`);
    setRefresh((v) => v + 1);
  };
  if (!window) {
    return <div />;
  }

  return (
    <Container>
      <LeftColumn>{loggedIn && <ProfileSideNav />}</LeftColumn>
      <Content key={`submitted${refresh}`}>
        <FadeIn>
          <h2 style={{ marginTop: 15 }}>Reset Password</h2>
          <MobileAccountNav />
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            {/* <TextfieldContainer>
              Old Password
              <Textfield
                value={values.old_password ?? ''}
                onChange={(value) => onChange('old_password', value)}
                color={'white'}
              />
            </TextfieldContainer> */}
            <TextfieldContainer style={{ maxWidth: '335px' }}>
              {/* New Password */}
              Email address
              <Textfield
                value={values.email ?? ''}
                onChange={(value) => onChange('email', value)}
                color={'white'}
                onEnter={onResetPassword}
              />
            </TextfieldContainer>
            {/* <TextfieldContainer>
              Repeat New Password
              <Textfield
                value={values.repeat_new_password ?? ''}
                onChange={(value) => onChange('repeat_new_password', value)}
                color={'white'}
              />
            </TextfieldContainer> */}
          </div>

          <div
            className='border'
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Button
              style={{
                marginLeft: 20,
                marginRight: 20,
                fontWeight: 400,
                marginBottom: 20,
                marginTop: 30,
                maxWidth: '335px',
              }}
              onClick={onResetPassword}
            >
              Reset Password
            </Button>
          </div>

          <u
            style={{
              fontSize: 12,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              localStorage.removeItem('token');
              localStorage.removeItem('email');
              window.location.reload();
            }}
          >
            Sign out
          </u>
          <div style={{ marginTop: 100 }} />
        </FadeIn>
      </Content>
      <RightColumn></RightColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;

  & h2 {
    font-size: 20px;
    @media (min-width: 700px) {
      font-size: 1.6vw;
    }
    // font-size: 5vw;
    font-weight: 700;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
  }

  &.mobile {
    @media (min-width: 700px) {
      display: none;
    }
  }

  & h5 {
    font-weight: 700;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & h6 {
    font-weight: 500;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & .multiple-tfs {
    display: flex;
    justify-content: space-between;
    width: 350px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    @media (min-width: 700px) {
      width: 22vw;
    }
    flex: 1;
  }
  & .multiple-tfs input {
    width: calc((300px / 3) - 20px);
    @media (min-width: 700px) {
      width: calc((22vw / 3) - 20px);
    }
  }
`;

const Content = styled(Column)`
  & > input {
    // text-align: left !important;
    // margin-left: -20px;
  }
  // width: 28vw;
  width: 100%;
  @media (min-width: 700px) {
    width: 22vw;
  }
`;

const RightColumn = styled(Column)`
  @media (max-width: 700px) {
    display: none;
  }
`;
const LeftColumn = styled(Column)`
  z-index: 1;
  @media (max-width: 700px) {
    display: none;
  }
`;

import React from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Address } from 'shopify-buy';
import styled from 'styled-components';
import { MobileAccountNav } from '../components/MobileAccountNav';
import { OrderHistoryItem } from '../components/OrderHistoryItem';
import { ProfileSideNav } from '../components/profile/ProfileSideNav';
import { Column } from '../helper/Common';
import { makeRequest } from '../hooks/Resource';
import { OrderDetails } from './OrderDetails';

export interface OrderItem {
  name: string;
  orderNumber: number;
  processedAt: string;
  statusUrl: string;
  customerUrl: string;
  id: string;
  totalPrice: number;
  totalShippingPrice: number;
  financialStatus: string;
  fulfillmentStatus: string;
  shippingAddress: Address;
  lineItems: {
    nodes: {
      quantity: number;
      title: string;
      discountedTotalPrice: { amount: number };
      variant: {
        price: number;
        image: {
          url: string;
        };
        currentlyNotInStock?: boolean;
        quantityAvailable?: number;
      };
    }[];
  };
}
export interface OrderHistoryResponse {
  displayName: string;
  addresses: {
    nodes: {
      address1: string;
      address2: string;
      province: string;
      city: string;
      zip: string;
    }[];
  };
  orders: {
    nodes: OrderItem[];
  };
}
export const OrderHistory: React.FC = () => {
  const navigate = useNavigate();

  const [params, setParams] = useSearchParams();

  const orderNumber = params.get('order') ?? '';

  const [products, setProducts] = React.useState<OrderItem[]>([]);
  const [customer, setCustomer] = React.useState<OrderHistoryResponse | null>(
    null
  );

  const getOrders = async () => {
    const accessToken = localStorage.getItem('token');
    const resp = await makeRequest<{
      user?: { body?: { data?: { customer: OrderHistoryResponse } } };
    }>(`/api/shopify/user/${accessToken}/orders`);

    if (resp?.user?.body?.data?.customer?.orders.nodes?.length) {
      const ordersResp: OrderItem[] =
        resp.user.body.data.customer.orders.nodes ?? [];
      setProducts(ordersResp);
      setCustomer(resp.user.body.data.customer);
    }
  };

  React.useEffect(() => {
    getOrders();
  }, []);

  const order = products.find((o) => o.orderNumber === +orderNumber);

  return orderNumber && order && customer ? (
    <OrderDetails order={order} customer={customer} />
  ) : (
    <Container>
      <div className="content">
        <LeftColumn>
          <ProfileSideNav />
        </LeftColumn>
        <MainColumn
          style={{
            flex: 2,
          }}
        >
          <h2 style={{ margin: 'auto', marginTop: 15 }}>ORDER HISTORY</h2>
          <MobileAccountNav />

          <div style={{ marginTop: 25 }} />
          {products.map((product, idx) => {
            return (
              <>
                <OrderHistoryItem product={product} showHeader={idx === 0} />

                {/* 
                <OrderHistoryItem
                  id={product.id}
                  title={product.title}
                  img={product.featuredImage}
                  showHeader
                />
                <OrderHistoryItem
                  id={product.id}
                  title={product.title}
                  img={product.featuredImage}
                  showHeader
                /> */}
              </>
            );
          })}

          {products.length === 0 ? (
            <Column style={{ alignItems: 'center', width: '100%' }}>
              <div style={{ width: '100%', textAlign: 'center' }}>
                No orders
              </div>
            </Column>
          ) : (
            <div />
          )}
        </MainColumn>
        <RightColumn
          style={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        ></RightColumn>
      </div>
    </Container>
  );
};

const Container = styled.div`
  & .content {
    display: flex;
    @media (min-width: 700px) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  & h2 {
    font-size: 20px;
    @media (min-width: 700px) {
      font-size: 1.6vw;
    }
    font-weight: 700;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & h5 {
    font-weight: 700;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & h6 {
    font-weight: 500;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & .multiple-tfs {
    display: flex;
    justify-content: space-between;
    width: 400px;
    flex: 1;
  }
  & .multiple-tfs input {
    width: calc((300px / 3) - 20px);
  }
`;

const MainColumn = styled(Column)`
  align-items: flex-start;
  @media (max-width: 700px) {
    align-items: center;
  }
`;

const RightColumn = styled(Column)`
  @media (max-width: 700px) {
    display: none;
  }
`;
const LeftColumn = styled(Column)`
  @media (max-width: 700px) {
    display: none;
  }
`;

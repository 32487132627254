import { update } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { notify } from '../components/ProductItem';
import { Textarea } from '../components/Textarea';
import { Textfield } from '../components/Textfield';
import {
  Column,
  SideNav,
  TextfieldContainer,
  validateEmail,
} from '../helper/Common';
import { makeRequest } from '../hooks/Resource';
import { Button, DropDown } from '../utils/Constants';

const OPTIONS: any = [
  { label: 'Shipping', value: 'shipping', isDisabled: true },
  { label: 'General', value: 'general', isDisabled: true },
  { label: 'Sizing', value: 'sizing', isDisabled: true },
];

export const Contact: React.FC = () => {
  const navigate = useNavigate();
  const [values, setValues] = React.useState<{
    name: string;
    email: string;
    topic: string;
    comment: string;
  }>({
    name: '',
    email: '',
    topic: '',
    comment: '',
  });
  const [html, setHtml] = React.useState<string>('');
  const [options, setOptions] =
    React.useState<{ label: string; value: string; isDisabled: boolean }[]>(
      OPTIONS
    );

  const submitToKlaviyo = async () => {
    if (!validateEmail(values.email)) {
      return;
    }
    const resp = await makeRequest('/api/klaviyo/contact', 'POST', values);
    setValues({
      name: '',
      email: '',
      topic: '',
      comment: '',
    });
    // console.log({ resp });
    notify('Submitted!');
  };

  const getContent = async () => {
    const resp = await makeRequest<{
      page?: { body?: { data?: { page: { body: string } } } };
    }>(`/api/shopify/page/contact`);
    console.log({ resp });
    if (resp?.page?.body?.data?.page?.body) {
      console.log('setting', resp.page.body.data.page.body);
      setHtml(resp.page.body.data.page.body);
    }
  };
  React.useEffect(() => {
    getContent();
  }, []);

  return (
    <Container>
      <LeftColumn></LeftColumn>
      <Column style={{ margin: 'auto' }} className={'container'}>
        <h2>CONTACT</h2>
        <Column style={{ alignItems: 'flex-start' }} className={'intro'}>
          <p
            style={{ marginTop: -20, textAlign: 'center' }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Column>
        <StyledTextfieldContainer>
          Name
          <Textfield
            value={values.name}
            onChange={(name) => setValues((v) => ({ ...v, name }))}
            color={'white'}
          />
        </StyledTextfieldContainer>
        <StyledTextfieldContainer>
          Email Address
          <Textfield
            value={values.email}
            onChange={(email) => setValues((v) => ({ ...v, email }))}
            color={'white'}
          />
        </StyledTextfieldContainer>
        <StyledTextfieldContainer>
          Topic
          <div style={{ marginTop: 10 }} />
          <StyledDropDown
            style={{
              marginTop: 10,
            }}
            isContact
            width={'100%'}
            title={''}
            onChange={(topic) => {
              setValues((v) => ({ ...v, topic }));
              const updatedOptions = options.map(
                (opt: { value: string; label: any }, i: string | number) => {
                  console.log('topic', topic, opt.value === topic);

                  if (opt.value === topic) {
                    return {
                      label: opt.label,
                      value: opt.value,
                      isDisabled: false,
                    };
                  } else {
                    return {
                      label: opt.label,
                      value: opt.value,
                      isDisabled: true,
                    };
                  }
                }
              );

              setOptions(updatedOptions);
            }}
            // title={`${size ?? 'SIZE'}`}
            // onChange={(val) => {
            //   setSize(+val);
            // }}
            value={options.filter(
              (opt: { value: string }) => opt.value === values.topic
            )}
            options={options}
          />
        </StyledTextfieldContainer>
        <StyledTextfieldContainer>
          <Textarea
            value={values.comment}
            color={'white'}
            width={/*320*/ '20vw'}
            onChange={(comment) => setValues((v) => ({ ...v, comment }))}
          />
        </StyledTextfieldContainer>
        <TopicButton
          style={{ marginTop: 50, marginBottom: 50 }}
          onClick={() => submitToKlaviyo()}
        >
          ENTER
        </TopicButton>
      </Column>
      <RightColumn></RightColumn>
    </Container>
  );
};

const TopicButton = styled(Button)`
  width: 100%;
  @media (min-width: 700px) {
    width: 20vw;
  }
`;
const LeftColumn = styled(Column)`
  @media (max-width: 700px) {
    display: none;
  }
`;
const RightColumn = styled(Column)`
  @media (max-width: 700px) {
    display: none;
  }
`;
const Container = styled.div`
  & h2 {
    font-size: 26px;
    @media (min-width: 700px) {
      font-size: 1.6vw;
    }
    font-weight: 700;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    @media (max-width: 700px) {
      margin-top: 50px;
    }
  }

  & p {
    @media (min-width: 700px) {
      font-size: 0.7vw;
    }
  }

  & h5 {
    font-weight: 700;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & h6 {
    font-weight: 500;
    font-size: 14px;
    @media (min-width: 700px) {
      font-size: 0.85vw;
    }
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & .multiple-tfs {
    display: flex;
    justify-content: space-between;
    width: 350px;
    flex: 1;
  }
  & .multiple-tfs input {
    width: calc((300px / 3) - 20px);
  }

  & .container {
    max-width: calc(100% - 40px);
    @media (min-width: 700px) {
      max-width: 20vw;
    }
  }
  & .intro {
    max-width: calc(100% - 40px);
    @media (min-width: 700px) {
      max-width: 20vw;
    }
  }
`;

const StyledTextfieldContainer = styled(TextfieldContainer)`
  // width: 22vw;
`;

// const TextfieldContainer = styled(Column)`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;

//   & input {
//     width: 350px;
//   }

//   & .textfield-wrapper {
//     margin-top: 15px;
//   }
// `;

const StyledDropDown = styled(DropDown)``;

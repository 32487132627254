import React from 'react';
import { useNavigate } from 'react-router';
import styled, { CSSProperties } from 'styled-components';
import FadeIn from 'react-fade-in';
import { useOnScreen } from '../hooks/useOnScreen';
import { Button } from '../utils/Constants';
import DropDown from 'react-dropdown';
import { notify } from './ProductItem';
import { ProductMinimal } from '../pages/Home';
import { addToCart } from '../helper/Common';

interface WishListItemProps {
  product: ProductMinimal;
  imgWidth?: number | string;
  imgHeight?: number | string;
  style?: CSSProperties;
  onRemove: (hideNotify?: boolean) => void;
  onUpdateSize: (size: number) => void;
  size: number;
}
export const WishListItem: React.FC<WishListItemProps> = ({
  product,
  imgWidth = 400,
  imgHeight = 400,
  style = {},
  onRemove,
  onUpdateSize,
  size,
}) => {
  const nav = useNavigate();

  const ref = React.useRef<HTMLDivElement | null>(null);
  const isOnScreen = useOnScreen(ref);

  const selectedCollectionTitle =
    product.collections.nodes.find(
      (n) =>
        n.handle.toLowerCase() !== 'homepage' &&
        n.handle.toLowerCase() !== 'frontpage'
    )?.handle || 'SHOP';

  return (
    <FadeIn visible={isOnScreen} delay={200}>
      <Container
        divWidth={style.width || 'auto'}
        ref={ref}
        style={{ ...{ ...style, width: undefined } }}
      >
        <Image
          imgHeight={imgHeight}
          style={{
            maxWidth: imgWidth,
            width: imgWidth,
          }}
          src={product.featuredImage.url}
          onClick={() =>
            nav(
              `/product/${selectedCollectionTitle}/${product.id}/${product.title}`
            )
          }
        />
        <h5 style={{ minHeight: '3vw' }}>{product.title.toUpperCase()}</h5>
        {/* <p>Size: {size}</p> */}
        <div
          className='size'
          style={{
            display: 'flex',
            alignSelf: 'flex-start',
            alignItems: 'center',
          }}
        >
          <p>Size:</p>

          <StyledDropDown
            arrowOpen={
              <div
                className='img up'
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  mask: `url("${
                    require('../images/arrow-down.svg').default
                  }") center/contain no-repeat`,
                  WebkitMask: `url("${
                    require('../images/arrow-down.svg').default
                  }") center/contain no-repeat`,
                }}
              />
            }
            arrowClosed={
              <div
                className='img'
                style={{
                  cursor: 'pointer',
                  mask: `url("${
                    require('../images/arrow-down.svg').default
                  }") center/contain no-repeat`,
                  WebkitMask: `url("${
                    require('../images/arrow-down.svg').default
                  }") center/contain no-repeat`,
                }}
              />
            }
            // style={{ /*width: 70, */ width: '2.7vw', marginRight: 20 }}
            value={String(size)}
            onChange={(val) => {
              if (onUpdateSize) {
                onUpdateSize(+val.value);
              }
            }}
            options={[
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
              { label: '6', value: '6' },
              { label: '7', value: '7' },
            ]}
          />
        </div>
        <p>${product.variants[size - 1].price}</p>
        <div style={{ marginTop: 10 }} />
        <div
          style={{
            display: 'flex',
          }}
        >
          <AddToCartButton
            onClick={() => {
              addToCart(product, size);
              onRemove(true);
              notify('Moved to cart');
            }}
          >
            ADD TO CART
          </AddToCartButton>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a
            href={'#'}
            style={{ color: 'black', marginTop: 10 }}
            onClick={(e) => {
              e.preventDefault();
              onRemove();
            }}
          >
            X Remove
          </a>
        </div>
      </Container>
    </FadeIn>
  );
};

const StyledDropDown = styled(DropDown)`
  cursor: pointer;
  & .Dropdown-control {
    display: flex;
  }
  & .Dropdown-control:hover {
    color: white;
  }
  & .Dropdown-placeholder {
    @media (min-width: 700px) {
      font-size: 0.7vw;
    }
  }
  & .Dropdown-menu::-webkit-scrollbar {
    display: none;
  }

  & .Dropdown-control:hover .img {
    background: white;
  }
  & .Dropdown-menu {
    position: absolute;
    // max-height: ;
    // @media (max-width: 700px) {
    width: 50px;
    padding: 0px;
    margin-left: -10px;
    background: white;
    border-radius: 5px;
    z-index: 1;
    box-shadow: -2.66667px -2.66667px 8px #ffffff,
      4px 4px 8px rgba(174, 174, 192, 0.4),
      inset 0px 0px 0px 0.533333px rgba(255, 255, 255, 0.2);
    // }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    max-height: 200px;
    overflow: scroll;
  }
  & img {
    margin-left: 8px;
  }
  & .Dropdown-option {
    text-align: center;
  }
  & .Dropdown-option:hover {
    // text-align: center;
    // background: white;
    // width: 2vw;
    color: white;
    background: var(--primary);
  }
`;

const Container = styled.div<{ divWidth: any }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  width: 100%;
  @media (min-width: 700px) {
    width: ${({ divWidth }) => divWidth};
  }

  & .size .Dropdown-placeholder {
    font-size: 1vw;
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }

  & h5 {
    width: calc(100% - 20px);
    font-weight: 600;
    margin-bottom: 40px;
    margin: 5px 10px;
  }
  & p {
    width: calc(100% - 20px);
    margin: 5px 10px;
    font-size: 1vw;
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
  & a {
    font-size: 14px;
    font-size: 0.85vw;
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
`;

// @ts-ignore
const Image = styled.img<{ imgHeight?: any }>`
  height: auto;
  @media (min-width: 700px) {
    height: ${({ imgHeight }) => imgHeight};
  }
  object-fit: contain;
`;

const AddToCartButton = styled(Button)`
  width: 100%;
  @media (min-width: 700px) {
    width: calc(19.7vw - 2.5vw);
  }
  @media (min-width: 1200) {
    font-size: 0.9vw;
  }
`;

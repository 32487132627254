import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { notify } from '../components/ProductItem';
import { makeRequest } from '../hooks/Resource';
import { ProductMinimal } from '../pages/Home';
import { ProductVariant } from '../pages/ProductDetail';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  margin-top: 50px;
  position: relative;
`;

export const SideNav = styled(Column)<{ transparent?: boolean }>`
  position: fixed;
  top: 200px;
  left: 50px;
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  font-size: 0.7vw;

  @media (max-width: 700px) {
    font-size: 12px;
  }

  & div {
    text-align: left;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & div:hover {
    // color: ${({ transparent }) =>
      transparent ? 'white' : `var(--primary)`};
    color: white;
  }
`;

export const toPrice = (val: number) =>
  (+val).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

export const addToCart = (product: ProductMinimal, size: number | null) => {
  if (!size) {
    notify('Please select a size');
    return;
  }
  if (!product?.variants[0]) {
    notify('Product variant not found');
    return;
  }
  const cartString = localStorage.getItem('cart');
  let cart: { id: number; size: number; variant: ProductVariant }[] = [];
  if (cartString) {
    cart = JSON.parse(cartString);
    cart = cart?.filter((c) => c.id !== 0);
  }
  // cart = [...cart.filter((c) => c !== productId), productId];
  cart.push({ id: product.id, size, variant: product.variants[size - 1] });
  console.log('saving', { cart });
  localStorage.setItem('cart', JSON.stringify(cart));
  // notify(`Added ${product.title} to cart`);
};

export const addToWishList = async (
  product: ProductMinimal,
  size: number | null
) => {
  if (!size) {
    notify('Please select a size');
    return;
  }
  if (!product.variants[0]) {
    notify('Product variant not found');
    return;
  }
  const accessToken = localStorage.getItem('token');

  const resps = await makeRequest<{
    user?: {
      body?: { data?: { customer?: { metafield?: { value: '' } } } };
    };
  }>(`/api/shopify/user/${accessToken}/wishlist`);

  let wishlistString =
    (resps.user?.body?.data?.customer?.metafield &&
      resps.user?.body?.data?.customer?.metafield?.value) ??
    '';

  let wishlist: { id: number; size: number; variant: ProductVariant }[] = [];
  if (wishlistString) {
    wishlist = JSON.parse(wishlistString);
  }

  // const wishlistString = localStorage.getItem('wishlist');
  // let wishlist: { id: number; size: number; variant: ProductVariant }[] = [];
  // if (wishlistString) {
  //   wishlist = JSON.parse(wishlistString);
  // }
  // wishlist = [...wishlist.filter((c) => c !== productId), productId];
  wishlist = [
    ...wishlist.filter(
      (w) => !(w.id === product?.id && w.size === product?.size)
    ),
    { id: product.id, size, variant: product?.variants[size - 1] },
  ];
  console.log('saving', wishlist);

  const resp = await makeRequest<{
    user?: {
      body?: { data?: { customer?: { metafield?: { value: '' } } } };
    };
  }>(`/api/shopify/user/wishlistEdit`, 'POST', {
    customerId: localStorage.getItem('customer_id'),
    wishlist_items: wishlist,
  });

  console.log('edit resp', resp);

  // localStorage.setItem('wishlist', JSON.stringify(wishlist));
  notify(
    <div>
      Added {product?.title} to <Link to={'/wish-list'}>wishlist</Link>
    </div>
  );
};

export const TextfieldContainer = styled(Column)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-size: 13px;
  @media (min-width: 700px) {
    font-size: 0.75vw;
  }
  margin-top: 30px;

  & input {
    width: 320px;
    @media (min-width: 700px) {
      // width: 20vw;
    }
    // min-width: 350px;
  }

  & .textfield-wrapper {
    margin-top: 10px;
  }
`;

export const validateEmail = (email: string) =>
  !(
    email.length === 0 ||
    !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      email.toLowerCase()
    )
  );

export const AccountMenuList = [
  { label: 'Profile', link: '/account' },
  { label: 'Addresses', link: '/addresses' },
  { label: 'Order History', link: '/order-history' },
  { label: 'Wishlist', link: '/wish-list' },
];

import React from 'react';
import FadeIn from 'react-fade-in';
import styled from 'styled-components';

export const ImageFullScreen: React.FC<{
  media: string[];
  selectedIdx: number;
  visible: boolean;
  onClose: () => void;
}> = ({ media, selectedIdx, visible = false, onClose }) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    const yOffset = 0;
    const element = document.getElementById(`selected-media-${selectedIdx}`);
    console.log({ element });
    if (!element) return;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    console.log({ y });
    // element.scrollIntoView({ behavior: 'smooth' });
    containerRef.current?.scrollTo({ top: y });
  }, [visible]);

  return (
    <Container
      ref={containerRef}
      style={{ opacity: visible ? 1 : 0, zIndex: visible ? 99 : -99 }}
    >
      <CloseBtn onClick={onClose}>X</CloseBtn>
      <StyledFadeIn visible={visible}>
        {media.map((url, idx) => {
          return (
            // <img
            //   src={im}
            //   alt={''}
            //   style={{ width: 800, maxWidth: '100%', height: 'auto' }}
            // />
            url.includes('mp4') ? (
              // <video
              //   ref={videoRef}
              //   autoPlay={true}
              //   playsInline={true}
              //   muted
              // >
              //   <source src={image} type="video/mp4"></source>
              // </video>
              // <picture>
              //   <source srcSet={image} type="video/mp4" />
              /* <img src={image} alt="An image of an explosion." /> */
              <video
                key={idx}
                id={`selected-media-${idx}`}
                autoPlay={true}
                playsInline={true}
                muted
                loop
              >
                <source src={url} type='video/mp4'></source>
              </video>
            ) : (
              //  </picture>
              //  <video
              //   ref={videoRef}
              //   autoPlay={true}
              //   playsInline={true}
              //   muted
              // >
              //   <source src={image} type="video/mp4"></source>
              // </video>
              <img
                id={`selected-media-${idx}`}
                alt={''}
                src={url}
                style={{
                  borderRadius: 16,
                  height: '100%',
                  objectFit: 'cover',
                  width: '100%',
                }}
              />
            )
          );
        })}
      </StyledFadeIn>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  background: #eeeff3;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 99;
  max-height: 100%;
  overflow-y: scroll;
`;

const CloseBtn = styled.div`
  position: fixed;
  top: 15px;
  right: 30px;
  text-align: right;
  cursor: pointer;
  font-size: 22px;

  @media (max-width: 700px) {
    position: sticky;
    align-self: flex-end;
    font-size: 14px;
  }

  &:hover {
    color: white;
  }
`;

const StyledFadeIn = styled(FadeIn)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

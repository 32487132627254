import React from 'react';
import { ProductItem } from '../components/ProductItem';
import { Textfield } from '../components/Textfield';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { ProductVariant as ShopifyProductVariant } from 'shopify-buy';
import axios from 'axios';
import { makeRequest } from '../hooks/Resource';
import { ProductVariant } from './ProductDetail';
import { SearchSection } from '../components/SearchSection';
import { WelcomePreview } from '../components/WelcomePreview';

export interface Metafield {
  namespace: string;
  key: string;
  type: string;
  value: string;
  reference?: { image?: { originalSrc?: string } };
}
export interface ShopifyProduct {
  id: string;
  title: string;
  images: {
    edges: { node: { url: string; altText: string } }[];
    nodes: { url: string }[];
  };
  media?: {
    edges: {
      node: {
        mediaContentType: string;
        sources?: { format: string; url: string }[];
        image: { url: string };
      };
    }[];
  };
  collections: {
    nodes: {
      handle: string;
      title: string;
      id: string;
    }[];
  };
  tags: string[];
  productType: string;
  metafield?: {
    id: string;
    value: string;
  };
  metafields: Metafield[];

  featuredImage: {
    url: string;
  };
  variants: {
    nodes: ShopifyProductVariant[];
  };
  description: string;
}
export interface ShopifyProductsResponse {
  edges: {
    node: ShopifyProduct;
  }[];
}

export interface ProductMinimal {
  id: number;
  title: string;
  featuredImage: { url: string };
  images?: string[];
  size?: number;
  variants: ProductVariant[];
  tags: string[];
  productType: string;
  collections: {
    nodes: {
      handle: string;
      title: string;
      id: string;
    }[];
  };
}

export const Home: React.FC<{ onCartChange: () => void }> = ({
  onCartChange,
}) => {
  return <SearchSection onCartChange={onCartChange} />;
};

import React from 'react';
import FadeIn from 'react-fade-in';
import { Link } from 'react-router-dom';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Column, TextfieldContainer, validateEmail } from '../helper/Common';
import { makeRequest } from '../hooks/Resource';
import { Customer } from '../pages/Profile';
import { Button } from '../utils/Constants';
import { notify } from './ProductItem';
import { Textfield } from './Textfield';

interface LoginResponse {
  login?: {
    body?: {
      data?: {
        customerAccessTokenCreate?: {
          customerAccessToken?: {
            accessToken?: string;
          };
          customerUserErrors?: {
            message?: string;
          };
        };
      };
    };
  };
}
export const Login: React.FC<{ onLoggedIn: () => void }> = ({ onLoggedIn }) => {
  console.log('get', localStorage.getItem('token'));
  const [email, setEmail] = React.useState<string>('');
  const [pass, setPass] = React.useState<string>('');
  const [params] = useSearchParams();
  const resetUrl = params.get('resetUrl') ?? '';

  const [step, setStep] = React.useState<
    'login' | 'register' | 'reset' | 'resetByUrl'
  >(resetUrl ? 'resetByUrl' : 'login');

  const onLogin = async () => {
    if (!email) {
      notify(
        <div>
          Enter your email or{' '}
          <a
            href={'#'}
            onClick={(e) => {
              e.preventDefault();
              setStep('register');
            }}
          >
            Create an Account
          </a>
        </div>
      );
      return;
    }
    if (!validateEmail(email)) {
      notify('Invalid email');
      return;
    }
    const resp = await makeRequest<LoginResponse>(
      '/api/shopify/user/login',
      'POST',
      {
        email,
        password: pass,
      }
    );
    const accessToken =
      resp.login?.body?.data?.customerAccessTokenCreate?.customerAccessToken
        ?.accessToken;
    if (!accessToken) {
      if (
        resp.login?.body?.data?.customerAccessTokenCreate?.customerUserErrors
          ?.message
      ) {
        notify(
          resp.login?.body?.data?.customerAccessTokenCreate?.customerUserErrors
            ?.message
        );
      } else {
        notify(
          <div>
            Enter your email or{' '}
            <u
              style={{ cursor: 'pointer' }}
              onClick={() => setStep('register')}
            >
              create an account
            </u>
          </div>
        );
      }
      return;
    }

    notify(`You're logged in!`);

    localStorage.setItem('token', accessToken);
    localStorage.setItem('email', email);
    onLoggedIn();
  };

  return (
    <Container>
      {step === 'reset' ? (
        <ForgotPasswordSection
          onReset={() => {
            setStep('login');
          }}
          onBack={() => setStep('login')}
        />
      ) : step === 'resetByUrl' ? (
        <ResetByUrlSection
          resetUrl={resetUrl}
          onReset={() => {
            setStep('login');
          }}
          onBack={() => setStep('login')}
        />
      ) : step === 'register' ? (
        <CreateAccountSection
          onCreate={() => {
            setStep('login');
          }}
          onBack={() => setStep('login')}
        />
      ) : (
        <StyledFadeIn>
          <h2 style={{ marginTop: 15 }}>PROFILE</h2>
          <Column style={{ alignItems: 'flex-start' }}>
            <h5 style={{ textAlign: "center" }}>
              EDIT YOUR PERSONAL INFORMATION
            </h5>
            <h6>Account Information</h6>
          </Column>
          <TextfieldContainer>
            Email Address
            <Textfield value={email} onChange={setEmail} color={'white'} />
          </TextfieldContainer>
          <TextfieldContainer>
            Password
            <Textfield
              value={pass}
              onChange={setPass}
              color={'white'}
              isSecure
              onEnter={onLogin}
            />
          </TextfieldContainer>
          <div style={{ marginTop: 50 }} />
          <Button className="btn" onClick={() => onLogin()}>
            SIGN IN
          </Button>
          <div style={{ marginTop: 30 }} />
          <u onClick={() => setStep('reset')}>Forgot your password?</u>
          <u onClick={() => setStep('register')}>Create an account</u>
          <div style={{ marginTop: 100 }} />
        </StyledFadeIn>
      )}
    </Container>
  );
};

const ForgotPasswordSection: React.FC<{
  onReset: () => void;
  onBack: () => void;
}> = ({ onReset, onBack }) => {
  const [email, setEmail] = React.useState<string>('');

  const onResetPassword = async () => {
    const resp = await makeRequest<{
      reset?: {
        body?: {
          data?: {
            customerRecover?: {
              customerUserErrors?: { message?: string }[];
            };
          };
        };
      };
    }>(`/api/shopify/user/reset`, 'POST', { email });

    const errors = resp.reset?.body?.data?.customerRecover?.customerUserErrors;
    if (errors?.length) {
      notify(errors[0].message ?? 'Something went wrong');
      return;
    }
    notify(`Please check your email for a link to reset your password`);
    onReset();
  };

  return (
    <StyledFadeIn>
      <h2 style={{ marginTop: 15 }}>PROFILE</h2>
      <Column style={{ alignItems: 'flex-start' }}>
        <h5>FORGOT PASSWORD</h5>
        <h6>
          Enter your email address and we'll send you a link to reset your
          password.
        </h6>
      </Column>
      <TextfieldContainer>
        Email Address
        <Textfield
          value={email}
          onChange={setEmail}
          color={'white'}
          onEnter={onResetPassword}
        />
      </TextfieldContainer>
      <div style={{ marginTop: 50 }} />
      <Button className="btn" onClick={() => onResetPassword()}>
        RESET PASSWORD
      </Button>
      <div style={{ marginTop: 30 }} />
      <u onClick={onBack}>Back</u>
      <div style={{ marginTop: 100 }} />
    </StyledFadeIn>
  );
};

const ResetByUrlSection: React.FC<{
  resetUrl: string;
  onReset: () => void;
  onBack: () => void;
}> = ({ resetUrl, onReset, onBack }) => {
  const [pass, setPass] = React.useState<string>('');

  const [_, setSearchParams] = useSearchParams();

  const onResetPassword = async () => {
    const resp = await makeRequest<{
      reset?: {
        body?: {
          data?: {
            customerResetByUrl?: {
              customerUserErrors?: { message?: string }[];
            };
          };
        };
      };
    }>(`/api/shopify/user/resetByUrl`, 'POST', { resetUrl, password: pass });

    const errors =
      resp.reset?.body?.data?.customerResetByUrl?.customerUserErrors;
    if (errors?.length) {
      notify(errors[0].message ?? 'Something went wrong');
      return;
    }
    setSearchParams({});
    notify(`Password reset! Please sign in with your new password`);
    onReset();
  };

  return (
    <StyledFadeIn>
      <h2 style={{ marginTop: 15 }}>PROFILE</h2>
      <Column style={{ alignItems: 'flex-start' }}>
        <h5>SET YOUR NEW PASSWORD</h5>
      </Column>
      <TextfieldContainer>
        Password
        <Textfield value={pass} onChange={setPass} color={'white'} isSecure />
      </TextfieldContainer>
      <div style={{ marginTop: 50 }} />
      <Button className="btn" onClick={() => onResetPassword()}>
        RESET PASSWORD
      </Button>
      {/* <div style={{ marginTop: 30 }} /> */}
      {/* <u onClick={onBack}>Back</u> */}
      <div style={{ marginTop: 100 }} />
    </StyledFadeIn>
  );
};

const CreateAccountSection: React.FC<{
  onCreate: () => void;
  onBack: () => void;
}> = ({ onCreate, onBack }) => {
  const [email, setEmail] = React.useState<string>('');
  const [pass, setPass] = React.useState<string>('');

  const onCreateAccount = async () => {
    const resp = await makeRequest<{
      register?: {
        body?: {
          data?: {
            customerCreate?: {
              customer?: Customer;
              customerUserErrors?: { message?: string }[];
            };
          };
        };
      };
    }>(`/api/shopify/user/create`, 'POST', { email, password: pass });

    const errors =
      resp.register?.body?.data?.customerCreate?.customerUserErrors;
    if (errors?.length) {
      notify(errors[0].message ?? 'Something went wrong');
      return;
    }
    notify(`Account created! Please sign in with your new account.`);
    onCreate();
  };

  return (
    <StyledFadeIn>
      <h2 style={{ marginTop: 15 }}>PROFILE</h2>
      <Column style={{ alignItems: 'flex-start' }}>
        <h5>CREATE AN ACCOUNT</h5>
      </Column>
      <TextfieldContainer>
        Email Address
        <Textfield value={email} onChange={setEmail} color={'white'} />
      </TextfieldContainer>
      <TextfieldContainer>
        Password
        <Textfield
          value={pass}
          onChange={setPass}
          color={'white'}
          isSecure
          onEnter={onCreateAccount}
        />
      </TextfieldContainer>
      <div style={{ marginTop: 50 }} />
      <Button className="btn" onClick={() => onCreateAccount()}>
        CREATE AN ACCOUNT
      </Button>
      <div style={{ marginTop: 30 }} />
      <u onClick={onBack}>Back</u>
      <div style={{ marginTop: 100 }} />
    </StyledFadeIn>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 700px) {
    width: calc(100% - 30px);
    max-width: 324px;
  }

  & h2 {
    width: 100%;
    text-align: center;
  }

  & u {
    font-size: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer !important;
  }

  & > h5,
  > h6,
  .btn {
    width: 21vw;
    @media (max-width: 700px) {
      width: 100%;
    }
  }

  & .btn {
    cursor: pointer;
  }
`;

const StyledFadeIn = styled(FadeIn)`
  & h5,
  h6 {
    width: 21vw;
    @media (max-width: 700px) {
      width: 100%;
    }
  }
`;

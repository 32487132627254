import React, { useEffect } from 'react';
import styled from 'styled-components';
import { MobileInfoNav } from '../components/info/MobileInfoNav';
import { SideNav } from '../helper/Common';
import { makeRequest } from '../hooks/Resource';
import { useLocation } from 'react-router';

type TabKey = 'privacy' | 'terms' | 'shipping-return-policy';

export const Privacy: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState<TabKey>(
    'shipping-return-policy'
  );
  const [policyHtml, setPolicyHtml] = React.useState<string>('');
  const [termsHtml, setTermsHtml] = React.useState<string>('');
  const [shippingReturnHtml, setShippingReturn] = React.useState<string>('');

  const location: { state: any } = useLocation();
  useEffect(() => {
    if (location?.state?.name) {
      onNavClicked(location.state.name);
    }
  }, [location.state]);

  useEffect(() => {
    console.log('selected', selectedTab);
  }, [selectedTab]);

  const onNavClicked = (id: TabKey) => {
    setSelectedTab(id);
    const yOffset = -250;
    const element = document.getElementById(id);
    if (!element) return;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const getContent = async () => {
    const policyResp = await makeRequest<{
      page?: { body?: { data?: { page: { body: string } } } };
    }>(`/api/shopify/page/privacy-policy`);
    const termsResp = await makeRequest<{
      page?: { body?: { data?: { page: { body: string } } } };
    }>(`/api/shopify/page/terms-of-use`);
    const shippingReturnResp = await makeRequest<{
      page?: { body?: { data?: { page: { body: string } } } };
    }>(`/api/shopify/page/shipping-and-return-policy`);

    if (policyResp?.page?.body?.data?.page?.body) {
      setPolicyHtml(policyResp.page.body.data.page.body);
    }
    if (termsResp?.page?.body?.data?.page?.body) {
      setTermsHtml(termsResp.page.body.data.page.body);
    }
    if (shippingReturnResp?.page?.body?.data?.page?.body) {
      setShippingReturn(shippingReturnResp.page.body.data.page.body);
    }
  };
  React.useEffect(() => {
    getContent();
  }, []);

  if (!document || !policyHtml) return <div />;

  return (
    <Container>
      <StyledSideNavColumn>
        <StyledSideNav style={{ top: 200 }}>
          <div
            className={
              selectedTab === 'shipping-return-policy' ? 'selected' : ''
            }
            onClick={() => onNavClicked('shipping-return-policy')}
          >
            Shipping / Return Policy
          </div>
          <div
            className={selectedTab === 'privacy' ? 'selected' : ''}
            onClick={() => onNavClicked('privacy')}
          >
            Privacy Policy
          </div>
          <div
            className={selectedTab === 'terms' ? 'selected' : ''}
            onClick={() => onNavClicked('terms')}
          >
            Terms & Conditions
          </div>
        </StyledSideNav>
      </StyledSideNavColumn>

      <Column>
        <MobileInfoNav changeTab={onNavClicked} selectedTab={selectedTab} />
        <h2 style={{ paddingTop: 15, paddingBottom: 24, textAlign: 'center' }}>
          SHIPPING / RETURN POLICY
        </h2>
        <div id={'shipping-return-policy'} />
        <div dangerouslySetInnerHTML={{ __html: shippingReturnHtml }} />
        <h2 style={{ paddingTop: 15, paddingBottom: 40, textAlign: 'center' }}>
          PRIVACY POLICY
        </h2>

        <div id={'privacy'} />
        <div dangerouslySetInnerHTML={{ __html: policyHtml }} />
        <h2 style={{ paddingTop: 63, paddingBottom: 40, textAlign: 'center' }}>
          TERMS & CONDITIONS
        </h2>
        <div id={'terms'} />
        <div dangerouslySetInnerHTML={{ __html: termsHtml }} />
      </Column>
      <RightColumn></RightColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;

  & h2 {
    font-weight: 700;
    font-size: 26px;
    @media (min-width: 700px) {
      font-size: 1.6vw;
    }
    margin: 0;
    margin-bottom: 10px;
  }

  & p {
    font-size: 12px;

    @media (min-width: 700px) {
      font-size: 0.7vw;
    }
    margin: 0;
    margin-top: 10px;
  }

  @media (max-width: 700px) {
    & p {
      font-size: 12px;
    }
  }

  @media (max-width: 700px) {
    padding: 30px;
    padding-top: 0px;
  }
`;

const StyledSideNav = styled(SideNav)`
  font-size: 12px;
  font-size: 0.7vw;
  @media (max-width: 700px) {
    font-size: 12px;
  }
  & div {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  & .selected {
    font-weight: bold;
  }
  @media (max-width: 700px) {
    display: none;
  }
  & div:hover {
    color: #666;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  // max-width: 600px;
  margin-top: 50px;
  position: relative;
`;

const StyledSideNavColumn = styled(Column)`
  @media (max-width: 700px) {
    display: none;
  }
`;
const RightColumn = styled(Column)`
  @media (max-width: 700px) {
    display: none;
  }
`;
